import _ from "lodash";

class SessionStatus {
  setMaxInactiveInternal(maxInactiveInterval) {
    this.maxInactiveInterval = maxInactiveInterval;
  }

  createSessionTimeoutTimer() {
    if (!_.isUndefined(this.maxInactiveInterval)) {
      this.maxInactiveIntervalMs = this.maxInactiveInterval * 1000 + 1000;
      this.startTimer();
    }
  }

  startTimer() {
    if (!_.isUndefined(this.maxInactiveInterval)) {
      this.sessionTimeoutTimerId = _.delay(
        this.sessionTimeoutCallback,
        this.maxInactiveIntervalMs,
      );
    }
  }

  resetTimer() {
    if (!_.isUndefined(this.sessionTimeoutTimerId)) {
      clearTimeout(this.sessionTimeoutTimerId);
      this.startTimer();
    }
  }

  sessionTimeoutCallback() {
    window.location.reload();
  }
}

export const sessionStatus = new SessionStatus();
