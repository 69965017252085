import * as React from "react";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import moment from "moment";
import log from "loglevel";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Box, Tooltip } from "@mui/material";
import { Button, Spacing2, ToggleButtonGroup } from "@chemaxon/design-system";
import ErrorOutline from "@chemaxon/design-system/icons/ErrorOutline";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import {
  useGetTeams,
  useRemoveTeam,
  useSyncTeam,
} from "src/ui/services/TeamService";
import ConfirmDialog from "src/ui/components/confirmation/ConfirmDialog";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";
import { UTC_OFFSET } from "src/ui/components/form/form-elements/inputs/DatePicker";
import { Team } from "src/ui/models/Team";
import SynergyMUIDataTableSearchBox from "src/ui/components/mui-datatables-search-box/SynergyMUIDataTableSearchBox";
import Subheader from "src/ui/components/subheader/Subheader";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import MoreMenu from "src/ui/components/more-menu/MoreMenu";
import { AppRoutePath } from "src/ui/utils/routes";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";

import "src/ui/views/teams/list/ListTeamsView.scss";
import SyncButton from "src/ui/components/more-menu/SyncButton";
import RemoveButton from "src/ui/components/more-menu/RemoveButton";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

const ListTeamsView = () => {
  const [teamToRemove, setTeamToRemove] = useState<null | Team>(null);
  const navigate = useNavigate();
  const getTeamsQueryResult = useGetTeams();
  const mutateRemoveTeam = useRemoveTeam();
  const mutateSyncTeam = useSyncTeam();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const teams =
    adaptUseQueryResponseForContainerWithLoader(getTeamsQueryResult);

  const hideConfirmDialog = () => {
    setTeamToRemove(null);
  };

  const removeTeam = () => {
    mutateRemoveTeam
      .mutateAsync(teamToRemove ? teamToRemove.id : -1)
      .then(() => {
        enqueueSnackbar(`${teamToRemove?.teamName} removed successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
        hideConfirmDialog();
      })
      .catch(e => {
        enqueueSnackbar(`Unable to remove team: ${teamToRemove?.teamName}!`, {
          variant: "error",
          persist: true,
          action: onCloseAction(closeSnackbar),
        });
        log.error(e);
        hideConfirmDialog();
      });
  };

  const syncTeam = (team: Team) => {
    mutateSyncTeam
      .mutateAsync(team.id)
      .then(() => {
        enqueueSnackbar(`${team.teamName} synced successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
      })
      .catch(e => {
        enqueueSnackbar(`Unable to sync team: ${team.teamName}!`, {
          variant: "error",
          persist: true,
          action: onCloseAction(closeSnackbar),
        });

        log.error(e);
      });
  };

  const getMuiTableOptions = (): MUIDataTableOptions => {
    return {
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <SynergyMUIDataTableSearchBox
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      },
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      onRowClick: editTeam,
      elevation: 0,
      filter: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "No registered teams.",
        },
      },
      sortOrder: {
        name: "Name",
        direction: "asc",
      },
      searchAlwaysOpen: true,
      customSearch: (rawSearchQuery, currentRow, columns) => {
        const caseSensitive = false;
        let isFound = false;
        const searchQuery = caseSensitive
          ? rawSearchQuery
          : rawSearchQuery.toLowerCase();
        currentRow.forEach((col, i) => {
          if (!columns[i].searchable) {
            return;
          }
          let textInRow = caseSensitive ? col : col.toString().toLowerCase();
          if (i === 4) {
            textInRow = col.actualSize + "/" + col.maxSize;
          }
          if (textInRow.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          if (colIndex === 4) {
            const textInRowA =
              a.data[colIndex].actualSize + "/" + a.data[colIndex].maxSize;
            const textInRowB =
              b.data[colIndex].actualSize + "/" + b.data[colIndex].maxSize;
            return (
              (textInRowA < textInRowB ? -1 : 1) * (order === "desc" ? 1 : -1)
            );
          }
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === "desc" ? 1 : -1)
          );
        });
      },
    };
  };

  const getTableColumns = () => {
    const columns = [];

    columns.push({
      name: "Id",
      options: { display: false, filter: false, searchable: false },
    });
    columns.push({
      name: "Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
        customBodyRender: renderTeamName,
      },
    });
    columns.push({
      name: "Subscription Expiration",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderExpirationDateCell,
      },
    });
    columns.push({
      name: "# of members",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderNumberOfMembers,
      },
    });
    columns.push({
      name: "",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: renderDropdownMenu,
      },
    });

    return columns;
  };

  const getTableData = (teams: Array<Team>) => {
    const rows: Array<object | number[] | string[]> = [];

    teams.forEach(team => {
      const cells: Array<object | number | string> = [];
      cells.push(team.id);
      cells.push(team.teamName);
      cells.push({
        expirationDate: team.expirationDate,
        expired: team.expired,
      });
      cells.push({ actualSize: team.usedSeatCount, maxSize: team.teamSize });
      cells.push(team);

      rows.push(cells);
    });

    return rows;
  };

  const renderTeamName = (teamName: string) => {
    if (teamName?.length > 40) {
      const truncTeamName = teamName.substring(0, 39) + "...";
      return (
        <Tooltip title={teamName}>
          <span>{truncTeamName}</span>
        </Tooltip>
      );
    }
    return <span>{teamName}</span>;
  };

  const renderExpirationDateCell = ({
    expirationDate,
    expired,
  }: {
    expirationDate: string;
    expired: string;
  }) => {
    const formattedDate = moment(expirationDate)
      .utcOffset(UTC_OFFSET)
      .format("YYYY-MM-DD");
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: Spacing2,
        }}
      >
        {expired && (
          <ErrorOutline
            sx={{
              width: "20px",
              height: "20px",
            }}
            className="expiredIcon"
          />
        )}
        <span className={expired ? "expiredText" : undefined}>
          {formattedDate}
        </span>
      </Box>
    );
  };

  const renderNumberOfMembers = ({
    actualSize,
    maxSize,
  }: {
    actualSize: number;
    maxSize: number;
  }) => {
    return (
      <span className={maxSize < actualSize ? "teamSizeOverflow" : undefined}>
        {actualSize}/{maxSize}
      </span>
    );
  };

  const renderDropdownMenu = (team: Team) => {
    return (
      <MoreMenu
        id={team.id}
        options={[
          {
            key: team.id + "_sync",
            option: <SyncButton onClick={() => syncTeam(team)} />,
            loading:
              team.id === mutateSyncTeam.variables && mutateSyncTeam.isPending,
          },
          {
            key: team.id + "_remove",
            option: <RemoveButton onClick={() => setTeamToRemove(team)} />,
            loading:
              team.id === mutateRemoveTeam.variables &&
              mutateRemoveTeam.isPending,
          },
        ]}
      />
    );
  };

  const renderCreateProjectButton = () => {
    return (
      <ToggleButtonGroup>
        <Button
          colorVariant="primary"
          id="importTeamButton"
          onClick={() => {
            navigate(AppRoutePath.TeamImport);
          }}
        >
          Import team
        </Button>
        <Button
          colorVariant="primary"
          id="addTeamButton"
          onClick={() => {
            navigate(AppRoutePath.TeamAdd);
          }}
        >
          Create team
        </Button>
      </ToggleButtonGroup>
    );
  };

  const editTeam: MUIDataTableOptions["onRowClick"] = row => {
    navigate(generatePath(AppRoutePath.TeamEdit, { teamId: String(row[0]) }));
  };

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.TEAM,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader title="Teams" button={renderCreateProjectButton()} />
        <ViewContainer id={"listTeamsContainer"} sx={{ px: 0 }}>
          <ContainerWithLoaderAndError
            data={teams}
            content={teams => {
              const tableColumns = getTableColumns();
              const tableData = getTableData(teams);
              return (
                <div className="listTeamsView">
                  <MUIDataTable
                    columns={tableColumns}
                    data={tableData}
                    options={getMuiTableOptions()}
                    title={""}
                  />
                  <ConfirmDialog
                    isShown={teamToRemove !== null}
                    title={
                      "Delete '" +
                      (teamToRemove ? teamToRemove.teamName : null) +
                      "'?"
                    }
                    subtitle={"You will not be able to recover it."}
                    confirmByType={{
                      inputLabel: "To confirm, enter the name of the team",
                      inputPlaceholder: teamToRemove?.teamName,
                    }}
                    confirmButtonLabel={"Yes, delete it"}
                    submit={removeTeam}
                    cancel={hideConfirmDialog}
                    destructive={true}
                    loading={mutateRemoveTeam.isPending}
                    divider={false}
                  />
                </div>
              );
            }}
          />
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
};

export default ListTeamsView;
