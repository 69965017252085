import React from "react";

import {
  useAddTeamMembers,
  useRemoveTeamMember,
} from "src/ui/services/TeamMemberService";

export const withTeamMemberService = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTeamMemberService = (props: TProps) => {
    const mutateAddTeamMembers = useAddTeamMembers();
    const mutateRemoveTeamMember = useRemoveTeamMember();

    const teamMemberService = {
      addTeamMembers: mutateAddTeamMembers.mutateAsync,
      removeTeamMember: mutateRemoveTeamMember.mutateAsync,
    };

    return (
      <WrappedComponent {...props} teamMemberService={teamMemberService} />
    );
  };

  ComponentWithTeamMemberService.displayName = `WithTeamMemberService(${displayName})`;

  return ComponentWithTeamMemberService;
};
