import React, { useEffect, useState } from "react";

import ApplicationCard from "src/ui/components/applications/ApplicationCard/ApplicationCard";
import ResourceCard from "src/ui/components/resources/ResourceCard";
import {
  filterRealApplications,
  getApplicationUrl,
  getOwnApplications,
  getDeepLinks,
  getProduct,
} from "src/ui/services/applicationUtils";
import ProductDataService from "src/ui/services/ProductDataService";
import HideMenu from "src/ui/views/home/HideMenu";

import Subheader from "src/ui/components/subheader/Subheader";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { Grid, Typography, TypographyProps } from "@chemaxon/design-system";
import { SectionTitle } from "src/ui/typography/SectionTitle";

import { Product } from "src/ui/models/Product";
import { useGetApplications } from "src/ui/services/ApplicationService";
import { useLocalStorage } from "src/ui/utils/useLocalStorage";
import { useGetResourcesInfo } from "src/ui/services/ResourceService";
import {
  useGetCurrentGroups,
  useGetCurrentTeam,
} from "src/ui/services/UserInfoService";

const WarningLabel = (props: TypographyProps) => (
  <Typography
    variant="subtitle1"
    align="center"
    sx={{ opacity: 0.6 }}
    paragraph
    {...props}
  />
);

function HomeView() {
  const [applicationProducts, setApplicationProducts] = useState<Product[]>([]);
  const [hiddenApps, setHiddenApps] = useLocalStorage<number[]>(
    "hidden-application",
    [],
  );

  useEffect(() => {
    ProductDataService.getApplications().then(setApplicationProducts);
  }, []);

  const getCurrentGroupsQueryResult = useGetCurrentGroups();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getApplicationsQueryResult = useGetApplications();
  const getResourcesInfoResult = useGetResourcesInfo();

  const currentGroups = getCurrentGroupsQueryResult.data ?? [];
  const currentTeam = getCurrentTeamQueryResult.data;
  const applications = getApplicationsQueryResult.data ?? [];
  const resourcesInfo = getResourcesInfoResult.data ?? [];

  const ownApplications =
    getOwnApplications(applications, currentGroups, currentTeam) || [];

  const noApps = ownApplications.length <= 0;
  const noResources = resourcesInfo.length <= 0;

  const renderResourceCards = () => {
    if (resourcesInfo === undefined || noResources) {
      return null;
    }

    return (
      <section>
        <SectionTitle>Resources</SectionTitle>
        <Grid container>
          {resourcesInfo.map(resource => (
            <Grid item xs={4} md={3} key={resource.id}>
              <ResourceCard
                entryPointUrl={resource.entryPointUrl}
                resourceType={resource.type}
              />
            </Grid>
          ))}
        </Grid>
      </section>
    );
  };

  const renderAppCards = () => {
    if (noApps) {
      return null;
    }

    const filteredApplications = filterRealApplications(ownApplications);
    // This sorts the applications so the ones with deeplinks are shown before the rest
    const sortedApplications = filteredApplications.sort(
      (a, b) => (getDeepLinks(b) || []).length - (getDeepLinks(a) || []).length,
    );
    const visibleApplications = sortedApplications.filter(
      app => hiddenApps.indexOf(app.id) === -1,
    );

    return (
      <section>
        <SectionTitle>Applications</SectionTitle>
        <Grid container>
          {visibleApplications.map(app => {
            const product = getProduct(app, applicationProducts);
            return (
              <Grid item xs={4} key={app.displayName}>
                <ApplicationCard
                  name={app.displayName}
                  description={product?.description ?? ""}
                  appIcon={{
                    iconMediaType: app.iconMediaType,
                    iconData: app.iconData,
                  }}
                  productUrl={getApplicationUrl(app)}
                  productPageUrl={product?.productPageUrl ?? null}
                  deepLinks={getDeepLinks(app)}
                />
              </Grid>
            );
          })}
        </Grid>
      </section>
    );
  };

  const renderHideMenu = () => {
    if (noApps) {
      return null;
    }

    return (
      <HideMenu
        allApps={ownApplications}
        hiddenApps={hiddenApps}
        toggleVisibility={toggleVisibility}
      />
    );
  };

  const toggleVisibility = (appId: number) => {
    const updatedHiddenApps = hiddenApps.filter(val => val !== appId);
    if (updatedHiddenApps.length === hiddenApps.length) {
      updatedHiddenApps.push(appId);
    }
    setHiddenApps(updatedHiddenApps);
  };

  return (
    <React.Fragment>
      <Subheader title={"Dashboard"} button={renderHideMenu()} />
      <ViewContainer id="homeViewContainer" transparentBg>
        {noApps && noResources && (
          <WarningLabel>
            You do not have any installed applications.
          </WarningLabel>
        )}

        {renderResourceCards()}

        {renderAppCards()}
      </ViewContainer>
    </React.Fragment>
  );
}

export default HomeView;
