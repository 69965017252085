import PropTypes from "prop-types";
import React, { useState } from "react";
import Dialog from "src/ui/components/dialog/Dialog";
import { TextField } from "@chemaxon/design-system";

const ConfirmationField = ({ placeholder, label, onConfirmation }) => {
  const checkSpelling = inputValue => {
    onConfirmation(inputValue === placeholder);
  };

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      fullWidth
      onChange={e => checkSpelling(e.target.value)}
    />
  );
};

ConfirmationField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onConfirmation: PropTypes.func,
};

function ConfirmDialog(props) {
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(
    !props.confirmByType,
  );

  const handleEvent = function (eventHandler, event) {
    setConfirmButtonEnabled(!props.confirmByType);
    eventHandler(event);
  };

  return (
    <Dialog
      open={props.isShown}
      title={props.title}
      subtitle={props.subtitle}
      content={
        props.confirmByType ? (
          <ConfirmationField
            placeholder={props.confirmByType.inputPlaceholder}
            label={props.confirmByType.inputLabel}
            onConfirmation={setConfirmButtonEnabled}
          />
        ) : (
          <></>
        )
      }
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          colorVariant: "secondary",
          onClick: handleEvent.bind(null, props.cancel),
        },
        {
          id: "confirm",
          label: props.confirmButtonLabel
            ? props.confirmButtonLabel
            : "Confirm",
          colorVariant: props.destructive ? "destructive" : "primary",
          onClick: handleEvent.bind(null, props.submit),
          loading: props.loading,
          disabled: !confirmButtonEnabled,
        },
      ]}
      onClose={handleEvent.bind(null, props.cancel)}
      divider={props.divider}
    />
  );
}

ConfirmDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  confirmByType: PropTypes.shape({
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
  }),
  confirmButtonLabel: PropTypes.string,
  destructive: PropTypes.bool,
  loading: PropTypes.bool,
  divider: PropTypes.bool,
};

export default ConfirmDialog;
