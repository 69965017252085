import PropTypes from "prop-types";
import React from "react";
import ModalOverlay from "src/ui/components/modal-overlay/ModalOverlay";

import "./InformationDialog.css";

class InformationDialog extends React.Component {
  stopClickEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    let content = null;

    if (this.props.isShown) {
      content = (
        <ModalOverlay onClick={this.props.close}>
          <div className="information-container">
            <div className="information-holder">
              <div
                className="information-content"
                onClick={e => {
                  this.stopClickEvent(e);
                }}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </ModalOverlay>
      );
    }

    return content;
  }
}

InformationDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

export default InformationDialog;
