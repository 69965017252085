import React from "react";
import PropTypes from "prop-types";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { Box, Divider, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Typography } from "@chemaxon/design-system";
import Close from "@chemaxon/design-system/icons/Close";
import { CornerMedium } from "@chemaxon/design-system";

const DialogHeader = ({ title, subtitle, onClose }) => (
  <Box
    sx={{
      px: 6,
      py: 4,
    }}
  >
    <MuiDialogTitle sx={{ p: 0 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {title}
        {onClose && (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Stack>
    </MuiDialogTitle>

    {subtitle && (
      <Typography
        variant="body1"
        sx={{
          mt: 2,
        }}
      >
        {subtitle}
      </Typography>
    )}
  </Box>
);

DialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      colorVariant: PropTypes.string,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
  onClose: PropTypes.func,
  divider: PropTypes.bool,
  maxWidth: PropTypes.string,
  borderRadius: PropTypes.string,
};

function Dialog({
  open,
  title,
  subtitle,
  content,
  actions,
  onClose,
  divider = true,
  maxWidth = "sm",
  borderRadius = CornerMedium.borderRadius,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: {
            xs: 0,
            sm: borderRadius,
          },
        },
      }}
      onClose={onClose}
    >
      <DialogHeader title={title} subtitle={subtitle} onClose={onClose} />

      {divider && content && <Divider />}

      {content && (
        <DialogContent
          sx={{
            py: 6,
            px: 6,
          }}
        >
          {content}
        </DialogContent>
      )}

      {divider && actions && <Divider />}

      {actions && (
        <DialogActions
          sx={{
            p: 6,
          }}
        >
          {actions.map(action => (
            <Button
              key={action.id}
              id={action.id}
              colorVariant={action.colorVariant}
              disabled={action.disabled}
              loading={action.loading}
              onClick={action.onClick}
              type={action.type}
              form={action.form}
            >
              {action.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;
