import { AppBar, Toolbar } from "@mui/material";
import {
  AppBarRight,
  AppsList,
  AppsListProps,
  HomeLink,
} from "@chemaxon/design-system";
import { Background } from "@chemaxon/design-system/color";

import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import ProfileElement from "src/ui/components/profile/ProfileElement";
import {
  filterApplicationsByVisibility,
  filterRealApplications,
  filterTeamApplications,
  getApplicationUrl,
} from "src/ui/services/applicationUtils";
import NavigationToolbar from "src/ui/components/navigation/NavigationToolbar";
import { Application } from "src/ui/models/Application";
import { useGetApplications } from "src/ui/services/ApplicationService";
import {
  useGetCurrentGroups,
  useGetCurrentTeam,
} from "src/ui/services/UserInfoService";
import { usePermissions } from "src/ui/utils/usePermissions";

const NO_APPLICATIONS: AppsListProps["apps"] = [
  { name: "No registered applications yet.", url: "#", target: "_self" },
];

type CurrentTeam = {
  id: number;
};

type CurrentGroup = {
  groupName: string;
};

type TopMenuBarBranchState = {
  applications?: Application[];
  currentGroups?: CurrentGroup[];
  currentTeam?: CurrentTeam;
  checkPermission: ReturnType<typeof usePermissions>["checkPermission"];
};

function getApplications({
  applications,
  currentGroups = [],
  currentTeam,
  checkPermission,
}: TopMenuBarBranchState) {
  if (
    !checkPermission(
      PlatformDomainObjectType.APPLICATION_MENU,
      PlatformPermission.READ,
    )
  ) {
    return undefined;
  }

  if (
    !applications ||
    (Array.isArray(applications) && applications.length === 0) ||
    !currentTeam
  ) {
    return NO_APPLICATIONS;
  }

  const groupMatch = currentGroups.find(
    ({ groupName }) => groupName === "TEAM_ADMIN",
  );
  const group = groupMatch ? groupMatch.groupName : "";

  const filteredApplications = filterApplicationsByVisibility(
    filterRealApplications(
      filterTeamApplications(applications, currentTeam.id),
    ),
    group,
  );

  if (
    !filteredApplications ||
    (Array.isArray(filteredApplications) && filteredApplications.length === 0)
  ) {
    return NO_APPLICATIONS;
  }

  return filteredApplications.map(appItem => ({
    name: appItem.displayName,
    url: getApplicationUrl(appItem),
  }));
}

const TopMenuBar = () => {
  const { checkPermission } = usePermissions();
  const getCurrentGroupsQueryResult = useGetCurrentGroups();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getApplicationsQueryResult = useGetApplications();

  const currentGroups = getCurrentGroupsQueryResult.data ?? [];
  const currentTeam = getCurrentTeamQueryResult.data;

  const apps = getApplications({
    applications: getApplicationsQueryResult.data,
    currentGroups,
    currentTeam,
    checkPermission,
  });

  return (
    <AppBar
      sx={{
        backgroundColor: Background.default,
      }}
    >
      <Toolbar
        sx={{
          px: {
            xs: 4,
            sm: 8,
          },
        }}
      >
        <HomeLink label="Chemaxon Cloud" />

        <NavigationToolbar />

        <AppBarRight>
          <ProfileElement />

          {apps && <AppsList apps={apps} />}
        </AppBarRight>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenuBar;
