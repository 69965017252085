import {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import log from "loglevel";

type SetValue<T> = Dispatch<SetStateAction<T>>;

type ValueObject<TValue> = {
  schemaVersion: number;
  value: TValue;
};

const DEFAULT_SCHEMA_VERSION = 1;

export const useLocalStorage = <TValue>(
  key: string,
  initialValue: TValue,
  schemaVersion: number = DEFAULT_SCHEMA_VERSION,
) => {
  const readValue = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      const parsedItem = item ? parseJSON<ValueObject<TValue>>(item) : null;

      if (
        parsedItem &&
        parsedItem.schemaVersion &&
        parsedItem.schemaVersion === schemaVersion &&
        parsedItem.value
      ) {
        return parsedItem.value;
      }

      if (
        parsedItem &&
        parsedItem.schemaVersion &&
        parsedItem.schemaVersion !== schemaVersion
      ) {
        log.warn("Schema version change detected for localstorage key:", key);
        log.warn("Previous value was:", parsedItem);
        log.warn("Resetting to default value:", initialValue);
      }

      return initialValue;
    } catch (error) {
      log.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState(readValue);

  // Handle setting value both in state and in localstorage.
  const setValue: SetValue<TValue> = value => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      const valueObject = createValueObject(newValue, schemaVersion);

      window.localStorage.setItem(key, JSON.stringify(valueObject));

      setStoredValue(newValue);
    } catch (error) {
      log.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  // Re-save data upon hook mount, to ensure reset upon schema change is persisted.
  useEffect(() => {
    setValue(storedValue);
  }, []);

  return [storedValue, setValue] as const;
};

function parseJSON<T>(value: string): T | null {
  try {
    return JSON.parse(value);
  } catch (error) {
    log.warn("parsing error on", { value }, error);
    return null;
  }
}

const createValueObject = <TValue>(value: TValue, schemaVersion: number) => ({
  schemaVersion,
  value,
});
