export const selectStyles = {
  container: provided => {
    return {
      ...provided,
      fontSize: "14px",
      pointerEvents: "all",
    };
  },
  valueContainer: provided => ({
    ...provided,
  }),
  clearIndicator: provided => ({
    ...provided,
    position: "absolute",
    right: 28,
    top: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (provided, state) => {
    let backgroundColor = "transparent";
    let fontWeight = 400;
    if (state.isFocused) {
      backgroundColor = "#F1F3F4";
    }
    if (state.isSelected) {
      fontWeight = 600;
    }

    let height = 36;
    let paddingLeft = 40;
    let lineHeight = "20px";

    return {
      ...provided,
      position: "relative",
      minHeight: height,
      lineHeight,
      paddingLeft,
      color: "#2D3539",
      backgroundColor,
      fontWeight,
      "&:active": {
        backgroundColor: "#E2E7E9",
      },
    };
  },
  multiValue: (provided, state) => {
    let backgroundColor;

    if (state.isDisabled) {
      backgroundColor = "#E2E7E9";
    } else {
      backgroundColor = "#F1F3F4";
    }

    return {
      ...provided,
      borderRadius: 8,
      padding: "0 2px",
      backgroundColor,
    };
  },
  multiValueLabel: provided => {
    let fontSize = "14px";
    let padding = "6px 2px";

    return {
      ...provided,
      padding,
      fontSize,
    };
  },
  multiValueRemove: provided => {
    let paddingLeft = 4;

    return {
      ...provided,
      color: "#70858F",
      cursor: "pointer",
      paddingLeft,
      "&:hover": {
        backgroundColor: "transparent",
        color: "#2D3539",
      },
    };
  },
  control: (provided, state) => {
    let paddingLeft = state.hasValue && state.isMulti ? "4px" : "12px";

    const padding = state.selectProps.isClearable
      ? `0 0px 0 ${paddingLeft}`
      : `1px 0px 1px ${paddingLeft}`;

    if (state.theme.error) {
      return {
        ...provided,
        padding,
        boxShadow: "0 0 0 1px #E61919",
        borderColor: "#E61919",
        transition: "none",
        "&:hover": {
          borderColor: "#E61919",
        },
      };
    }

    if (state.isDisabled) {
      return {
        ...provided,
        padding,
        backgroundColor: "#F1F3F4",
        cursor: "not-allowed",
        borderColor: "#C6CED2",
        opacity: 0.8,
        "&:hover": {
          borderColor: "#C6CED2",
        },
      };
    }

    const borderColor = state.isFocused ? "#195EE6" : "#C6CED2";
    const borderHoverColor = state.isFocused ? "#195EE6" : "#A9B6BC";
    const boxShadow = state.isFocused ? "0 0 0 1px #195EE6" : "none";

    return {
      ...provided,
      padding,
      borderColor,
      boxShadow,
      transition: "none",
      "&:hover": {
        borderColor: borderHoverColor,
      },
    };
  },
  menuList: () => {
    let maxHeight = 232;

    return {
      maxHeight,
      padding: "4px 0",
      overflow: "auto",
    };
  },
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided, state) => {
    const opacity = state.isDisabled ? 0.65 : 1;
    return {
      ...provided,
      color: "#A9B6BC",
      fontStyle: "italic",
      opacity,
    };
  },
};
