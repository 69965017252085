import PropTypes from "prop-types";
import React from "react";
import { Avatar, Button, Typography } from "@chemaxon/design-system";
import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from "@mui/material";
import { BaseCard } from "src/ui/components/card/BaseCard";

function ApplicationCard(props) {
  const iconUrl = (props.appIcon || {}).iconData
    ? `data:${props.appIcon.iconMediaType};base64,${props.appIcon.iconData}`
    : "";

  return (
    <BaseCard>
      <CardHeader
        avatar={
          <Avatar
            variant="square"
            size="xl"
            src={iconUrl}
            sx={{ backgroundColor: "transparent" }}
            imgProps={{
              sx: {
                objectFit: "contain",
              },
            }}
          />
        }
        title={<Typography variant="label1">{props.name}</Typography>}
        subheader={<Typography variant="body2">{props.description}</Typography>}
        disableTypography
      />

      <CardActions sx={{ p: 4 }}>
        <Button colorVariant="primary" href={props.productUrl} target="_blank">
          {" "}
          Open{" "}
        </Button>
        {props.productPageUrl && (
          <Button
            colorVariant="secondary"
            href={props.productPageUrl}
            target="_blank"
          >
            {" "}
            Learn More{" "}
          </Button>
        )}
      </CardActions>

      {props.deepLinks && (
        <React.Fragment>
          <Divider />
          <CardContent sx={{ px: 4, pt: 4, pb: 6 }}>
            <Stack spacing={2} alignItems="flex-start">
              {props.deepLinks.map(link => (
                <Button
                  colorVariant="text"
                  href={link.url}
                  key={link.url}
                  target="_blank"
                >
                  {link.name}
                </Button>
              ))}
            </Stack>
          </CardContent>
        </React.Fragment>
      )}
    </BaseCard>
  );
}

ApplicationCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  appIcon: PropTypes.object,
  productUrl: PropTypes.string,
  productPageUrl: PropTypes.string,
  deepLinks: PropTypes.array,
};

export default ApplicationCard;
