import PropTypes from "prop-types";
import React from "react";
import log from "loglevel";
import { flow } from "lodash";
import moment from "moment";
import Dropzone from "react-dropzone";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Link,
} from "@mui/material";
import { Button, Switch, TextField, Typography } from "@chemaxon/design-system";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import TeamService, { useAddTeam } from "src/ui/services/TeamService";
import Dialog from "src/ui/components/dialog/Dialog";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import Subheader from "src/ui/components/subheader/Subheader";
import CustomMuiNumber from "src/ui/components/Number/Number";
import withRouter from "src/ui/utils/WithRouter";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormActions from "src/ui/components/form/FormActions";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";

import "./AddTeamView.scss";
import { withSnackbar } from "src/ui/utils/withSnackbar";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

const UTC_OFFSET = -12 * 60;

const HELPER_TEXTS = {
  teamName: "Please enter the name of the team (e.g. biopharma)",
  expirationDate:
    "The subscription expires the day after the set date (UTC-12)",
  teamSize: "Maximum size of the team",
  subdomain: "Subdomain of the instance",
  adminEmails: "Please enter the email address of the Team Administrator",
};

class AddTeamView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        teamName: "",
        adminEmails: "",
        expirationDate: moment()
          .utcOffset(UTC_OFFSET)
          .add({ day: 1 })
          .startOf("day")
          .add({ month: 1 })
          .toISOString(),
        teamSize: "",
        subdomain: "",
      },
      formErrors: {
        teamName: "",
        adminEmails: "",
        teamSize: "",
        subdomain: "",
      },
      customEusa: null,
      hasCustomEusa: false,
      loadingEusa: false,
      alertEusa: false,
      fileLimitExceeded: false,
      savingTeam: false,
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.hasCustomEusa && this.state.customEusa === null) {
      this.setState({
        alertEusa: true,
      });
      return;
    }

    const teamData = Object.assign({}, this.state.formData);

    teamData.adminEmails = this.state.formData.adminEmails
      ? this.state.formData.adminEmails.split(",").map(_ => _.trim())
      : [];

    this.props.teamService
      .addTeam(teamData)
      .then(teamId => {
        if (this.state.hasCustomEusa) {
          TeamService.uploadEusa(teamId, this.state.customEusa)
            .then(teamId => {
              this.props.router.navigate("/team/edit/" + teamId);
            })
            .catch(err => {
              log.error("Failed to upload the custom EUSA file.", err);
              this.props.router.navigate("/team/edit/" + teamId, {
                state: {
                  formErrorMessage:
                    "Team has been created but upload of the custom EUSA file has failed.",
                },
              });
            });
        } else {
          this.props.router.navigate("/team/edit/" + teamId);
        }
      })
      .catch(err => {
        let newFormErrors = {};
        const fieldErrors = err.getFieldErrors();
        if (fieldErrors.length > 0) {
          err.getFieldErrors().forEach(e => {
            newFormErrors[e.field] = e.message;
          });
        } else if (typeof err.message === "string") {
          this.props.snackbar.enqueueSnackbar(`Failed to add team.`, {
            variant: "error",
            persist: true,
            action: onCloseAction(this.props.snackbar.closeSnackbar),
          });
        }
        this.setState({
          formErrors: newFormErrors,
        });
        log.error("Failed to save changes of the team.", err);
      })
      .finally(() => {
        this.setState({
          savingTeam: false,
        });
      });
  };

  handleDateChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: moment(event.target.value)
          .utcOffset(UTC_OFFSET)
          .add({ day: 1 })
          .startOf("day")
          .toISOString(),
      };
      const newFormErrors = {
        ...this.state.formErrors,
        [fieldName]: moment(newFormData[fieldName]).isBefore()
          ? "Date should be in the future."
          : "",
      };
      this.setState({
        formData: newFormData,
        formErrors: newFormErrors,
      });
    };
  };

  handleTextFieldChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: event.target.value,
      };
      this.setState({
        formData: newFormData,
      });

      // clearing potentionally shown error message under the field
      delete this.state.formErrors[fieldName];
    };
  };

  handleNumberFieldChange = fieldName => {
    return value => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: value,
      };
      this.setState({
        formData: newFormData,
      });

      // clearing potentionally shown error message under the field
      delete this.state.formErrors[fieldName];
    };
  };

  handleSelectChange = fieldName => {
    return optionItem => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: optionItem ? optionItem.value : "",
      };
      this.setState({
        formData: newFormData,
      });

      delete this.state.formErrors[fieldName];
    };
  };

  handleSwitchChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: event.target.checked,
      };

      this.setState({
        formData: newFormData,
      });
    };
  };

  handleCustomEUSASwitch = event => {
    this.setState({
      hasCustomEusa: event.target.checked,
    });
  };

  removeEusaFile = () => {
    this.setState({
      customEusa: null,
      hasCustomEusa: false,
    });
  };

  handleEusaDialogClose = () => {
    this.setState({
      alertEusa: false,
    });
  };

  isInErrorState(field) {
    return !!(this.state.formErrors[field] || "");
  }

  getShownText(field) {
    return this.isInErrorState(field)
      ? this.state.formErrors[field]
      : HELPER_TEXTS[field];
  }

  getGeneralInputProps(field) {
    return {
      name: field,
      value: this.state.formData[field],
      autoComplete: "off",
      id: field,
    };
  }

  getGeneralProps(field) {
    return {
      helperText: (
        <HelperTextTypography
          text={this.getShownText(field)}
          isError={this.isInErrorState(field)}
        />
      ),
      error: this.isInErrorState(field),
    };
  }

  render() {
    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.TEAM,
            platformPermission: PlatformPermission.CREATE,
          }}
        >
          <Subheader title="Add team" />
          <ViewContainer id={"addTeamContainer"}>
            <form onSubmit={this.handleSubmit} id="addTeam">
              <FormLayoutVertical>
                <TextField
                  {...this.getGeneralProps("teamName")}
                  InputProps={this.getGeneralInputProps("teamName")}
                  type="text"
                  label="Team name"
                  variant="outlined"
                  autoFocus
                  required
                  onChange={this.handleTextFieldChange("teamName")}
                />
                <FormLayoutVertical className="addteam-custom-eusa-group">
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.handleCustomEUSASwitch}
                        name="customEusa"
                        checked={this.state.hasCustomEusa}
                      />
                    }
                    label="Custom subscription agreement"
                  />
                  {this.state.hasCustomEusa ? (
                    <React.Fragment>
                      <Dropzone
                        onDrop={() => {
                          this.setState({ loadingEusa: true });
                        }}
                        accept={"application/pdf"}
                        maxFiles={1}
                        maxSize={16777216}
                        onDropRejected={(fileRejections, event) => {
                          this.props.snackbar.enqueueSnackbar(
                            `Upload EUSA failed! Reasons: ${fileRejections[0].errors.reduce(
                              (accumulator, currentValue) => {
                                if (!accumulator) {
                                  return `${accumulator} ${currentValue.message}`;
                                }
                                return `${accumulator}, ${currentValue.message}`;
                              },
                              "",
                            )}`,
                            {
                              variant: "error",
                              persist: true,
                              action: onCloseAction(
                                this.props.snackbar.closeSnackbar,
                              ),
                            },
                          );
                          this.setState({ loadingEusa: false });
                        }}
                        onDropAccepted={(acceptedFiles, event) => {
                          this.setState({
                            customEusa: acceptedFiles[0],
                            loadingEusa: false,
                          });
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="addteam-drop-zone">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Typography align="center">
                                Drag & drop or&nbsp;
                                <Link underline="hover" href="#">
                                  browse
                                </Link>{" "}
                                pdf file <br />
                                (maximum size is 16MB)
                              </Typography>
                            </div>
                            {this.state.fileLimitExceeded ? (
                              <div className="addteam-eusa-error">
                                File limit exceeded
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.customEusa ? (
                              <aside>
                                <div className="addteam-custom-eusa">
                                  <div className="addteam-eusa-path">
                                    File:&nbsp;{this.state.customEusa.path}
                                  </div>
                                  <IconButton
                                    edge="end"
                                    aria-label="Remove"
                                    onClick={this.removeEusaFile}
                                    size="large"
                                  >
                                    <Delete />
                                  </IconButton>
                                </div>
                              </aside>
                            ) : null}
                          </section>
                        )}
                      </Dropzone>
                      <Backdrop
                        open={this.state.loadingEusa}
                        className="addteam-loader"
                        timeout={300}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </React.Fragment>
                  ) : null}
                </FormLayoutVertical>

                <Dialog
                  open={this.state.alertEusa}
                  title="Custom EUSA"
                  subtitle="Upload the custom EUSA contract file or uncheck the EUSA switch."
                  actions={[
                    {
                      id: "eusa-dialog-close",
                      label: "Close",
                      colorVariant: "secondary",
                      onClick: this.handleEusaDialogClose,
                    },
                  ]}
                  onClose={this.handleEusaDialogClose}
                />

                <TextField
                  type="date"
                  required
                  {...this.getGeneralProps("expirationDate")}
                  InputProps={{
                    value: moment(this.state.formData.expirationDate).format(
                      "YYYY-MM-DD",
                    ),
                    id: "expirationDate",
                  }}
                  label="Subscription expiration"
                  onChange={this.handleDateChange("expirationDate")}
                />
                <CustomMuiNumber
                  {...this.getGeneralProps("teamSize")}
                  InputProps={this.getGeneralInputProps("teamSize")}
                  inputProps={{ min: 1 }}
                  required
                  label="Team size"
                  onChange={this.handleTextFieldChange("teamSize")}
                  valueChangeHandler={this.handleNumberFieldChange("teamSize")}
                />

                <TextField
                  {...this.getGeneralProps("subdomain")}
                  InputProps={this.getGeneralInputProps("subdomain")}
                  label="Subdomain"
                  required
                  onChange={this.handleTextFieldChange("subdomain")}
                />

                <TextField
                  {...this.getGeneralProps("adminEmails")}
                  InputProps={this.getGeneralInputProps("adminEmails")}
                  label="Administrator's email"
                  required
                  onChange={this.handleTextFieldChange("adminEmails")}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.handleSwitchChange("liveChatOn")}
                        name="liveChatOn"
                      />
                    }
                    label="Live chat"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.handleSwitchChange("demoTeam")}
                        name="demoTeam"
                      />
                    }
                    label="Demo team"
                    id="demoTeam"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.formData.federatedAuth}
                        onChange={this.handleSwitchChange("federatedAuth")}
                        name="federatedAuth"
                      />
                    }
                    label="Federated authentication"
                    id="federatedAuth"
                  />
                </FormGroup>

                <FormActions>
                  <Button
                    type="submit"
                    colorVariant="primary"
                    loading={this.props.teamService.isAddTeamLoading}
                  >
                    Register team
                  </Button>
                  <Button
                    onClick={this.handleBackButton}
                    colorVariant="secondary"
                  >
                    Go back
                  </Button>
                </FormActions>

                <Backdrop
                  open={this.state.savingTeam}
                  className="addteam-loader"
                  timeout={300}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </FormLayoutVertical>
            </form>
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }

  handleBackButton = () => {
    this.props.router.navigate("/teams");
  };
}

AddTeamView.propTypes = {
  router: PropTypes.object,
  teamService: PropTypes.shape({
    addTeam: PropTypes.func,
    isAddTeamLoading: PropTypes.bool,
  }),
  snackbar: PropTypes.shape({
    enqueueSnackbar: PropTypes.func,
    closeSnackbar: PropTypes.func,
  }),
};

const withTeamService = WrappedComponent => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTeamService = props => {
    const mutateAddTeam = useAddTeam();

    const teamService = {
      addTeam: mutateAddTeam.mutateAsync,
      isAddTeamLoading: mutateAddTeam.isPending,
    };

    return <WrappedComponent {...props} teamService={teamService} />;
  };

  ComponentWithTeamService.displayName = `WithTeamService(${displayName})`;

  return ComponentWithTeamService;
};

const enhance = flow(withSnackbar, withTeamService, withRouter);

export default enhance(AddTeamView);
