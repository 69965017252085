import PropTypes from "prop-types";
import React from "react";
import ReactJson from "@microlink/react-json-view";
import JsonViewerFields from "src/ui/views/applications/JsonViewerFields";
import _ from "lodash";

class JsonViewer extends React.Component {
  static createData(inputData) {
    return JSON.stringify(_.pick(inputData, JsonViewerFields));
  }

  render() {
    return (
      <div
        style={{
          fontSize: 11,
          whiteSpace: "nowrap",
          height: window.innerHeight / 2,
          width: "100%",
          overflow: "auto",
          textAlign: "left",
        }}
      >
        <ReactJson
          src={JSON.parse(this.props.src)}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </div>
    );
  }
}

JsonViewer.propTypes = {
  src: PropTypes.string,
};

export default JsonViewer;
