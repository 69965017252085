import React, { useEffect, useState } from "react";
import log from "loglevel";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileCopy from "@chemaxon/design-system/icons/FileCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Button,
  ButtonProps,
  InputAdornment,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
} from "@chemaxon/design-system";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { Restricted } from "src/ui/utils/Restricted";
import {
  useEditApplication,
  useGetApplication,
  useGetApplicationInfo,
} from "src/ui/services/ApplicationService";
import InformationDialog from "src/ui/components/information/InformationDialog";
import JsonViewer from "src/ui/components/json-viewer/JsonViewer";
import {
  isFeatureNamespace,
  getDefaultValueForHiddenField,
} from "src/ui/services/applicationUtils";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import FormHeader from "src/ui/components/form/FormHeader";
import FormActions from "src/ui/components/form/FormActions";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";
import {
  Application,
  ApplicationEditRequest,
  ApplicationFields,
  FeatureNamespace,
} from "src/ui/models/Application";
import { Team } from "src/ui/models/Team";
import TeamService, { useGetTeams } from "src/ui/services/TeamService";
import { AppRoutePath } from "src/ui/utils/routes";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

enum FormField {
  clientId = "clientId",
  clientSecret = "clientSecret",
  displayName = "displayName",
  address = "address",
  appInfoUrl = "appInfoUrl",
  notes = "notes",
  hidden = "hidden",
}

type FormData = {
  [FormField.clientId]: string;
  [FormField.clientSecret]: string;

  [FormField.displayName]: string;
  [FormField.address]: string;
  [FormField.notes]: string;
  [FormField.hidden]: boolean;

  [FormField.appInfoUrl]: string;
};

type FormErrors = {
  [key in FormField]: string;
};

const HELPER_TEXTS = {
  [FormField.appInfoUrl]:
    "Please provide an info URL and make sure it is valid. Click the refresh button if you change it so you can save the changes",
};

const isFieldInHelperTexts = (
  field: string,
): field is keyof typeof HELPER_TEXTS =>
  typeof (HELPER_TEXTS as Record<string, string>)[field] !== "undefined";

const INITIAL_STATE_FORM_ERRORS = {
  [FormField.clientId]: "",
  [FormField.clientSecret]: "",
  [FormField.displayName]: "",
  [FormField.address]: "",
  [FormField.appInfoUrl]: "",
  [FormField.notes]: "",
  [FormField.hidden]: "",
} satisfies FormErrors;

type EditApplicationViewParams = {
  appId: string;
};

const getAppInfoUrl = (application: Application) => {
  if (!application.features) {
    return "";
  }

  const appInfoFeature = application.features.find(
    isFeatureNamespace(FeatureNamespace.AppInfo),
  );

  return appInfoFeature && appInfoFeature.attributes.url;
};

const EditApplicationView = () => {
  const [showingRawJson, setShowingRawJson] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [hasValidInfoUrl, setHasValidInfoUrl] = useState(false);
  const [formData, setFormData] = useState<Partial<FormData>>({});
  const [formErrors, setFormErrors] = useState<FormErrors>(
    INITIAL_STATE_FORM_ERRORS,
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [parentTeam, setParentTeam] = useState<Team>();
  const getTeamsQueryResult = useGetTeams();
  const navigate = useNavigate();
  const { appId } = useParams<EditApplicationViewParams>();
  const getApplicationQueryResult = useGetApplication(
    Number(appId),
    Boolean(appId),
  );
  const getAppInfoUrlResult = useGetApplication(
    Number(appId),
    Boolean(appId),
    getAppInfoUrl,
  );
  const getApplicationInfoQueryResult = useGetApplicationInfo(
    formData.appInfoUrl ?? getAppInfoUrlResult.data,
    false,
  );
  const mutateEditApplication = useEditApplication();

  const teams = getTeamsQueryResult.data;

  useEffect(() => {
    if (
      teams &&
      getApplicationQueryResult.data &&
      getApplicationQueryResult.data.team
    ) {
      setParentTeam(
        TeamService.lookupTeamById(teams, getApplicationQueryResult.data.team),
      );
    }
  }, [teams, getApplicationQueryResult.data]);

  useEffect(() => {
    if (getAppInfoUrlResult.data) {
      setHasValidInfoUrl(true);
    }
  }, [getAppInfoUrlResult.data]);

  if (getApplicationQueryResult.status === "pending") {
    return <div>Loading...</div>;
  }

  if (getApplicationQueryResult.status === "error") {
    return (
      <div>
        <h3>This application cannot be viewed</h3>
        <div>
          It may have been deleted or you do not have permission to view it.
        </div>
      </div>
    );
  }

  const pageData = {
    ...getApplicationQueryResult.data,
    ...getApplicationInfoQueryResult.data,
    ...formData,
  };

  const closeRawJsonDialog = () => {
    setShowingRawJson(false);
  };

  const openRawJsonDialog: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    event.stopPropagation();
    setShowingRawJson(true);
  };

  const handleChange =
    (fieldName: FormField): TextFieldProps["onChange"] =>
    event => {
      if (fieldName === FormField.appInfoUrl) {
        setHasValidInfoUrl(false);
      }

      const newData = {
        ...formData,
        [fieldName]: event.target.value,
      } satisfies Partial<FormData>;

      setFormData(newData);
      setDirty(true);
    };

  const handleSwitchChange =
    (fieldName: FormField.hidden): SwitchProps["onChange"] =>
    event => {
      const newData = {
        ...formData,
        [fieldName]: event.target.checked,
      } satisfies Partial<FormData>;

      setFormData(newData);
      setDirty(true);
    };

  const renderCopyAdornment = (
    fieldName: FormField.clientId | FormField.clientSecret,
  ) => {
    const fieldValue = getFieldValue(fieldName);
    let text = "";

    if (typeof fieldValue !== "undefined") {
      text = fieldValue;
    }

    return (
      <InputAdornment position="end">
        <CopyToClipboard text={text}>
          <IconButton
            title="Copy to clipboard"
            onMouseDown={event => {
              event.preventDefault();
            }}
            size="large"
          >
            <FileCopy />
          </IconButton>
        </CopyToClipboard>
      </InputAdornment>
    );
  };

  const getShownText = (field: FormField) => {
    if (isInErrorState(field)) {
      return formErrors[field];
    }

    if (isFieldInHelperTexts(field)) {
      return HELPER_TEXTS[field];
    }

    return "";
  };

  const isInErrorState = (field: FormField) => {
    return !!(formErrors[field] || "");
  };

  const getGeneralInputProps = (field: FormField) => {
    return {
      name: field,
      id: field,
      autoComplete: "off",
    } satisfies React.InputHTMLAttributes<HTMLInputElement>;
  };

  const getFieldValue = <TField extends FormField>(field: TField) => {
    const formFieldValue = formData[field];
    const backendFieldValue =
      getApplicationQueryResult.data &&
      getApplicationQueryResult.data[field as TField & ApplicationFields];
    let extraValue;

    if (
      field === FormField.appInfoUrl &&
      typeof getAppInfoUrlResult.data !== "undefined"
    ) {
      extraValue = getAppInfoUrlResult.data;
    }

    return formFieldValue ?? backendFieldValue ?? extraValue ?? "";
  };

  const getGeneralProps = (field: FormField) => {
    return {
      value: getFieldValue(field),
      className: isInErrorState(field) ? "error" : "",
      helperText: (
        <HelperTextTypography
          text={getShownText(field)}
          isError={isInErrorState(field)}
        />
      ),
      error: isInErrorState(field),
      onChange: handleChange(field),
    } satisfies TextFieldProps;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (
      mutateEditApplication.isPending ||
      getApplicationInfoQueryResult.isFetching
    ) {
      return;
    }

    const dataToSubmit: ApplicationEditRequest = {
      ...getApplicationQueryResult.data,
      ...getApplicationInfoQueryResult.data,
      ...formData,
    };

    mutateEditApplication
      .mutateAsync(dataToSubmit)
      .then(() => {
        navigate(AppRoutePath.ApplicationList);
      })
      .catch((err: unknown) => {
        if (err instanceof SynergySpringMVCError) {
          const newFormErrors: FormErrors & {
            [key in ApplicationFields]?: string;
          } = { ...INITIAL_STATE_FORM_ERRORS };

          err.getFieldErrors().forEach(e => {
            newFormErrors[e.field as FormField] = e.message;
          });

          let errorMessage = "An error occurred while processing your request.";
          const notVisibleFields = Object.keys(newFormErrors).filter(
            (item): item is ApplicationFields =>
              !Object.keys(INITIAL_STATE_FORM_ERRORS).includes(item),
          );

          if (notVisibleFields) {
            notVisibleFields.forEach(field => {
              errorMessage = `${errorMessage} ${newFormErrors[field]}`;
            });
          }

          setFormErrors(newFormErrors);
          enqueueSnackbar(errorMessage, {
            variant: "error",
            persist: true,
            action: onCloseAction(closeSnackbar),
          });

          log.error("Failed to save changes of the application.", err);
        } else {
          log.error("Failed to save changes of the application.", err);
        }
      });
  };

  const handleBackButton: ButtonProps["onClick"] = event => {
    event.preventDefault();
    navigate(AppRoutePath.ApplicationList);
  };

  const refreshAppInfo = () => {
    getApplicationInfoQueryResult
      .refetch({ throwOnError: true })
      .then(appInfo => {
        if (!appInfo.data) {
          setHasValidInfoUrl(false);
          return;
        }

        setDirty(true);
        setHasValidInfoUrl(true);

        const newFormData: Partial<FormData> = {
          ...formData,
          [FormField.hidden]: getDefaultValueForHiddenField(appInfo.data),
          [FormField.address]: appInfo.data.address,
          [FormField.displayName]: appInfo.data.displayName,
        };

        setFormData(newFormData);
        setFormErrors(INITIAL_STATE_FORM_ERRORS);
      })
      .catch(err => {
        const newFormErrors: FormErrors = {
          ...INITIAL_STATE_FORM_ERRORS,
          [FormField.appInfoUrl]:
            "The provided URL is invalid. Please change it and click on the refresh button",
        };

        setHasValidInfoUrl(false);
        setFormErrors(newFormErrors);

        log.error(err);
      });
  };

  const renderTeamLink = () => {
    return parentTeam && parentTeam.id && parentTeam.teamName ? (
      <Link className="styled-link" to={`/team/edit/${parentTeam.id}`}>
        {parentTeam.teamName}
      </Link>
    ) : null;
  };

  const applicationName = getFieldValue(FormField.displayName);

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.APPLICATION,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader
          title={applicationName ? `Edit Application: ${applicationName}` : ""}
        />
        <ViewContainer id={"editApplicationContainer"}>
          <React.Fragment>
            <form id="editApplication" onSubmit={handleSubmit}>
              <FormLayoutVertical>
                <FormHeader
                  title={
                    <React.Fragment>
                      Assigned to: {renderTeamLink()}
                    </React.Fragment>
                  }
                />

                <TextField
                  {...getGeneralProps(FormField.clientId)}
                  InputProps={{
                    ...getGeneralInputProps(FormField.clientId),
                    endAdornment: renderCopyAdornment(FormField.clientId),
                  }}
                  label="Client ID"
                  readOnly={true}
                  disabled={true}
                />

                <TextField
                  {...getGeneralProps(FormField.clientSecret)}
                  InputProps={{
                    ...getGeneralInputProps(FormField.clientSecret),
                    endAdornment: renderCopyAdornment(FormField.clientSecret),
                  }}
                  label="Client Secret"
                  readOnly={true}
                  disabled={true}
                />

                <TextField
                  {...getGeneralProps(FormField.displayName)}
                  InputProps={getGeneralInputProps(FormField.displayName)}
                  autoFocus
                  required
                  label="Name (required)"
                />

                <TextField
                  {...getGeneralProps(FormField.address)}
                  InputProps={getGeneralInputProps(FormField.address)}
                  placeholder="<No address provided yet. Add app info url to load one.>"
                  label="Address"
                />

                <Stack direction="row" alignItems="flex-start" spacing={6}>
                  <TextField
                    {...getGeneralProps(FormField.appInfoUrl)}
                    InputProps={getGeneralInputProps(FormField.appInfoUrl)}
                    label="Application info URL"
                  />
                  <Button
                    colorVariant="outlined"
                    sx={theme => ({ "&&": { mt: theme.spacing(6) } })}
                    size="medium"
                    onClick={refreshAppInfo}
                    loading={getApplicationInfoQueryResult.isFetching}
                  >
                    Refresh
                  </Button>
                </Stack>

                <TextField
                  label="Notes"
                  {...getGeneralProps(FormField.notes)}
                  InputProps={{
                    ...getGeneralInputProps(FormField.notes),
                    multiline: true,
                  }}
                />

                <FormControlLabel
                  label="Hidden"
                  control={
                    <Switch
                      onChange={handleSwitchChange(FormField.hidden)}
                      inputProps={{
                        ...getGeneralInputProps(FormField.hidden),
                      }}
                      checked={getFieldValue(FormField.hidden)}
                    />
                  }
                />

                <FormActions>
                  {dirty ? (
                    <React.Fragment>
                      <Button
                        colorVariant="primary"
                        type="submit"
                        disabled={
                          !hasValidInfoUrl ||
                          getApplicationInfoQueryResult.isFetching
                        }
                        loading={mutateEditApplication.isPending}
                      >
                        Save
                      </Button>
                      <Button
                        colorVariant="secondary"
                        onClick={handleBackButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        colorVariant="secondary"
                        onClick={openRawJsonDialog}
                      >
                        Raw JSON data
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        colorVariant="secondary"
                        onClick={handleBackButton}
                      >
                        Back
                      </Button>
                      <Button
                        colorVariant="secondary"
                        onClick={openRawJsonDialog}
                      >
                        Raw JSON data
                      </Button>
                    </React.Fragment>
                  )}
                </FormActions>
              </FormLayoutVertical>
            </form>

            <InformationDialog
              isShown={showingRawJson}
              close={closeRawJsonDialog}
            >
              <JsonViewer src={JsonViewer.createData(pageData)} />
            </InformationDialog>
          </React.Fragment>
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
};

export default EditApplicationView;
