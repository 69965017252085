import { Error } from "@chemaxon/design-system/color";
import { inputLabelClasses } from "@mui/material";

export const MuiFormElementOverrides = {
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: ({ theme }) => ({
        position: "relative",
        transform: "none",
        marginBottom: theme.spacing(1),
        fontSize: "14px",
        zIndex: 0,
        color: "#1E1F24",
        [`&.${inputLabelClasses.focused}`]: {
          color: "#1E1F24",
        },
      }),
      asterisk: {
        color: Error.default,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginLeft: theme.spacing(0),
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      }),
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: "small",
    },
  },
};
