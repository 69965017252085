import { queryOptions, useQuery } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import { CsrfResponse } from "src/ui/models/Csrf";
import queryClient from "src/ui/utils/queryClient";

export const SERVICE_URL = "/resources/csrf";

class CsrfService {
  static getCsrf(): Promise<CsrfResponse> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "csrf";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  csrf: () => [QUERY_KEYS_NAMESPACE, "csrf"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const getCsrfQueryOptions = <TData = CsrfResponse>(
  select?: (data: CsrfResponse) => TData,
) =>
  queryOptions({
    queryKey: queryKeys.csrf(),
    queryFn: () => CsrfService.getCsrf(),
    select,
  });

export const useGetCsrfQuery = <TData = CsrfResponse>(
  select?: (data: CsrfResponse) => TData,
) => useQuery(getCsrfQueryOptions(select));

queryClient.setQueryDefaults(queryKeys.csrf(), {
  staleTime: Infinity,
});

export default CsrfService;
