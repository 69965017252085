import { Button } from "@chemaxon/design-system";

const RemoveMemberButton = (props: Props) => {
  return props.hasPermission ? (
    <Button
      colorVariant="text"
      onClick={e => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      Remove
    </Button>
  ) : (
    <></>
  );
};

type Props = {
  hasPermission: boolean;
  onClick: () => void;
};

export default RemoveMemberButton;
