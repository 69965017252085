import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import type {
  ProjectAddRequest,
  ProjectEditRequest,
  ProjectGetResponse,
  ProjectsGetResponse,
} from "src/ui/models/Project";
import { queryKeys as teamMemberQueryKeys } from "src/ui/services/TeamMemberService";
import { queryKeys as userInfoQueryKeys } from "src/ui/services/UserInfoService";

export const SERVICE_URL = "/internal/projects";

class ProjectService {
  /**
   * Add new project.
   */
  static addProject(input: ProjectAddRequest): Promise<number> {
    return Api.post(SERVICE_URL, input);
  }

  /**
   * Get project details.
   */
  static getProject(
    projectId: number | undefined,
  ): Promise<ProjectGetResponse> {
    if (projectId === undefined) {
      return Promise.reject(new Error("Invalid project id"));
    }

    return Api.get(`${SERVICE_URL}/${projectId}`);
  }

  /**
   * Edit existing project.
   */
  static editProject(input: ProjectEditRequest): Promise<void> {
    return Api.put(SERVICE_URL, input);
  }

  /**
   * Get list of saved projects for team.
   */
  static getProjects(): Promise<ProjectsGetResponse> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "projects";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
  details: () => [QUERY_KEYS_NAMESPACE, "detail"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
  detail: (projectId: number | undefined) =>
    [...baseQueryKeys.details(), projectId] as const,
} satisfies QueryKeysDefinition;

export const useGetProjects = () =>
  useQuery({
    queryKey: queryKeys.list(),
    queryFn: () => ProjectService.getProjects(),
  });

export const useGetProject = (projectId: number | undefined) =>
  useQuery({
    queryKey: queryKeys.detail(projectId),
    queryFn: () => ProjectService.getProject(projectId),
  });

export const useAddProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: ProjectAddRequest) => ProjectService.addProject(input),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: teamMemberQueryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: userInfoQueryKeys.me(),
      });
    },
  });
};

export const useEditProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: ProjectEditRequest) =>
      ProjectService.editProject(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(input.id) });
      queryClient.invalidateQueries({ queryKey: teamMemberQueryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: userInfoQueryKeys.me(),
      });
    },
  });
};

export default ProjectService;
