import { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";

import { useGetMeQuery } from "src/ui/services/UserInfoService";
import { GtmCustomEvents } from "src/ui/utils/analytics";

export const usePendo = () => {
  const analytics = useAnalytics();
  const [isPendoUserInitialized, setIsPendoUserInitialized] = useState(false);
  const getMeQueryResult = useGetMeQuery();

  const user_email = getMeQueryResult?.data?.email;
  const user_tenant = getMeQueryResult?.data?.principal.team.subdomain;

  useEffect(() => {
    if (isPendoUserInitialized) {
      return;
    }

    if (user_email === undefined || user_tenant === undefined) {
      return;
    }

    analytics.track(GtmCustomEvents.PendoInitUser, {
      user_email,
      user_tenant,
    });

    setIsPendoUserInitialized(true);
  }, [isPendoUserInitialized, user_email, user_tenant]);
};
