import { Error } from "@chemaxon/design-system/color";
import DeleteForever from "@chemaxon/design-system/icons/DeleteForever";
import MoreMenuListButton from "src/ui/components/more-menu/MoreMenuListButton";

export default function RemoveButton(props: Props) {
  return (
    <MoreMenuListButton
      onClick={props.onClick}
      color={Error.default}
      icon={<DeleteForever />}
      text="Delete"
    />
  );
}

type Props = {
  onClick: () => void;
};
