import React from "react";
import { Card } from "@mui/material";
import { Outline, Surface } from "@chemaxon/design-system/color";

export const BaseCard = props => (
  <Card
    variant="outlined"
    sx={{
      backgroundColor: Surface.variant,
      borderColor: Outline.variant,
      borderRadius: 2,
    }}
    {...props}
  />
);
