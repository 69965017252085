export enum AppRoutePath {
  Dashboard = "/",

  ApplicationList = "/applications",
  ApplicationAdd = "/application/add",
  ApplicationEdit = "/application/edit/:appId",

  TeamList = "/teams",
  TeamAdd = "/team/add",
  TeamImport = "/team/import",
  TeamEdit = "/team/edit/:teamId",

  TeamMemberList = "/team/members",
  TeamMemberAdd = "/team/member/add",

  GroupList = "/groups",

  ProjectList = "/projects",
  ProjectAdd = "/project/add",
  ProjectEdit = "/project/edit/:projectId",

  ResourceList = "/resources",

  Notifications = "/notifications",
  ErrorPage = "/error",
  LoginErrorPage = "/loginError",
  Unsubscribe = "/unsubscribe",
}
