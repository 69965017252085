export interface Application {
  id: number;
  team: number;
  clientId: string;
  clientSecret?: string;
  displayName: string;
  address?: string;
  notes?: string;
  hidden: boolean;
  features: Feature[];
  identities: Identity[];
  status: Status;
  iconMediaType?: IconMediaType;
  iconData?: string;
}

export type ApplicationFields = keyof Application;

export interface ApplicationInfo {
  displayName: string;
  address: string;
  identities: Identity[];
  features: Feature[];
}

export interface ApplicationAddRequest {
  team: string;
  displayName: string;
}

export type ApplicationEditRequest = Omit<
  Application,
  "iconMediaType" | "iconData"
>;

type BaseFeature<
  TNamespace extends string,
  TAttribtues extends Record<string, unknown>,
> = {
  namespace: TNamespace;
  attributes: TAttribtues;
};

export type Feature =
  | AppInfoFeature
  | HealthFeature
  | AppIconFeature
  | WebFeature
  | LoginFeature
  | LogoutFeature
  | DeeplinksFeature
  | EventTypesFeature
  | VisibilityFeature;

export type AppInfoFeature = BaseFeature<
  FeatureNamespace.AppInfo,
  {
    url: string;
  }
>;

export type HealthFeature = BaseFeature<
  FeatureNamespace.Health,
  {
    url: string;
  }
>;

export type AppIconFeature = BaseFeature<
  FeatureNamespace.Icon,
  {
    url: string;
  }
>;

export type WebFeature = BaseFeature<
  FeatureNamespace.Web,
  {
    mainEntryPoint: string;
  }
>;

export type LoginFeature = BaseFeature<
  FeatureNamespace.Login,
  {
    url: string;
  }
>;

export type LogoutFeature = BaseFeature<
  FeatureNamespace.Logout,
  {
    url: string;
  }
>;

export type DeeplinksFeature = BaseFeature<
  FeatureNamespace.Deeplinks,
  {
    links: Link[];
  }
>;

export type EventTypesFeature = BaseFeature<
  FeatureNamespace.EventTypes,
  {
    url: string;
  }
>;

export type VisibilityFeature = BaseFeature<
  FeatureNamespace.Visibility,
  {
    groups: string[];
  }
>;

export enum FeatureNamespace {
  Icon = "synergy/icon",
  Health = "synergy/health",
  Web = "synergy/web",
  AppInfo = "synergy/app-info",
  Login = "synergy/login",
  Logout = "synergy/logout",
  Deeplinks = "synergy/deeplinks",
  EventTypes = "synergy/event-types",
  Visibility = "synergy/visibility",
}

export interface Link {
  name: string;
  url: string;
}

export enum IconMediaType {
  ImagePNG = "image/png",
  ImageSVGXML = "image/svg+xml",
  TextHTMLCharsetUTF8 = "text/html;charset=utf-8",
}

type BaseIdentity<TCategory extends string, TType extends string> = {
  category: TCategory;
  type: TType;
};

export type Identity =
  | ApplicationIdentity
  | GroupIdentity
  | ProductIdentity
  | VendorIdentity;

export type ApplicationIdentity = BaseIdentity<
  Category.Application,
  ApplicationType
>;
export type GroupIdentity = BaseIdentity<Category.Group, GroupType>;
export type ProductIdentity = BaseIdentity<Category.Product, ProductType>;
export type VendorIdentity = BaseIdentity<Category.Vendor, VendorType>;

export enum ApplicationType {
  Webapp = "webapp",
  Service = "service",
}

export enum GroupType {
  Reporting = "reporting",
  Registration = "registration",
  Design = "design",
  Compliance = "compliance",
  Computation = "computation",
  Search = "search",
  Eln = "eln",
  Inventory = "inventory",

  // This IdentityType not exist on backend, but frontend logic handles it.
  // Identity Categories and Types can be freely set by the applications.
  Aggregation = "aggregation",
}

export enum ProductType {
  Assay = "assay",
  BiomoleculeToolkit = "biomolecule-toolkit",
  Chemlocator = "chemlocator",
  ComplianceChecker = "compliance-checker",
  Connect = "connect",
  MarvinLive = "marvin-live",
  Navigator = "navigator",
  Registration = "compound-registration",
  DesignHub = "design-hub",
}

export enum VendorType {
  Chemaxon = "chemaxon",
}

export enum Category {
  Application = "application",
  Group = "group",
  Product = "product",
  Vendor = "vendor",
}

export enum Status {
  Registered = "REGISTERED",
  Waiting = "WAITING",
  Up = "UP",
  ShuttingDown = "SHUTTING_DOWN",
  Error = "ERROR",
  Unknown = "UNKNOWN",
  Down = "DOWN",
}
