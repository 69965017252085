import React from "react";
import { useSnackbar } from "notistack";

export const withSnackbar = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithSnackbar = (props: TProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const snackbar = {
      enqueueSnackbar: enqueueSnackbar,
      closeSnackbar: closeSnackbar,
    };

    return <WrappedComponent {...props} snackbar={snackbar} />;
  };

  ComponentWithSnackbar.displayName = `WithSnackbar(${displayName})`;

  return ComponentWithSnackbar;
};
