import { Box, Container } from "@mui/material";
import { Button, Checkbox, FormControlLabel } from "@chemaxon/design-system";
import React, { useState } from "react";
import FormActions from "src/ui/components/form/FormActions.jsx";
import EusaContent from "./EusaContent";
import Paper from "@mui/material/Paper";
import { useAcceptEusa } from "src/ui/services/TeamMemberService";
import { useGetMeQuery } from "src/ui/services/UserInfoService";
import log from "loglevel";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical.jsx";
import { Background } from "@chemaxon/design-system/color";

const Eusa = () => {
  const getMeQueryResult = useGetMeQuery();
  const me = getMeQueryResult.data;
  const mutateAcceptEusa = useAcceptEusa();

  const [hasRead, setHasRead] = useState(false);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    mutateAcceptEusa.mutateAsync(me.principal.user.id).catch(err => {
      log.error("Failed to upload the custom EUSA file.", err);
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: Background.default,
        height: "100%",
        width: "100%",
      }}
    >
      <Container
        maxWidth={"lg"}
        sx={{
          height: "100%",
        }}
      >
        <Box
          sx={{
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            fontFamily: "'Roboto', sans-serif",
            justifyContent: "center",
          }}
        >
          <Paper elevation={3}>
            <EusaContent />
            <form id="eusaForm" onSubmit={handleSubmit}>
              <FormLayoutVertical
                justifyContent={"center"}
                alignItems={"center"}
                my={6}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"ném"}
                      onChange={e => setHasRead(e.target.checked)}
                    />
                  }
                  label={"I have read the subscription terms"}
                />
                <FormActions>
                  <Button
                    disabled={!hasRead}
                    colorVariant="primary"
                    type="submit"
                    loading={mutateAcceptEusa.isPending}
                  >
                    Accept
                  </Button>
                </FormActions>
              </FormLayoutVertical>
            </form>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default Eusa;
