import React from "react";
import { FormControl, FormGroup, FormLabel } from "@mui/material";
import { Button, Checkbox, FormControlLabel } from "@chemaxon/design-system";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import NotificationSettingsService from "src/ui/services/NotificationSettingsService";
import Subheader from "src/ui/components/subheader/Subheader";
import FormHeader from "src/ui/components/form/FormHeader";
import FormActions from "src/ui/components/form/FormActions";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";

class NotificationSettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationSettings: [],
    };
    NotificationSettingsService.getNotificationSettings().then(value => {
      this.setState({
        notificationSettings: value,
      });
    });
  }

  handleChange = event => {
    const newNotificationSettings = this.state.notificationSettings.map(
      notification => {
        if (notification.type === event.target.name) {
          notification.unsubscribed = !event.target.checked;
        }
        return notification;
      },
    );
    this.setState({
      notificationSettings: newNotificationSettings,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const unsubscribed = this.state.notificationSettings.map(notification => {
      return {
        type: notification.type,
        unsubscribed: notification.unsubscribed ? true : false,
      };
    });
    NotificationSettingsService.updateNotificationSettings(unsubscribed);
  };

  render() {
    return (
      <React.Fragment>
        <Subheader title="Notification settings" />
        <ViewContainer id={"notificationSettings"}>
          <form onSubmit={this.handleSubmit} id="notificationSettings">
            <FormLayoutVertical>
              <FormHeader title="Events" />

              <FormControl>
                <FormLabel>Send me email when:</FormLabel>
                <FormGroup>
                  {this.state.notificationSettings.map(notification => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!notification.unsubscribed}
                          onChange={this.handleChange}
                          name={notification.type}
                        />
                      }
                      label={notification.preferencesLabel}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormActions>
                <Button
                  colorVariant="primary"
                  className="submit-update-notification-settings"
                  type="submit"
                >
                  Update my preferences
                </Button>
              </FormActions>
            </FormLayoutVertical>
          </form>
        </ViewContainer>
      </React.Fragment>
    );
  }
}

export default NotificationSettingsView;
