import { queryOptions, useQuery } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import { PermissionMap } from "src/ui/models/Permission";
import queryClient from "src/ui/utils/queryClient";

export const SERVICE_URL = "/internal/permissions";

class PermissionService {
  static getPermissions(): Promise<PermissionMap> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "permissions";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const getPermissionsQueryOptions = () =>
  queryOptions({
    queryKey: queryKeys.list(),
    queryFn: () => PermissionService.getPermissions(),
  });

export const useGetPermissions = () => useQuery(getPermissionsQueryOptions());

queryClient.setQueryDefaults(queryKeys.list(), {
  staleTime: Infinity,
});

export default PermissionService;
