import { UseQueryResult } from "@tanstack/react-query";

type ExtractQueryResultDataType<Type> = Type extends UseQueryResult<infer TData>
  ? TData
  : never;

/**
 * Adapts the result of a `useQuery` call to be usable by a `ContainerWithLoaderAndError` component.
 *
 * @see https://tanstack.com/query/latest/docs/react/reference/useQuery
 * @see ContainerWithLoaderAndError
 */
export const adaptUseQueryResponseForContainerWithLoader = <
  TQueryResult extends UseQueryResult<TData>,
  TData = ExtractQueryResultDataType<TQueryResult>,
>(
  useQueryResult: TQueryResult,
) => {
  const { isPending, isError, isSuccess, data } = useQueryResult;

  if (isError) {
    return false;
  }

  if (isPending) {
    return undefined;
  }

  if (isSuccess) {
    return data;
  }
};
