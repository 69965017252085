import { ProjectStatus } from "src/ui/models/Project.ts";

export enum FormField {
  id = "id",
  displayId = "displayId",
  title = "title",
  externalId = "externalId",
  assigneeId = "assigneeId",
  description = "description",
  conclusion = "conclusion",
  status = "status",
  memberIds = "memberIds",
  newMemberId = "newMemberId",
}

export type FormData = {
  [FormField.id]: number;
  [FormField.displayId]: string;
  [FormField.title]: string;
  [FormField.externalId]: string;
  [FormField.assigneeId]: number;
  [FormField.description]: string;
  [FormField.conclusion]: string;
  [FormField.status]: ProjectStatus;
  [FormField.memberIds]: number[];
  [FormField.newMemberId]: number | undefined;
};

export type FormErrors = {
  [key in FormField]: string;
};

export const helperTexts = {
  [FormField.id]: "",
  [FormField.displayId]: "please provide an ID for your project",
  [FormField.title]: "",
  [FormField.externalId]: "",
  [FormField.description]: "",
  [FormField.assigneeId]: "please select the project owner",
  [FormField.conclusion]: "",
  [FormField.status]: "",
  [FormField.memberIds]: "",
  [FormField.newMemberId]: "",
};
