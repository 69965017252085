import NotificationSettingsService from "src/ui/services/NotificationSettingsService.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Unsubscribe = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get("uid");
  const notificationType = urlParams.get("notificationType");
  const navigate = useNavigate();

  const [response, setResponse] = useState();
  const [error, setError] = useState();

  if (!uid || !notificationType) {
    navigate("/");
  }

  useEffect(() => {
    NotificationSettingsService.unsubscribe(uid, notificationType)
      .then(response => {
        setResponse(response);
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  if (response) {
    return (
      <div>
        <p>Successfully unsubscribed </p>
        <p>{response.unsubscribeMessage} </p>
      </div>
    );
  }

  if (error) {
    if (error.response.status == 404) {
      return (
        <div>
          <p>Unsubscribe failed</p>
          <p>
            No notification settings found this user ({uid}) with notification
            type ({notificationType}).
          </p>
        </div>
      );
    }

    return (
      <div>
        <p>Unsubscribe failed</p>
        <p>Error status code: {error.response.status}</p>
      </div>
    );
  }

  return "Loading...";
};

export default Unsubscribe;
