import { QueryClient } from "@tanstack/react-query";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // global default
      staleTime: 1000 * 30,
      retry: (failureCount: number, error: any) => {
        if (error instanceof SynergySpringMVCError) {
          if (error.response?.status == 401) {
            return false;
          }
        }
        return 3 - failureCount;
      },
    },
  },
});

export default queryClient;
