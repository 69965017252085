import React, { useEffect, useState } from "react";
import log from "loglevel";
import {
  generatePath,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, TextField } from "@chemaxon/design-system";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { useAddApplication } from "src/ui/services/ApplicationService";
import TeamService, { useGetTeams } from "src/ui/services/TeamService";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import FormHeader from "src/ui/components/form/FormHeader";
import FormActions from "src/ui/components/form/FormActions";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";
import { AppRoutePath } from "src/ui/utils/routes";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

enum FormField {
  displayName = "displayName",
}

type FormData = {
  [key in FormField]: string;
};

type FormErrors = {
  [key in FormField]: string;
};

const AddApplicationView = () => {
  const navigate = useNavigate();
  const getTeamsQueryResult = useGetTeams();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [teamName, setTeamName] = useState("");
  const [formData, setFormData] = useState<FormData>({
    [FormField.displayName]: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    [FormField.displayName]: "",
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const mutateAddApplications = useAddApplication();

  const teams = getTeamsQueryResult.data;

  useEffect(() => {
    if (typeof teams !== "undefined" && teamId !== null) {
      // TODO - Refactor this once TeamService uses react-query ?
      const team = TeamService.lookupTeamById(teams, Number(teamId));

      if (team) {
        setTeamName(team.teamName);
      }
    }
  }, [teams, teamId]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (teamId === null || mutateAddApplications.isPending) {
      return;
    }

    mutateAddApplications
      .mutateAsync({
        team: teamId,
        ...formData,
      })
      .then(applicationId => {
        log.debug("Application name added.");
        navigate(
          generatePath(AppRoutePath.ApplicationEdit, {
            appId: String(applicationId),
          }),
        );
      })
      .catch((error: unknown) => {
        if (error instanceof SynergySpringMVCError) {
          const newFormErrors: FormErrors = { ...formErrors };

          error.getFieldErrors().forEach(e => {
            newFormErrors[e.field as FormField] = e.message;
          });

          setFormErrors(newFormErrors);
        } else {
          enqueueSnackbar(`Unable to add application.`, {
            variant: "error",
            persist: true,
            action: onCloseAction(closeSnackbar),
          });
        }
      });
  };

  const handleChange =
    (
      fieldName: FormField,
    ): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    event => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.value,
      };
      setFormData(newFormData);

      // clearing potentially shown error message under the field
      const newFormErrors = {
        ...formErrors,
        [fieldName]: "",
      };
      setFormErrors(newFormErrors);
    };

  const handleCancelButton: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    navigate(-1);
  };

  const isInErrorState = (field: FormField) => {
    return !!(formErrors[field] || "");
  };

  const getShownText = (field: FormField) => {
    return isInErrorState(field)
      ? formErrors[field]
      : "Please provide the application name";
  };

  const getGeneralInputProps = (field: FormField) => {
    return {
      name: field,
      value: formData[field],
      autoComplete: "off",
      id: field,
    };
  };

  const getGeneralProps = (field: FormField) => {
    return {
      className: isInErrorState(field) ? "error" : "",
      helperText: (
        <HelperTextTypography
          text={getShownText(field)}
          isError={isInErrorState(field)}
        />
      ),
      onChange: handleChange(field),
      error: isInErrorState(field),
    };
  };

  const renderTeamLink = () => {
    return (
      <Link to={"/team/edit/" + teamId} className="styled-link">
        {teamName}
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.APPLICATION,
          platformPermission: PlatformPermission.CREATE,
        }}
      >
        <Subheader title={"Add Application"} />
        <ViewContainer id={"addApplicationContainer"}>
          <form id="addApplicationSkeleton" onSubmit={handleSubmit}>
            <FormLayoutVertical>
              <FormHeader
                title={
                  <React.Fragment>
                    Assigned to: {renderTeamLink()}
                  </React.Fragment>
                }
              />

              <TextField
                {...getGeneralProps(FormField.displayName)}
                InputProps={getGeneralInputProps(FormField.displayName)}
                label="Name"
                autoFocus
                required
              />

              <FormActions>
                <Button
                  colorVariant="primary"
                  type="submit"
                  loading={mutateAddApplications.isPending}
                >
                  Create application
                </Button>
                <Button colorVariant="secondary" onClick={handleCancelButton}>
                  Cancel
                </Button>
              </FormActions>
            </FormLayoutVertical>
          </form>
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
};

export default AddApplicationView;
