import {
  Link,
  styled,
  TabProps,
  Tab,
  useMediaQuery,
  Theme,
  ButtonBase,
  Breakpoint,
  MenuList,
  ListSubheader,
  Divider,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Content } from "@chemaxon/design-system/color";
import {
  Dropdown,
  DropdownAnchor,
  DropdownItem,
  useDropdownControls,
  Tabs,
  TabsProps,
} from "@chemaxon/design-system";
import Menu from "@chemaxon/design-system/icons/Menu";

type TabLink<TComponent extends FunctionComponent = typeof Link> =
  TabProps<TComponent>;

export interface NavigationTabProps extends TabLink {
  label: string;
  href: string;
  selected?: boolean;
}

export interface NestedNavigationTabProps extends NavigationTabProps {
  nestedTabs?: NavigationTabProps[];
}

export interface NavigationProps extends Omit<TabsProps, "value"> {
  tabs: NestedNavigationTabProps[];
  activeIndex?: number;
  breakpoint?: Breakpoint;
}

export const NavigationTabs = styled(Tabs)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const MenuButton = styled(ButtonBase)(({ theme }) => ({
  color: Content.default,
  padding: theme.spacing(1),
  margin: theme.spacing(2),
}));

const BigTab = ({
  tab,
  onClick,
  ...props
}: {
  tab: NestedNavigationTabProps;
  onClick: () => void;
}) => {
  const [anchorEl, handleOpen, handleClose] = useDropdownControls();
  const { nestedTabs, ...tabProps } = tab;
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    if (anchorEl && anchorEl.offsetWidth) {
      setDropdownWidth(anchorEl.offsetWidth);
    }
  }, [anchorEl]);

  if (nestedTabs) {
    return (
      <>
        <Tab
          component={Link}
          key={tab.label}
          {...props} // NOTE: Required so that the parent Tabs can pass down styling
          {...tabProps} // NOTE: order matters! this way component can be overridden
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onClick();
            handleOpen(event);

            if (tab.onClick) {
              tab.onClick(event);
            }
          }}
        />

        <Dropdown
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -6,
            horizontal: "center",
          }}
          slotProps={{
            paper: {
              variant: "outlined",
              sx: { minWidth: dropdownWidth },
            },
          }}
        >
          {nestedTabs.map(tab => (
            <DropdownItem key={tab.label} selected={tab.selected}>
              <DropdownAnchor href={tab.href}>{tab.label}</DropdownAnchor>
            </DropdownItem>
          ))}
        </Dropdown>
      </>
    );
  } else {
    return (
      <Tab
        component={Link}
        key={tab.label}
        {...props} // NOTE: Required so that the parent Tabs can pass down styling
        {...tabProps} // NOTE: order matters! this way component can be overridden
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          onClick();

          if (tab.onClick) {
            tab.onClick(event);
          }
        }}
      />
    );
  }
};

const SmallTab = ({ tab }: { tab: NestedNavigationTabProps }) => {
  if (tab.nestedTabs) {
    return (
      <>
        <Divider variant="middle" />
        <MenuList>
          <ListSubheader>{tab.label}</ListSubheader>
          {tab.nestedTabs.map(tab => (
            <DropdownItem key={tab.label} selected={tab.selected}>
              <DropdownAnchor href={tab.href}>{tab.label}</DropdownAnchor>
            </DropdownItem>
          ))}
        </MenuList>
        <Divider variant="middle" />
      </>
    );
  } else {
    return (
      <DropdownItem key={tab.label} selected={tab.selected}>
        <DropdownAnchor href={tab.href}>{tab.label}</DropdownAnchor>
      </DropdownItem>
    );
  }
};

function Navigation({
  tabs,
  activeIndex,
  breakpoint = "md",
  ...props
}: NavigationProps) {
  const [iActiveIndex, setIActiveIndex] = useState(activeIndex || 0);
  const isBig = useMediaQuery<Theme>(theme => theme.breakpoints.up(breakpoint));
  const [anchorEl, handleClick, handleClose] = useDropdownControls();

  useEffect(() => {
    if (activeIndex !== undefined) {
      setIActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  return isBig ? (
    <NavigationTabs value={iActiveIndex} {...props}>
      {tabs.map((tab, i) => (
        <BigTab key={tab.label} tab={tab} onClick={() => setIActiveIndex(i)} />
      ))}
    </NavigationTabs>
  ) : (
    <>
      <MenuButton onClick={handleClick}>
        <Menu />
      </MenuButton>

      <Dropdown
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: -12 }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        slotProps={{
          paper: {
            variant: "outlined",
          },
        }}
      >
        {tabs.map(tab => (
          <SmallTab key={tab.label} tab={tab} />
        ))}
      </Dropdown>
    </>
  );
}

export default Navigation;
