import { queryOptions, useQuery } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import { EnvInfoResponse } from "src/ui/models/EnvInfo";

export const SERVICE_URL = "/resources/env";

class EnvInfoService {
  static getEnv(): Promise<EnvInfoResponse> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "envinfo";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  env: () => [QUERY_KEYS_NAMESPACE, "env"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const getEnvQueryOptions = <TData = EnvInfoResponse>(
  select?: (data: EnvInfoResponse) => TData,
) =>
  queryOptions({
    queryKey: queryKeys.env(),
    queryFn: () => EnvInfoService.getEnv(),
    select,
  });

export const useGetEnvQuery = <TData = EnvInfoResponse>(
  select?: (data: EnvInfoResponse) => TData,
) => useQuery(getEnvQueryOptions(select));

export const useGetBuildInfo = () => useGetEnvQuery(data => data.buildInfo);

export const useGetLoginPath = () => useGetEnvQuery(data => data.loginPath);

export const useGetMaxInactiveInterval = () =>
  useGetEnvQuery(data => data.maxInactiveInterval);

export default EnvInfoService;
