import logMain from "loglevel";
import SynergySpringMVCError from "./SynergySpringMVCError";
import { sessionStatus } from "./sessionStatus";
import queryClient from "src/ui/utils/queryClient";

const log = logMain.getLogger("api");

/*
 * Simple rest-api interface - return a Promise
 * example:
 *   Api.get('/foo')
 *   .then(data => console.log('Success:', data))
 *   .catch(error => console.log('Error: ', error.message));
 * If the promise is fulfilled, it returns the returned json as 'data'
 * If the promise is rejected, it return an Error
 *   If the error response has JSON body, the returned promise will reject with SynergySpringMVCError
 *   containing that json object in 'data'.
 */
class Api {
  constructor(isSessionHandlingEnabled) {
    this.isSessionHandlingEnabled = isSessionHandlingEnabled;
  }

  async init(csrfToken) {
    this.csrfToken = csrfToken;
  }

  get(url) {
    let options = {
      method: "GET",
      url: url,
      headers: this.getXMLHttpRequestHeaders(),
    };

    return this.call(options);
  }
  post(url, data) {
    return this.call({
      method: "POST",
      url: url,
      body: data,
      headers: this.getXMLHttpRequestHeaders(
        true,
        this.isSessionHandlingEnabled,
      ),
    });
  }
  put(url, data) {
    return this.call({
      method: "PUT",
      url: url,
      body: data,
      headers: this.getXMLHttpRequestHeaders(
        true,
        this.isSessionHandlingEnabled,
      ),
    });
  }
  delete(url) {
    return this.call({
      method: "DELETE",
      url: url,
      headers: this.getXMLHttpRequestHeaders(
        false,
        this.isSessionHandlingEnabled,
      ),
    });
  }

  postMultipartData(url, data) {
    const formData = new FormData();
    formData.append("file", data);
    return this.call({
      method: "POST",
      url: url,
      body: formData,
      headers: this.getXMLHttpRequestHeaders(
        true,
        this.isSessionHandlingEnabled,
        true,
      ),
      multipart: true,
    });
  }

  getPdf(url) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/pdf",
    };

    const requestUrl = window.location.origin + url;
    const request = new window.Request(requestUrl, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: headers,
    });

    log.debug("Api request: ", request);
    return fetch(request)
      .then(response => {
        log.debug("Api response: ", response);
        if (this.isSessionHandlingEnabled) {
          sessionStatus.resetTimer();
        }
        return response.ok
          ? response
          : response.then(data =>
              Promise.reject(new SynergySpringMVCError(data, response)),
            );
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // TODO: is this codeblock even reachable? (probably not because 401 is not a network error so in
          // case we get a 401 response we won't end up in this error block)
          // TODO: Do we really need a dialog here?
          //alert('Your session has expired. Please sign in again!');
          window.location.reload();
        }
        log.debug("Api error: ", error);
        throw error;
      });
  }

  getCsrfToken() {
    return this.get("/resources/csrf");
  }

  getXMLHttpRequestHeaders(hasBody, csrf, multipart) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
    };
    if (hasBody && !multipart) {
      headers["Content-Type"] = "application/json";
    }
    if (csrf) {
      if (this.csrfToken.headerName && this.csrfToken.token) {
        headers[this.csrfToken.headerName] = this.csrfToken.token;
      }
    }
    if (multipart) {
      //headers['Content-Type'] = 'multipart/form-data';
    }
    return headers;
  }

  getXMLHttpRequestHeadersPdf(csrf) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/pdf",
    };
    if (csrf) {
      headers[window.csrf.header] = window.csrf.token;
    }
    return headers;
  }

  /*
   * calls the api and returns with a promise
   * - if the api returns with error (starus != 2xx) and a JSON response
   *   the returned promise will reject with SynergySpringMVCError containing that json object in 'data'
   * - config: {
   *      url: string,        //required
   *      method: string,     //optional - default: GET
   *      mode: string,       //optional - default: cors
   *      credentials: string,//optional - default: include
   *      headers: object,    //optional
   *      body: object        //optional - will be converted into JSON
   *   }
   */
  call(config) {
    const url = window.location.origin + config.url;
    const request = new window.Request(url, {
      method: config.method || "GET",
      mode: config.mode || "cors",
      credentials: config.credentials || "include",
      headers: config.headers,
      body: config.body
        ? config.multipart
          ? config.body
          : JSON.stringify(config.body)
        : undefined,
    });

    log.debug("Api request: ", request);
    return fetch(request)
      .then(response => {
        log.debug("Api response: ", response);
        if (this.isSessionHandlingEnabled) {
          sessionStatus.resetTimer();
        }
        const content = isJsonResponse(response)
          ? response.json()
          : response.text();
        return response.ok
          ? content
          : content.then(data =>
              Promise.reject(new SynergySpringMVCError(data, response)),
            );
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // TODO: is this codeblock even reachable? (probably not because 401 is not a network error so in
          // case we get a 401 response we won't end up in this error block)
          // TODO: Do we really need a dialog here?
          //alert('Your session has expired. Please sign in again!');
          queryClient.setQueryData(["auth"], false);
        }
        log.debug("Api error: ", error);
        throw error;
      });
  }
}

function isJsonResponse(response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.includes("application/json");
}

const ApiWithSessionHandling = new Api(true);
const ApiWithoutSessionHandling = new Api(false);

export default ApiWithSessionHandling;
export { ApiWithoutSessionHandling };
