import React from "react";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import { usePermissions } from "src/ui/utils/usePermissions";

type RestrictedProps = {
  children?: React.ReactNode;
  to: {
    platformDomainObjectType: PlatformDomainObjectType;
    platformPermission: PlatformPermission;
  };
};

export const Restricted = ({
  children,
  to: { platformDomainObjectType, platformPermission },
}: RestrictedProps) => {
  const { checkPermission } = usePermissions();

  if (checkPermission(platformDomainObjectType, platformPermission)) {
    return children;
  }

  return null;
};
