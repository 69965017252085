import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultView from "src/ui/views/lead-generating-landing-page/LandingDefault";

const LeadGenLandingPageApp = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<DefaultView />} />
    </Routes>
  </BrowserRouter>
);

export default LeadGenLandingPageApp;
