import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Footer from "./Footer";
import theme from "./theme";
import _ from "lodash";

import "./style.scss";
import ProductDataService from "src/ui/services/ProductDataService";

import withRouter from "src/ui/utils/WithRouter";

class LandingDefault extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      solutions: null,
    };
    ProductDataService.getSolutions().then(solutions => {
      this.setState({ solutions: solutions });
    });
  }

  render() {
    let sections = _.map(
      this.state.solutions,
      function (s, i) {
        return (
          <div key={i}>
            <div className="solution-section">
              <div className="solution-section-left">
                <h3>{s.title}</h3>
                <p>{s.description}</p>
              </div>
            </div>
            <div className="solution-separator"></div>
          </div>
        );
      }.bind(this),
    );

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className="lead-generating-container">
            <div className="header-wrapper">
              <div className="header">
                <div className="header-content">
                  <div className="header-logo"></div>
                  <div className="landing-default">
                    <h2 className="normal-weight">
                      We're dedicated to improving the way drug discovery and
                      research is performed
                    </h2>
                    <h2 className="bold-weight">
                      Explore our collaborative solution for your workflows
                      <br />
                      Step into the cloud
                    </h2>
                  </div>
                </div>
                <div className="header-cloud-image"></div>
              </div>
            </div>
            <div className="solutions">{sections}</div>
            <Footer />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

LandingDefault.propTypes = {
  router: PropTypes.object,
};

export default withRouter(LandingDefault);
