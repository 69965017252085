import { ReactNode } from "react";

/*
 * A container that displays the content only if the data is available
 * otherwise it displays a loading indicator or an error message
 * Usage:
 *
 *   <ContainerWithLoaderAndError data={this.state.data} content={data => {
 *     <p>Hello {data.name}</p>
 *   }/>
 *
 * data should be initialized with 'undefined' - if (data === undefined), a loading indicator will be rendered
 * if an error occurs, data should be set to 'false' - if (data === false), an error message will be rendered
 * once the data has been set, the component renders the oontent via this.props.content()
 */

interface Props<TData> {
  data: TData | false | undefined;
  content: (data: TData) => ReactNode;
  loadingMessage?: string;
  errorMainMessage?: string;
  errorSubMessage?: string;
}

function ContainerWithLoaderAndError<TData>({
  data,
  content,
  loadingMessage = "Loading...",
  errorMainMessage = "Error while loading data.",
  errorSubMessage = "",
}: Props<TData>) {
  if (typeof data === "undefined") {
    return <div data-is-loading="true">{loadingMessage}</div>;
  }

  if (data === false) {
    return (
      <div>
        <h3>{errorMainMessage}</h3>
        <div>{errorSubMessage}</div>
      </div>
    );
  }

  return <div data-is-loading="false">{content(data)}</div>;
}

export default ContainerWithLoaderAndError;
