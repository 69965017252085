const EusaContent = () => {
  return (
    // prettier-ignore
    <div
      className="eusa-container"
      style={{
        height: "70vh",
        overflowY: "scroll",
        padding: "12px 24px",
        paddingBottom: "52px",
        borderBottom: "1px solid #ccc",
      }}
    >
      <h1>End User Subscription Agreement</h1>
      <p>
        This End User Subscription Agreement for Chemaxon SaaS ("<b>EUSA</b>")
        is by and between Chemaxon Kft., located in Váci út 133., Budapest,
        Hungary, H-1138 ("<b>Chemaxon</b>") and its Affiliates and the
        individual or legal entity who is using the applicable services made
        available by Chemaxon ("<b>Subscriber</b>") – altogether “<b>Parties</b>”
        – and governs all use of said services referenced in the order placed
        by Subscriber. Subscriber can order said services by sending to Chemaxon
        either an email message (sales [at] chemaxon [dot] com) or an ordering
        or purchasing document (“<b>Order Form</b>”) properly filled out.
      </p>
      <p>
        BY USING CHEMaxon SAAS AND/OR SUBSCRIPTION SERVICES, SUBSCRIBER
        EXPRESSLY ACCEPTS AND AGREES TO THE TERMS OF THIS EUSA. IF YOU ARE AN
        INDIVIDUAL AGREEING TO THE TERMS OF THIS EUSA ON BEHALF OF AN ENTITY,
        SUCH AS YOUR EMPLOYER, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY
        TO BIND THAT ENTITY AND "SUBSCRIBER" SHALL REFER HEREIN TO SUCH ENTITY.
        IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THE TERMS
        OF THIS EUSA, YOU MUST NOT EXECUTE THE ORDER FORM AND MAY NOT USE
        CHEMaxon SAAS AND/OR SUBSCRIPTION SERVICES (EACH AS DEFINED BELOW).
      </p>
      <p>
        <b>1. Definitions</b>
      </p>
      <p>
        The following capitalized terms shall have the meanings set forth below:
      </p>
      <p>
        1.1. <b>"Account" </b>gives the End User personalized access to settings
        and Chemaxon SaaS by signing up and providing Chemaxon with some
        personal information (typically your name, email address and a
        password). Such Account information will be used to authenticate the End
        User when accessing the Chemaxon SaaS and Subscription Services and
        protect the Account from unauthorized access by others. Account settings
        can be edited or terminated at any time via the identity provider.
      </p>
      <p>
        1.2. “<b>Affiliate</b>” shall mean (i) any corporation or business
        entity of which fifty percent (50%) or more of the securities or other
        ownership interests representing the equity, the voting stock or general
        partnership interest are owned, controlled or held, directly or
        indirectly; or (ii) any corporation or business entity which, directly
        or indirectly, owns, controls or holds fifty percent (50%) (or the
        maximum ownership interest permitted by law) or more of the securities
        or other ownership interests representing the equity, the voting stock
        or, if applicable, the general partnership interest.
      </p>
      <p>
        1.3. <b>"Chemaxon SaaS" </b>means Chemaxon’s proprietary software as a
        service (“<b>SaaS</b>”) solution identified at the order by Subscriber
        and made available by Chemaxon to Subscriber on the e-business Hosting
        Environment for which Chemaxon agrees to provide Subscription Services
        to Subscriber pursuant to the terms of this EUSA, e.g., the “Chemaxon
        Cloud. For avoidance of doubt, no license file shall be provided to
        Subscribers.
      </p>
      <p>
        1.4. “<b>Subscriber Data</b>” means any data and/or information that
        Subscriber provides including, without limitation, any kind of chemical
        and biological data or any html files, text, recordings, graphics,
        images, applets or servlets that Subscriber create, upload/download or
        transfer in/out or through the e-business Hosting Environment.
      </p>
      <p>
        1.5. “<b>e-business Hosting Environment</b>” means the hardware and
        software that Hosting Party makes available for Chemaxon to provide
        Subscription Services to Subscriber and the Hosting Party provided
        Internet access bandwidth, collectively as detailed in <u>Annex 2</u>.
      </p>
      <p>
        1.6. <b>“Effective Date” </b>means the commencement of the Subscription
        Term and of this EUSA as defined in Section 7.1.
      </p>
      <p>
        1.7. “<b>End User</b>” shall mean an individual user who eventually uses
        Chemaxon SaaS including the Subscriber’s officers, directors, employees
        if said Subscriber is a legal entity.
      </p>
      <p>
        1.8. <b>"Feedback"</b> means any comments or other feedback Subscriber
        may provide to Chemaxon concerning the functionality and performance of
        Chemaxon SaaS, including identification of potential errors and
        improvements.
      </p>
      <p>
        1.9. “<b>Hosting Party</b>” shall mean a third party that provides the
        e-business Hosting Environment for Chemaxon SaaS. Such third party is
        listed in <u>Annex 2</u>.
      </p>
      <p>
        1.10. “<b>Internet</b>” means the public worldwide network of
        TCP/IP-based networks.
      </p>
      <p>
        1.11. <b>"Order Form" </b>means an ordering document, which is either an
        appropriate email message or a form manifested in a web page or a part
        thereof or a standalone document accepted by Chemaxon for subscribing to
        Chemaxon SaaS and Subscription Services and referencing this EUSA.
      </p>
      <p>
        1.12.<b> “Representatives” </b>means directors, officers, employees,
        agents, contractors, consultants, advisors and representatives of a
        party or a third party.
      </p>
      <p>
        1.13. <b>"Subscription Expiration Date"</b> means the last day of
        Subscriber’s Subscription Term when all Subscription Services granted
        under this EUSA have expired in accordance with any further conditions
        agreed on the Order Form.
      </p>
      <p>
        1.14. <b>"Subscription Fee"</b> means the fee for Subscription Services
        purchased by the Subscriber.
      </p>
      <p>
        1.15. <b>"Subscription Services"</b> means the hosting services of
        Chemaxon SaaS as well as maintenance and support services including
        software upgrades and patches and bug fixes provided by Chemaxon as set
        forth in <u>Annex 1</u> of this EUSA.
      </p>
      <p>
        1.16. <b>"Subscription Term"</b> means the applicable initial and/or
        renewal term of the Subscription Services as defined in Section 7 and
        considered as the time between the Effective Date and the Subscription
        Expiration Date.
      </p>
      <p>
        1.17. “<b>TCP/IP</b>” means Transmission Control Protocol/Internet
        Protocol.
      </p>
      <p></p>
      <p>
        <b>2. Chemaxon Responsibilities</b>
      </p>
      <p>
        2.1. <b>Chemaxon SaaS. </b>Subject to Subscriber's timely payment of the
        Subscription Fee, compliance with the terms and conditions of this EUSA
        and parameters declared on the Order Form, Chemaxon shall provide to
        Subscriber Chemaxon SaaS, running in the e-business Hosting Environment
        made available by the Hosting Party and the Subscription Services during
        the applicable Subscription Term to use the same for internal or
        commercial purposes , in connection with the deployment of no more than
        the number of Accounts as are set forth in the Order Form. Unless
        renewed, this EUSA will expire on the Subscription Expiration Date. This
        means that Chemaxon will cease providing access to Chemaxon SaaS after
        the end of the applicable Subscription Term. Team Admin is Subscriber’s
        personnel who shall be a direct contact to Chemaxon regarding Chemaxon
        SaaS and Subscription Services. Team Admin invite and manage End Users.
      </p>
      <p>
        2.2. <b>Accounts.</b> Chemaxon shall create a proper work space of
        Chemaxon SaaS defined on Subscriber’s Order Form and invite a Team Admin
        who shall be responsible for all other set up, pursuant to Section 3.1,
        necessary for the End Users to access Chemaxon SaaS. Each End User shall
        generate an Accounts and proper credentials, which provides access to
        Chemaxon SaaS.
      </p>
      <p>
        2.3. <b>Set-up</b>. On or before the Effective Date, Chemaxon shall
        complete all tasks required to, and shall make the Subscription Services
        and Chemaxon SaaS accessible to Subscriber in the e-business Hosting
        Environment.
      </p>
      <p>
        2.4. <b>Subscription Services. </b>Subject to Subscriber's timely
        payment of the Subscription Fee and compliance with the terms and
        conditions of this EUSA, Chemaxon shall provide and perform to
        Subscriber the Subscription Services operate in the e-business Hosting
        Environment during the Subscription Term so as to meet or exceed the
        required levels of quality, speed, availability, capacity, reliability
        or other characteristics detailed in <u>Annex 1</u>. Subscriber shall
        purchase Subscription Services corresponding to the details of the
        subscription as specified in the Order Form. Unless renewed, the
        Subscription Services will expire on the Subscription Expiration Date.
        This means that Chemaxon will not provide the Subscription Services
        after the end of the applicable Subscription Term.
      </p>
      <p></p>
      <p>
        <b>3. Subscriber’s Responsibilities</b>
      </p>
      <p>
        3.1. <b>Accounts.</b> The Subscriber is responsible to have its End
        Users set up their Accounts. The Subscriber is also responsible to
        control and maintain the access rights for Chemaxon SaaS based on the
        number and identity of its End Users pursuant to this EUSA and the Order
        From. Account and the Account information is personal, non-transferable
        and each End User shall keep any related information confidential.
        Multiple and/or parallel use of an access credential to an Account at
        the same time is strictly prohibited.
      </p>
      <p>
        3.2. Subscriber and End Users shall not destroy, disable or circumvent,
        or attempt to destroy, disable or circumvent in any way the Account
        and/or the use and time limitations set by the Account or Chemaxon SaaS.
        Subscriber acknowledges and agrees that any attempt to exceed the use of
        Chemaxon SaaS beyond the limits configured to the Account will
        automatically and immediately terminate the licenses granted under this
        EUSA.
      </p>
      <p>
        3.3. <b>Subscription Services. </b>Subscriber will comply with its
        responsibilities to support the Subscription Services as specified in
        this Section 3 and in <u>Annex 1</u>. Such obligations are to be
        performed at no charge to Chemaxon. Chemaxon’s obligations are
        contingent on Subscriber meeting such support obligations.
      </p>
      <p>
        3.4. <b>Capacity Planning. </b>Subscriber acknowledges it is its
        responsibility to determine whether Chemaxon SaaS, the Subscription
        Services, e-business Hosting Environment, Subscriber<b> </b>Data and
        their combination will meet Subscriber’s capacity, performance, or
        scalability needs. Subscriber is responsible for planning and for
        requesting changes to the e-business Hosting Environment, including any
        additional capacity required supporting anticipated peaks in demand that
        may significantly increase web site hits, transaction volumes, or
        otherwise increase system resource utilization. In such cases, further
        charges may apply.
      </p>
      <p>
        3.5. <b>Content and Digital Certificates. </b>Subscriber is responsible
        for Subscriber Data, including selection, creation, design, usage,
        licensing, maintenance, testing, and support. Subscriber is also
        responsible for any individual’s personal information or any information
        Subscriber considers confidential that is included in the Subscriber
        Data. The Subscription Services or Chemaxon SaaS may allow Subscriber to
        include within the Subscriber Data personal information about others.
        The laws of some jurisdictions may require the consent of individuals
        prior to including their personal information in the Subscriber Data or
        require compliance with laws, rules, and regulations (such as personal
        health information). Subscriber agrees to comply with all applicable
        laws, to obtain all necessary consents, and make all necessary
        disclosures before including personal information in the Subscriber Data
        and using in connection with Chemaxon SaaS and the Subscription
        Services. The Subscriber acknowledges and agrees that Subscriber is
        solely responsible for any personal information that may be contained in
        the Subscriber Data, including any information which Subscriber shares
        with third parties, and that Subscriber is and remains in compliance
        with applicable data privacy and protection laws.
      </p>
      <p>
        3.6. <b>Security considerations.</b> Subscriber acknowledges that
        Subscriber has reviewed the security features e-business Hosting
        Environment and responsibilities of the Host Party and has determined
        that they meet Subscriber’s security needs. Subscriber is solely
        responsible for determining the appropriate procedures and controls
        regarding security of Subscriber Data and for the implementation of any
        such procedures and controls.
      </p>
      <p></p>
      <p>
        <b>4. Subscription Fee and Payment</b>
      </p>
      <p>
        4.1. <b>Subscription Fee. </b>In consideration of the provision of the
        Subscription Services and Chemaxon SaaS hereunder and subject to
        Chemaxon’s compliance with its obligations, Subscriber agrees to pay
        Chemaxon the Subscription Fee, which is due on the Effective Date in
        case of the Initial Subscription Term pursuant to Section 7.1 or on the
        Subscription Expiration Date of the then-current Renewal Subscription
        Term in case of renewal pursuant to Section 7.2.
      </p>
      <p>
        4.2. <b>Payment.</b> Payment of the Subscription Fee made by Subscriber
        as set forth in the applicable Order Form shall arrive to Chemaxon’s
        designated bank account prior to or on its due date pursuant to Section
        4.1 .
      </p>
      <p>
        4.3. <b>Taxes. </b>All payments by Subscriber to Chemaxon hereunder
        shall be made free and clear of and without reduction for all applicable
        sales or use, goods and services, value added, consumption or other
        similar fees or taxes imposed by any government (other than taxes on the
        net income of Chemaxon), which shall be paid by Subscriber. Accordingly,
        if Subscriber is required to withhold any taxes on the amounts payable
        to Chemaxon hereunder, Subscriber shall pay Chemaxon such additional
        amounts as are necessary to ensure receipt by Chemaxon of the full
        amount which Chemaxon would have received but for the deduction on
        account of such withholding. The amounts of any taxes required to be
        paid by Chemaxon will be added to Chemaxon’s invoice, and Subscriber
        shall promptly remit such amounts to Chemaxon, as the collection agent,
        upon invoice.
      </p>
      <p></p>
      <p>
        <b>5. Restricted Activities</b>
      </p>
      <p>
        Subscriber shall not, and shall not encourage any third party to: (a)
        use Chemaxon SaaS for the purpose or in manner except as permitted or
        described in this EUSA, in the Order Form or in the documentation
        provided therewith; (b) modify, adapt, alter, translate, or create
        derivative works of Chemaxon SaaS; (c) reverse-engineer, decompile,
        disassemble, or attempt to derive the code for Chemaxon SaaS, in whole
        or in part, except to the extent that such activities are permitted
        under applicable law; (d) distribute, license, sublicense, lease, rent,
        loan, or otherwise transfer Chemaxon SaaS to any third party; (e)
        remove, alter, or obscure in any way the proprietary rights notices
        (including copyright, patent, and trademark notices and symbols) of
        Chemaxon or its suppliers contained on or within any copies of Chemaxon
        SaaS; (f) use Chemaxon SaaS for the purpose of creating a product or
        service competitive with Chemaxon SaaS; (g) use Chemaxon SaaS with any
        unsupported software or hardware (as described in the applicable
        documentation provided by Chemaxon); (h) use Chemaxon SaaS for any
        time-sharing, outsourcing, service bureau, hosting, application service
        provider or like purposes; (i) disclose the results of any benchmark
        tests on Chemaxon SaaS without Chemaxon's prior written consent; (j) use
        Chemaxon SaaS for any unlawful purpose; or (k) separate use of
        individual components integrated into Chemaxon SaaS by Subscriber for
        any purposes; if this is the intention, Subscriber needs to contact
        Chemaxon at sales [at] chemaxon [dot] com and purchase appropriate
        license(s) for such individual components.
      </p>
      <p></p>
      <p>
        <b>6. Ownership of Chemaxon SaaS</b>
      </p>
      <p>
        Chemaxon and its licensors own and retain all right, title, and
        interest, including all intellectual property rights, in and to Chemaxon
        SaaS, including any improvements, modifications, and enhancements to it.
        Except for the rights expressly granted in this EUSA, Subscriber shall
        acquire no other rights, express or implied, in or to Chemaxon SaaS, and
        all rights not expressly provided to Subscriber hereunder are reserved
        by Chemaxon and its licensors. Chemaxon SaaS provided hereunder are made
        available to Subscriber as a service, not sold. For avoidance of doubt,
        no license file shall be provided to fulfill Chemaxon responsibilities
        pursuant to this EUSA.
      </p>
      <p></p>
      <p>
        <b>7. Term and Termination</b>
      </p>
      <p>
        7.1. <b>Commencement.</b> This EUSA and Subscription Services will be
        effective commencing at 12:01 a.m., CET (GMT+1), on the fifth (5th)
        business day after the date of arrival of payment of the Subscription
        Fee pursuant to Section 4 to Chemaxon’s designated bank account (
        “<b>Effective Date</b>”). Unless otherwise terminated in accordance with
        this section, this EUSA will remain in effect until the Subscription
        Expiration Date and for so long as Subscriber is in compliance with the
        terms and conditions of this EUSA (
        “<b>Initial Subscription Term</b>”).
      </p>
      <p>
        7.2. <b>Renewal.</b> Subject to payment of the Subscription Fee by
        Subscriber pursuant to Section 4 and Subscriber’s in compliance with the
        terms and conditions of this EUSA, this EUSA and the Subscription Term
        will renew for an additional Subscription Term (the
        “<b>Renewal Subscription Term</b>”). The Initial Subscription<b> </b>Term
        and Renewal Subscription Term altogether, where applicable, shall be the
        term of the Subscription Services (“<b>Subscription Term</b>”).
      </p>
      <p>
        7.3. <b>Expiry. </b>If a payment of the Subscription Fee does not arrive
        to Chemaxon’s designated bank account prior to or on its due date
        pursuant to Section 4, this EUSA, Chemaxon SaaS and Subscription
        Services shall expire on the Subscription Expiration Date. Chemaxon
        shall shut down Chemaxon SaaS and Subscription Services pursuant to
        Section 7.8.
      </p>
      <p>
        7.4. <b>Change in Subscription Services.</b> Subscriber
        may terminate or extend to use certain and available portion
        of or tools in Chemaxon SaaS at its sole discretion at the expiry of the
        Subscription Term and/or add new ones at any time in the Subscription
        Term with giving written notification to Chemaxon. Such notification
        must be in writing at least thirty (30) days prior to the end of the
        Subscription Term for this EUSA. The termination of selected portion of
        or tools in Chemaxon SaaS will not affect Subscriber’s obligation to pay
        charges for other Chemaxon SaaS or the Subscription Services. In case of
        the above change, no refund of the Subscription Fee shall be made by
        Chemaxon to Subscriber. Subject to a ninety (90) day prior notice to
        Subscriber, Chemaxon shall have the right to change any portion of or
        tools in Chemaxon SaaS or the Subscription Services at its sole
        discretion at any time in the Subscription Term if such change is in
        line with the Subscription Service purposes or is a substitution of
        service, which serves better performance for Subscriber or termination
        of a product line or a component thereof.
      </p>
      <p>
        7.5. <b>Termination without cause by Subscriber.</b> Subscriber
        may terminate this EUSA, any Order Form incorporating the
        terms of this EUSA and the use of Chemaxon SaaS and Subscription
        Services without cause at any time by simply stopping to use and make
        payment for Chemaxon SaaS and Subscription Services. The date of
        termination pursuant to this Section 7.5 shall be the end of use of
        Chemaxon SaaS by Subscriber if Chemaxon has been notified or the
        Subscription Expiration Date if Chemaxon has not been notified.
      </p>
      <p>
        7.6. <b>Termination without cause by Chemaxon.</b> Chemaxon
        may terminate this EUSA, any Order Form incorporating the terms
        of this EUSA and the provision of Chemaxon SaaS and Subscription
        Services without cause at any time with giving written notification to
        Subscriber. Such notification must b e in writing at least one quarter
        of the then-current Subscription Term prior to the Subscription
        Expiration Date. The date of termination pursuant to this Section 7.6
        shall be the end of the notice period.
      </p>
      <p>
        7.7. <b>Termination for Cause.</b> Either party may terminate this EUSA
        and any Order Form incorporating the terms of this EUSA on an immediate
        effect if the other party materially breaches this EUSA and fails to
        cure such breach within three (3) working days of receiving written
        notice thereof. The date of termination pursuant to this Section 7.7
        shall be the end of the cure period. Chemaxon has the right to suspend
        access right of any End User or eventually terminate this EUSA and any
        Order Form incorporating the terms of this EUSA on an immediate effect
        if such End User will not use Chemaxon SaaS with full compliance of the
        provisions of this EUSA. Subscriber acknowledges and agrees that any
        attempt to exceed the use of Chemaxon SaaS beyond the limits configured
        to the Account shall be considered as material breach of this EUSA.
      </p>
      <p>
        7.8. <b>Effect of Termination.</b> Unless otherwise agreed by the
        Parties, upon the expiration or termination of this EUSA or any Order
        Form, all Chemaxon SaaS and Subscription Services granted herein or
        therein will terminate and Subscriber will discontinue all use or
        provision of the applicable Chemaxon SaaS and Subscription Services,
        respectively. Subscriber shall return to Chemaxon any materials (e.g.
        backups) provided by Chemaxon to Subscriber, if any. Upon the date of
        termination, all undisputed Subscription Fee accrued hereunder through
        the date of termination will become due and payable. No refund of the
        Subscription Fee shall be made by Chemaxon to Subscriber if this EUSA
        (i) expires pursuant to Section 7.3 or is terminated by (ii) Subscriber
        without cause pursuant to Sections 7.5 or (iii) Chemaxon with cause
        pursuant to Section 7.7. Proportional refund of the Subscription Fee
        shall be made by Chemaxon to Subscriber if this EUSA is terminated by
        (a) Chemaxon without cause pursuant to Section 7.6 or (b) Subscriber
        with cause pursuant to Section 7.7 provided only that the amount of the
        proportional refund exceeds 10% of the Subscription Fee. Subscriber Data
        stored on the e-business Hosting Environment, if any, shall be available
        for thirty (30) days after the Subscription Expiration Date or the date
        of termination. During such period, Subscriber may securely save its
        data to its own environment. Upon Subscriber’s request and subject to
        additional fee payable by Subscriber to Chemaxon based on agreement
        between Subscriber and Chemaxon, Chemaxon may provide the service to
        save and deliver such Subscriber Data to Subscriber. After such period,
        Chemaxon shall assume no liability whatsoever for losses of any
        information or data whatsoever including Subscriber Data, which may
        occur due to any expiry or termination of this EUSA, Chemaxon SaaS and
        Subscription Services.
      </p>
      <p>
        7.9. <b>Reinstatement.</b> In case of expiry or termination of Chemaxon
        SaaS and/or Subscription Services by Subscriber pursuant to Sections
        7.3, 7.5 and 7.6, a reinstatement fee shall be paid by Subscriber to
        Chemaxon upon Subscriber’s requests to resume its subscription of
        Chemaxon SaaS and Subscription Services after said expiry, termination
        or discontinuation thereof.
      </p>
      <p>
        7.10. <b>Survival</b>. Sections 1, 5, 6, and 7 through 13 shall survive
        any termination or expiration of this EUSA or any Order Form. Upon
        termination, Chemaxon may also enforce any rights provided by law.
      </p>
      <p></p>
      <p>
        <b>8. Feedback.</b>
      </p>
      <p>
        Subscriber may submit to Chemaxon bug reports, comments, feedback or
        ideas (collectively “<b>Feedback</b>”) about Chemaxon SaaS, including
        without limitation about how to improve Chemaxon SaaS. By submitting any
        Feedback, Subscriber hereby assigns to Chemaxon all right, title, and
        interest in and to the Feedback, if any.
      </p>
      <p></p>
      <p>
        <b>9. Confidentiality</b>
      </p>
      <p>
        9.1. <b>Definition. </b>"Confidential Information" means any information
        disclosed by one party ("Discloser") to the other ("Recipient"),
        directly or indirectly, in writing, orally or by inspection of tangible
        objects, which is designated as "Confidential," "Proprietary" or some
        similar designation, or learned by Recipient under circumstances in
        which such information would reasonably be understood to be
        confidential. Confidential Information may include information disclosed
        in confidence to Discloser by third parties. For the purposes of this
        EUSA, Chemaxon SaaS, and the results of any performance, functional or
        other evaluation of Chemaxon SaaS, shall be deemed as Confidential
        Information of Chemaxon, while Subscriber Data shall be deemed as
        Confidential Information of Subscriber.
      </p>
      <p>
        9.2. <b>Exceptions. </b>The confidentiality obligations in this Section
        9 shall not apply with respect to any of the Discloser's Confidential
        information which Recipient can demonstrate: (a) was in the public
        domain at the time it was disclosed to Recipient or has become in the
        public domain through no act or omission of Recipient; (b) was known to
        Recipient, without restriction, at the time of disclosure as shown by
        the files of Recipient in existence at the time of disclosure; (c) was
        disclosed by Recipient with the prior written approval of Discloser; (d)
        was independently developed by Recipient without any use of Discloser's
        Confidential Information by Representatives of (or contractors hired by)
        Recipient who had no access to or did not rely on Discloser's
        Confidential Information; (e) became known to Recipient, without
        restriction, from a source other than Discloser without breach of this
        EUSA by Recipient and otherwise not in violation of Discloser's rights;
        or (f) is released from the term of this EUSA by mutual agreement of the
        Parties in writing.
      </p>
      <p>
        9.3. <b>Restrictions on Use and Disclosure. </b>Recipient agrees not to
        use Discloser's Confidential Information or disclose, distribute or
        disseminate Discloser's Confidential Information except in furtherance
        of the performance of its obligations or enforcement of its rights
        hereunder or as otherwise expressly agreed by Discloser in writing.
        Recipient agrees to restrict access to such Confidential Information to
        its Representatives who need to know such Confidential Information for
        performing as contemplated hereunder and have agreed in writing to be
        bound by a confidentiality obligation no less protective than that
        contained in this EUSA. Recipient shall exercise the same degree of care
        to prevent unauthorized use or disclosure of Discloser's Confidential
        Information to others as it takes to preserve and safeguard its own
        information of like importance, but in no event less than reasonable
        care.
      </p>
      <p>
        9.4. <b>Compelled Disclosure.</b> If Recipient is compelled by a court
        or other competent authority or applicable law to disclose Confidential
        Information of Discloser, it shall give Discloser prompt written notice
        and shall provide Discloser with reasonable cooperation at Discloser's
        expense so that Discloser may take steps to oppose such disclosure or
        obtain a restraining order. Recipient shall not be in breach of its
        obligations in this Section 9 if it makes any legally compelled
        disclosure provided that Recipient meets the foregoing notice and
        cooperation requirements.
      </p>
      <p>
        9.5. <b>Injunctive Relief.</b> Recipient acknowledges that breach of the
        confidentiality obligations would cause irreparable harm to Discloser,
        the extent of which may be difficult to ascertain. Accordingly,
        Recipient agrees that Discloser is entitled to immediate injunctive
        relief in the event of breach of an obligation of confidentiality by
        Recipient, and that Discloser shall not be required to post a bond or
        show irreparable harm in order to obtain such injunctive relief.
      </p>
      <p>
        9.6. <b>Return of Confidential Information.</b> As between the Parties,
        Confidential Information shall remain the property of the Discloser. At
        any time, upon Discloser’s reasonable request, Recipient shall promptly
        (and in any event within 60 days) return to Discloser or destroy, at the
        election of the Discloser, any Confidential Information of the
        Discloser. In addition, within 30 days after termination of this EUSA,
        Recipient shall (i) promptly return all tangible materials containing
        such Confidential Information to Discloser, (ii) remove all Confidential
        Information (and any copies thereof) from any computer systems of the
        Recipient, its contractors and its distributors, and confirm in writing
        that all materials containing Confidential Information have been
        destroyed or returned to Discloser, as applicable, by Recipient.
        Recipient shall cause its Affiliates, and Representatives to strictly
        comply with the foregoing. In case of Chemaxon, as Recipient, shall be
        requested by Subscriber to save and deliver Subscriber’s data to
        Subscriber pursuant to Section 7.8, additional fee payable by Subscriber
        to Chemaxon may apply. Notwithstanding the foregoing, (x) the Recipient
        may retain one copy of the Discloser’s Confidential Information for
        archival purposes and as reasonably necessary to demonstrate compliance
        with the terms and conditions of this EUSA, including in connection with
        legal proceedings and (y) neither the Recipient nor any of its
        Representatives shall be required to delete or destroy any electronic
        back-up media or other electronic back-up files that have been created
        solely by the automatic or routine archiving and back-up procedures of
        the Recipient or its Representatives, to the extent created and retained
        in a manner consistent with its or their standard archiving and back-up
        procedures.
      </p>
      <p></p>
      <p>
        <b>10. Warranties and Disclaimers</b>
      </p>
      <p>
        10.1.<b> Chemaxon Warranties.</b> Chemaxon warrants that:
      </p>
      <p>
        10.1.1. it has the requisite corporate power and authority to execute,
        deliver and perform its obligations under this EUSA;
      </p>
      <p>
        10.1.2. it will make available Chemaxon SaaS and perform the
        Subscription Services to the best of its abilities using all reasonable
        care and skill in accordance with generally recognized commercial
        practices and standards and in accordance with <u>Annex 1</u> and
        documentation;
      </p>
      <p>
        10.1.3. it shall make available Chemaxon SaaS and perform the
        Subscription Services in accordance with all applicable laws and
        regulations and expected industry standards including but not limited to
        those relating to data protection;
      </p>
      <p>
        10.1.4. Chemaxon SaaS shall be in compliance with the specification and
        in accordance with the functional characteristics described in the user
        guide.
      </p>
      <p>
        10.1.5. if required, it has obtained, and at all times will maintain,
        all necessary licenses and consents from third parties to perform its
        obligations under this EUSA;
      </p>
      <p>
        10.1.6. to the best of its knowledge, Chemaxon SaaS made available to
        Subscriber, pursuant to this EUSA, shall be free from computer viruses.
        Subscriber is advised that it should carry out its own virus checks and
        satisfy itself that any files downloaded from the e-business Hosting
        Environment are free from computer viruses. Under no circumstances shall
        Chemaxon be liable whatsoever for any loss (consequential or otherwise,
        including but not limited to loss of data or loss of profits) resulting
        from or connected with the accidental introduction of a computer virus
        into Subscriber’s environment (other than in breach of the warranty in
        this Section 10.1);
      </p>
      <p>
        10.1.7. it shall notify Subscriber immediately of any breach by the Host
        Party of its agreement with Chemaxon or any material issue arising from
        the e-business Hosting Environment which may interfere with the
        Subscriber’s enjoyment of the Subscription Services or risk damage,
        loss, corruption or modification to any data, programs, materials or
        information used by the Subscriber;
      </p>
      <p>
        10.2. <b>Warranty disclaimer. </b>SUBSCRIBER EXPRESSLY UNDERSTAND AND
        AGREE THAT ALL USE OF CHEMaxon SAAS IS AT SUBSCRIBER'S SOLE RISK AND
        THAT CHEMaxon SAAS AND MAINTENANCE AND SUPPORT SERVICES ARE PROVIDED "AS
        IS" AND "AS AVAILABLE." CHEMaxon, ITS SUBSIDIARIES AND AFFILIATES, AND
        ITS LICENSORS MAKE NO EXPRESS WARRANTIES AND DISCLAIM ALL IMPLIED
        WARRANTIES REGARDING CHEMaxon SAAS OR MAINTENANCE AND SUPPORT SERVICES,
        INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT, TOGETHER WITH ANY AND ALL
        WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE IN TRADE. NO ADVICE
        OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM CHEMaxon OR
        ELSEWHERE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS EUSA.
        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, CHEMaxon, ITS
        SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR
        WARRANT TO SUBSCRIBER THAT: (A) SUBSCRIBER’S USE OF CHEMaxon SAAS OR
        MAINTENANCE AND SUPPORT SERVICES WILL MEET SUBSCRIBER’S REQUIREMENTS, OR
        (B) SUBSCRIBER’S USE OF CHEMaxon SAAS OR MAINTENANCE AND SUPPORT
        SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR.
        NOTWITHSTANDING THE FOREGOING, NOTHING HEREIN SHALL EXCLUDE OR LIMIT
        CHEMaxon'S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY
        EXCLUDED OR LIMITED BY APPLICABLE LAW. SUBSCRIBER UNDERSTANDS AND
        ACKNOWLEDGES THAT CHEMaxon SAAS IS NOT DESIGNED, INTENDED OR WARRANTED
        FOR USE IN HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE CONTROLS,
        INCLUDING WITHOUT LIMITATION, OPERATION OF NUCLEAR FACILITIES, AIRCRAFT
        NAVIGATION OR COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, AND LIFE
        SUPPORT OR WEAPONS SYSTEMS.
      </p>
      <p>
        10.3. <b>Exclusivity of Warranties. </b>The warranties in Section 10.1
        are the exclusive warranties from Chemaxon. They replace all other
        warranties, including, without limitation, the implied warranties of
        merchantability, and fitness for a particular purpose.
      </p>
      <p>
        10.4.<b> Security. </b>Security-related services by the Host Party
        including process security management, physical security, and network
        security are specified in documents made available by the Host Party.
        Subscriber acknowledges that Chemaxon does not control and the transfer
        of data over telecommunication facilities, including the Internet.
        Chemaxon does not warrant that it will be able to prevent third party
        disruptions of the e-business Hosting Environment or in connection with
        Subscriber<b> </b>Data, provided that it has taken all reasonable
        commercial and technical measures to avoid this eventuality. Subscriber
        agrees and acknowledges that Chemaxon shall assume no liability
        whatsoever for the security-related services by the Host Party and/or
        telecommunication facilities described in this Section 10.4.
      </p>
      <p>
        10.5.<b> Other Disclaimers. </b>Save as set out in the Service Levels
        pursuant to <u>Annex 1</u>, Chemaxon does not warrant uninterrupted or
        error-free operation of any Subscription Service or that Chemaxon will
        correct all defects. Chemaxon does not make any representation or
        warranty with respect to Subscriber’s responsibilities set forth in
        Section 3.6. Chemaxon provides non-Chemaxon products, and
        non-Subscription Services, if any, without warranties of any kind.
        However, non-Chemaxon manufacturers, suppliers, or publishers may
        provide their own warranties to Subscriber.
      </p>
      <p>
        10.6.<b> Subscriber’s Warranties.</b> Subscriber
        warrants that (a) it has the requisite corporate power and
        authority to execute, deliver and perform its obligations under this
        EUSA; (b) Subscriber has no contractual or other obligation that
        restricts or prohibits Subscriber’s execution or performance of this
        EUSA; (c) Subscriber’s use of Chemaxon SaaS and/or Subscription Services
        and Subscriber Data will comply with unlawful use of the Internet
        service as follows: Subscriber warrants and undertakes that neither
        Subscriber nor any person authorized by Subscriber will knowingly
        publish or transmit over the Internet any material that is obscene,
        threatening, defamatory or likely to cause offence or which in any way
        infringes the intellectual property rights of another party. Subscriber
        hereby agree to indemnify and hold Chemaxon harmless from any and all
        demands losses claims proceedings damages costs and expenses including
        legal fees arising out of any claim against us in relation to such
        materials. Subscriber shall accept responsibilities for all items
        published or transmitted from Subscriber’s site. The Hosting Party will
        only act as a distributor of Subscriber’s traffic and will not examine
        it in any way except for the purpose of routing it over the Internet.
        Chemaxon reserves the right to investigate potential violations of the
        above warranties in this Section 10.6. If Chemaxon reasonably determines
        that a material breach of any such warranty has occurred during the term
        of this EUSA, Chemaxon reserves the right without notice to Subscriber
        to reject remove or – using reasonable efforts to promptly notify
        Subscriber by any reasonably practical means under the circumstances,
        such as, without limitation, by telephone or e-mail - require Subscriber
        to remove any material or Subscriber Data, which in our reasonable
        opinion contravenes the above provisions of this Section 10.6.
      </p>
      <p></p>
      <p>
        <b>11. Indemnification</b>
      </p>
      <p>
        11.1.<b> By Chemaxon.</b> Chemaxon shall defend at its own expense any
        legal action brought against Subscriber to the extent that it is based
        on a claim or allegation that Chemaxon SaaS infringes a patent or
        copyright of a third party, and Chemaxon will pay any costs and damages
        awarded against Subscriber in any such action, or agreed to under a
        settlement signed by Chemaxon, that are attributable to any such claim
        but shall not be responsible for any compromise made or expense incurred
        without Chemaxon’s consent. Such defense and payments are subject to the
        conditions that (a) Subscriber gives Chemaxon prompt written notice of
        such claim, (b) tenders to Chemaxon sole control of the defense and
        settlement of the claim, and (c) reasonably cooperates with Chemaxon
        when requested in connection with the defense and settlement of the
        claim. Chemaxon will have no liability to so defend and pay for any
        infringement claim to the extent it (i) is based on modification of
        Chemaxon SaaS other than by Chemaxon, with or without authorization;
        (ii) results from failure of Subscriber to use an updated version of
        Chemaxon SaaS; or (iii) is based on the combination or use of Chemaxon
        SaaS with any product, data, software (including, without limitation, an
        open source software), program or apparatus, device not provided by
        Chemaxon in the e-business Hosting Environment if such infringement
        would not have arisen but for such use or combination; or (iv) results
        from use of Chemaxon SaaS by Subscriber after its subscription and/or
        this EUSA was terminated; or (v) is based on Subscriber Data or
        generally Subscriber’s failure to comply with its obligations under this
        EUSA.
      </p>
      <p>
        11.2.<b> Limitation of IP Damages.</b> Should any Chemaxon SaaS, or the
        operation thereof, become or in Chemaxon's opinion be likely to become,
        the subject of such claim described in Section 11.1, Chemaxon may, at
        Chemaxon's option and expense, procure for Subscriber the right to
        continue using Chemaxon SaaS, replace or modify Chemaxon SaaS so that it
        becomes non-infringing, or terminate its subscription granted hereunder
        for such Chemaxon Saas. THIS SECTION 11.2 LIABILITY, AND SUBSCRIBER'S
        SOLE AND EXCLUSIVE REMEDY, WITH RESPECT TO INFRINGEMENT OR
        MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF ANY KIND.
      </p>
      <p>
        11.3.<b> By Subscriber. </b>Subscriber agrees to hold harmless and
        indemnify Chemaxon and its subsidiaries, Affiliates, Representatives,
        officers, agents, employees, advertisers, licensors, suppliers or
        partners from and against any third party claim arising from or in any
        way related to Subscriber’s breach of this EUSA, use of Chemaxon SaaS
        and/or the Subscription Services, or violation of applicable laws, rules
        or regulations in connection with Chemaxon SaaS and/or the Subscription
        Services, including any liability or expense arising from all claims,
        losses, damages (actual and consequential), suits, judgments, litigation
        costs and attorneys' fees, of every kind and nature. In such a case,
        Chemaxon will provide Subscriber with written notice of such claim, suit
        or action.
      </p>
      <p></p>
      <p>
        <b>12. Limitation of Liability.</b>
      </p>
      <p>
        12.1. <b>Exclusion of Damages. </b>SUBSCRIBER EXPRESSLY UNDERSTANDS AND
        AGREES THAT CHEMaxon, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS
        SHALL NOT BE LIABLE TO SUBSCRIBER FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES INCURRED BY SUBSCRIBER,
        HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, BUT NOT
        LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR
        INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF
        DATA SUFFERED EXCEPT AS PROVIDED HEREIN, COST OF PROCUREMENT OF
        SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS. THE FOREGOING
        LIMITATIONS ON CHEMaxon'S LIABILITY SHALL APPLY WHETHER OR NOT CHEMaxon
        HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY
        SUCH LOSSES ARISING. NOTWITHSTANDING THE FOREGOING, NOTHING HEREIN SHALL
        EXCLUDE OR LIMIT CHEMaxon'S LIABILITY FOR LOSSES WHICH MAY NOT BE
        LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.
      </p>
      <p>
        12.2. <b>Liability Cap.</b> EXCEPT WITH RESPECT TO EITHER PARTY’S
        OBLIGATIONS OF INDEMNIFICATION, THE TOTAL LIABILITY OF CHEMaxon ARISING
        OUT OF OR RELATED TO THIS EUSA WILL NOT EXCEED THE GREATER OF USD $100
        OR THE TOTAL AMOUNTS PAID BY SUBSCRIBER FOR THE RELEVANT CHEMaxon SAAS
        UNDER THE APPLICABLE ORDER FORM, IN THE TWELVE (12) MONTH PERIOD
        IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE LIABILITY.
      </p>
      <p></p>
      <p>
        <b>13. Miscellaneous</b>
      </p>
      <p>
        13.1. <b>No Sale or Lease of Goods and/or Real Property.</b> As between
        Subscriber and Chemaxon, Chemaxon retains all right, title and interest
        in Chemaxon SaaS. This EUSA is a subscription agreement. No any goods
        and/or real property are sold or leased by Chemaxon under this EUSA.
      </p>
      <p>
        13.2. <b>Headings.</b> The headings of the various sections of this EUSA
        have been inserted for convenience only and shall not affect the
        interpretation of this EUSA.
      </p>
      <p>
        13.3. <b>Conflict.</b> If a term or condition in an Attachment conflicts
        with a term or condition in this EUSA, the provisions of the Attachment
        shall prevail unless specifically stated to the contrary.
      </p>
      <p>
        13.4. <b>Survival.</b> Any of these terms and conditions which by their
        nature extend beyond the EUSA termination or expiration remain in effect
        until fulfilled.
      </p>
      <p>
        13.5. <b>Governing Law.</b> This EUSA will be governed by the laws of
        Hungary without reference to conflict of law principles. Each party
        agrees to submit to the exclusive jurisdiction of the courts located
        within the county of Budapest, Hungary to resolve any legal matter
        arising from this EUSA.
      </p>
      <p>
        13.6. <b>Waiver of Jury Trial. </b>The Parties waive any right to a jury
        trial in any proceeding arising out of or related to this EUSA.
      </p>
      <p>
        13.7. <b>Severability. </b>If any provision of this EUSA shall be held
        by a court of competent jurisdiction to be invalid, illegal, or
        unenforceable, the validity, legality, and enforceability of the
        remaining provisions of this EUSA shall in no way be affected or
        impaired thereby, so long as the remaining provisions of this EUSA still
        express the original intent of the Parties. If the original intent of
        the Parties cannot be preserved, this EUSA shall either be renegotiated
        or terminated.
      </p>
      <p>
        13.8. <b>Publicity and Trademarks. </b>Neither Party grants the other
        the right to use its or any of its affiliates’ trademarks, trade names,
        or other designations in any promotion, publication, or web site without
        prior written consent. Parties agree that they may, after their prior
        written approval, issue a jointly agreed joint press release relating to
        this EUSA. Except as may be required by law or as may be required by
        Chemaxon to make Chemaxon SaaS available to Subscriber or perform the
        Subscription Services, neither Party may disclose to any third party the
        terms and conditions of this EUSA, without prior written consent.
      </p>
      <p>
        13.9. <b>No Third-Party Beneficiaries. </b>Except as expressly provided
        in Section 11, this EUSA does not create any intended third party
        beneficiary rights.
      </p>
      <p>
        13.10.<b> Personnel. </b>Each Party is responsible for the supervision,
        direction, and control of its respective personnel and Representatives.
        Chemaxon reserves the right to determine or change at any time the
        assignment of its personnel and Representatives. Chemaxon may
        subcontract portions of the Subscription Services to suitably qualified,
        professional subcontractors and their Affiliates selected by Chemaxon.
      </p>
      <p>
        13.11.<b> No Agency.</b> This EUSA does not create an agency, joint
        venture, or partnership between the Parties.
      </p>
      <p>
        13.12. <b>Assignment.</b> Except as provided herein, neither Party may
        assign any of its rights or obligations under this EUSA, whether by
        operation of law or otherwise, without the prior written consent of the
        other party (not to be unreasonably withheld). Notwithstanding the
        foregoing, Chemaxon may assign the entirety of its rights and
        obligations under this EUSA, without consent of the Subscriber, to its
        Affiliate or in connection with a merger, acquisition, corporate
        reorganization, or sale of all or substantially all of its assets.
      </p>
      <p>
        13.13. <b>Disclaimer.</b> The application of the UN Convention of
        International Sale of Goods to this EUSA is disclaimed in its entirety.
      </p>
      <p>
        13.14.<b> No Resale.</b> Subscriber shall not resell Chemaxon SaaS
        and/or Subscription Services in whole or in part. This does not prevent
        Subscriber from making their Subscriber Data and/or Chemaxon SaaS and/or
        Subscription Services available to Subscriber’s End Users.
      </p>
      <p>
        13.15. <b>Risk of Loss.</b> Risk of loss for all Chemaxon SaaS shall at
        all times remain with Chemaxon. Risk of loss for Subscriber Data shall
        at all times remain with Subscriber.
      </p>
      <p>
        13.16. <b>Force Majeure.</b> Except for payment obligations hereunder,
        neither Party is responsible to fulfill its obligations to the extent
        due to causes beyond its control.
      </p>
      <p>
        13.17. <b>Entire Agreement.</b> Together with any Order Forms, this is
        the entire agreement between the Parties relating to the subject matter
        hereof. This EUSA (including applicable Order Forms) shall control over
        any additional or different terms of any correspondence, order,
        confirmation, invoice or similar document, even if accepted in writing
        by both Parties, and waivers and amendments of any provision of this
        EUSA shall be effective only if made by non-preprinted agreements
        indicating specifically what sections of this EUSA are affected, signed
        by both Parties and clearly understood by both Parties to be an
        amendment or waiver.
      </p>
      <p>
        13.18. <b>Waiver.</b> The failure of either Party to enforce its rights
        under this EUSA at any time for any period shall not be construed as a
        waiver of such rights. If any provision of this EUSA is held invalid or
        unenforceable, the remainder of this EUSA will continue in full force
        and effect and the invalid or unenforceable provision shall be reformed
        to the extent necessary to make it valid and enforceable.
      </p>
      <p>
        13.19. <b>Freedom of Action.</b> Each Party is free to enter into
        similar agreements with others.
      </p>
      <p>
        13.20. <b>Limitation of Rights.</b> Each of the Parties grants only the
        rights expressly specified herein. No other rights (including rights
        under patents) are granted, either directly, by implication, estoppel,
        or otherwise.
      </p>
      <p>
        13.21. <b>Data Protection.</b> Subject to and where permitted by its
        data protection obligations, Subscriber agrees to allow Chemaxon to
        store and use Subscriber’s contact information, including names, phone
        numbers, and e-mail addresses, anywhere they do business solely to the
        extent necessary for it to perform its obligations under this EUSA. Such
        information will be processed and used in accordance with all data
        protection laws and Subscriber’s instructions.
      </p>
      <p>
        13.22. <b>Notices.</b> Any notices required or permitted hereunder will
        be effective upon receipt and will be personally delivered; mailed via
        the postal service; sent by reliable overnight courier; or transmitted
        by confirmed facsimile. Except where otherwise specified, all notices
        will be in writing and addressed to the applicable Party’s designated
        Representative at the address specified in this EUSA. Except as to
        notices permitted or required under Sections 7 (Term and Termination) or
        11 (Indemnification), the Parties agree that electronic mail messages
        sent between them using security procedures sufficient to reasonably
        authenticate them will be deemed writings.
      </p>
      <p>
        13.23. <b>New Agreement versions.</b> When a new Subscription Term
        begins, the most current version of this EUSA made available by Chemaxon
        shall be applicable to Subscriber's use of Chemaxon SaaS.
      </p>
      <h2
        style={{ textAlign: "left" }}
        id="EndUserSubscriptionAgreement-EUSA-Annex1.Hosting,SupportandMaintenanceServicesforChemaxonSaaS"
      >
        Annex 1. Hosting, Support and Maintenance Services for Chemaxon SaaS
      </h2>
      <h3 id="EndUserSubscriptionAgreement-EUSA-ServiceDescriptions">
        Service Descriptions
      </h3>
      <p>
        The following Subscription Services shall be provided for Chemaxon SaaS:
      </p>
      <p>
        <b>1. Hosting Services</b>. Chemaxon provides hosting services to its
        Subscribers in accordance with this EUSA which enables access to
        Chemaxon SaaS with commercially reasonable levels of security.
      </p>
      <p>
        <b>2. Maintenance and Support Services</b>. Chemaxon provides
        maintenance and support services for Chemaxon SaaS to its Subscribers
        including: (i) technical and operational assistance to Subscriber’s
        designated personnel via communication channel(s) as defined
        hereinafter; and (ii) application of upgrades, patches, error
        corrections and bug fixes during normal scheduled maintenance periods;
        (iii) updates to the application documentation.
      </p>
      <p>
        <b>3. Services Exclusions</b>. The following services are not included
        in Subscription or Support Services yet may be obtained from Chemaxon on
        an additional fee basis and subject to Subscriber’s execution of an
        Order Form and Chemaxon’s acceptance and confirmation for the same: (i)
        professional services and content development, including new and/or
        missing functionality, customized features, strategy and design
        services, systems architecture, configuration and integration; (ii)
        training on Chemaxon SaaS or its separate modules; (iii) out-of-hours
        support: support provided by Chemaxon to Subscriber at times excluding
        Chemaxon Support Hours pursuant to Section 8 of this Annex 1; (iv)
        support provided by Chemaxon at Subscriber’s premises or any other
        premises requiring travel and living expenses; (v) any professional
        services required by Subscriber in connection with implementation of any
        upgrade to Chemaxon SaaS; and (vi) any professional services in respect
        of incorrect use of the current release or operator error. Arrangement
        for the above services may be initiated by Subscriber using an Order
        Form to be sent to sales [at] chemaxon [dot] com.
      </p>
      <p>
        <b>4. Data Management</b>. Chemaxon shall manage all software and
        algorithms in accordance with Chemaxon global, standard information
        security policies and applicable standard operating procedures (“SOPs”)
        for the following:
      </p>
      <ul>
        <li>Security (physical, network and application)</li>
        <li>
          <span className="confluence-link">Privacy Policy</span>
        </li>
      </ul>
      <h3 id="EndUserSubscriptionAgreement-EUSA-ServiceLevels">
        Service Levels
      </h3>
      <p>
        As part of the hosting Chemaxon SaaS, Chemaxon shall meet or exceed the
        following Service Levels:
      </p>
      <div className="table-wrap">
        <table className="confluenceTable">
          <tbody>
            <tr>
              <td className="confluenceTd">
                <p>
                  <b></b>
                </p>
              </td>
              <td className="confluenceTd">
                <p>
                  <b>Standard Hosting</b>
                </p>
              </td>
            </tr>
            <tr>
              <td className="confluenceTd">
                <p>Availability (percent)</p>
              </td>
              <td className="confluenceTd">
                <p>
                  <b>&gt; 95%</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ol>
        <li>
          <b>Availability</b>. Availability is calculated as actual uptime in
          days when Chemaxon SaaS are available, divided by scheduled uptime in
          days and expressed as a percentage. Scheduled uptime is calculated as
          calendar days excluding days dedicated for scheduled maintenance and
          support.
        </li>
        <li>
          <b>Scheduled Maintenance</b>. Regularly scheduled maintenance
          including upgrades, updates, patches, enhancements and new releases of
          Chemaxon SaaS will be excluded when calculating Availability and will
          be conducted from 8:00am-10:00am Central European Time (CET = GMT+1)
          Wednesdays, except as otherwise notified by Chemaxon.
        </li>
        <li>
          <b>Support Services</b>. Chemaxon will respond to defects, errors,
          deficiency or outages (“<b>Problem</b>”) in accordance with the levels
          of severity, each as reasonably determined by Chemaxon, and Support
          Package agreed between Subscriber and Chemaxon as described
          hereinafter.
        </li>
        <li>
          <b>Subscriber Responsibilities. </b>Subscriber is advised to utilize
          its own permanent help desk for the purpose of acting as sole liaison
          between Subscriber and Chemaxon. Subscriber shall report any Problem
          and provide Chemaxon with the necessary data required to reproduce
          such Problem, or to discover the cause or a cure for the reported
          Problem.
        </li>
        <li>
          <b>Additional information.</b> If the description of the Problem is
          insufficient, Chemaxon may need to gather more information from
          Subscriber to conduct additional testing. In these cases, Chemaxon may
          ask Subscriber to (i) list the exact steps that cause the Problem,
          (ii) collect and send additional information, such as log files or
          message headers. Subscriber may also route the Problem to individual
          specialists for further investigation. Resolution times vary,
          depending on the complexity of the Problem and the availability of
          information described above. Chemaxon shall provide its Support
          Services as described herein provided that Subscriber ensures full
          cooperation with Chemaxon on both technical and personnel level and
          shares all information that Chemaxon deems necessary to resolve the
          Problem.
        </li>
        <li>
          <b>Exclusions</b>. In no event will Chemaxon be responsible for
          Problems occurred for any of the following reasons: (i) Subscriber’s
          breach of this EUSA, (ii) causes resulting from, if applicable,
          Subscriber’s, Hosting Party’s or any third party’s acts, errors or
          omissions or any systems, hardware or software not provided by, or
          identified by Chemaxon or Chemaxon’s service provider as being
          compatible with Chemaxon SaaS (including Subscriber telecommunications
          carrier or internet service provider). In addition, the Service Level
          commitments and response times are not applicable to Subscriber’s use
          of Chemaxon SaaS in a non-production or test (demo / trial /
          evaluation) environment, or beta or pilot use of the same by early
          adopters.
        </li>
        <li>
          <b>Communication channels. </b>Subscriber shall report issues
          throughout dedicated support channels, currently via email
          (<a
            className="external-link"
            href="mailto:support@chemaxon.com"
            rel="nofollow"
          >
            support [at] chemaxon [dot] com
          </a>)
          and direct chat options.
        </li>
        <li>
          <b>Chemaxon Support Hours.</b> As Chemaxon is a Hungarian corporation,
          it shall provide its Support Services within working hours in Hungary
          between 9 a.m. and 5 p.m. Monday to Friday, Central European Time
          excluding the national, bank and bridge holidays related to Hungary as
          listed at{" "}
          <a
            className="external-link"
            href="https://www.officeholidays.com/"
            rel="nofollow"
          >
            https://www.officeholidays.com/
          </a>{" "}
          ("<b>Chemaxon Support Hours</b>").
        </li>
        <li>
          <b>Acknowledgement of Support Request.</b> Upon receipt of a support
          request by Subscribers to any of the above channels Chemaxon will
          prioritize such request according to the Severity Levels identified
          below and Chemaxon employ commercially reasonable efforts wherever
          possible to issue acknowledgement for the 90% of the queries within
          Chemaxon Support Hours by the end of the next business day after
          ticket is logged.{" "}
        </li>
        <li>
          <p className="auto-cursor-target">
            <b>Determining Severity Levels.</b> After acknowledgement is issued,
            Chemaxon will prioritize such request according to the Severity
            Levels identified in the table below and defines support case
            priorities:
            <br />
            <br />
          </p>
          <div className="table-wrap">
            <table className="confluenceTable">
              <tbody>
                <tr>
                  <td className="confluenceTd">
                    <p>
                      <b>
                        <u>Severity Levels</u>
                      </b>
                    </p>
                  </td>
                  <td className="confluenceTd">
                    <p>
                      <b>Description and impact of the Problem</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="confluenceTd">
                    <p>Critical Impact (Severity 1)</p>
                  </td>
                  <td className="confluenceTd">
                    <p>
                      A Problem that causes Chemaxon SaaS to crash or critical
                      functions thereof be unavailable for use and which has no
                      acceptable work-around available that is easy to implement
                      (&lt; 4 hours). Subscriber’s business is at risk
                      (significant FTE cost, potential data integrity issue,
                      etc.).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="confluenceTd">
                    <p>High Impact (Severity 2)</p>
                  </td>
                  <td className="confluenceTd">
                    <p>
                      A Problem that affects multiple users of Chemaxon SaaS and
                      prevents effective use of a feature or features of
                      Chemaxon SaaS, but which does not cause Chemaxon SaaS to
                      be unavailable for use in whole. Important functions of
                      Chemaxon SaaS are unavailable, impaired or degraded.
                      Subscriber’s business is significantly impacted (danger of
                      significant FTE cost, productivity decrease, etc.). A
                      work-around to mitigate critical business impact is
                      available and easy to implement.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="confluenceTd">
                    <p>Low Impact (Severity 3)</p>
                  </td>
                  <td className="confluenceTd">
                    <p>
                      All other Problems that (i) affects productivity or the
                      use and leads erroneous behaviour of Chemaxon SaaS and
                      (ii) does not materially affect Subscriber’s ability to
                      use Chemaxon SaaS (e.g. user inconveniences). Non-critical
                      functions of Chemaxon SaaS are behaving abnormally, or
                      Subscriber has a time-sensitive development question (this
                      may be included in Additional support services).
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <b>Possible solutions.</b> Subject to Severity Levels, CHEMaxon will
          use best endeavors to offer Subscriber a solution. Depending on the
          Problem, the following solutions are possible without limitation:
          <ol>
            <li>CHEMaxon answers Subscriber’s question.</li>
            <li>
              CHEMaxon fixes the Problem or provides a work-around, which may be
              a tentative, short-term and/or temporary solution for the Problem.
            </li>
            <li>
              CHEMaxon resolves the Problem, which considered as a longer-term
              or the permanent resolution for the Problem.
            </li>
            <li>
              CHEMaxon confirms the Problem reported as a missing feature.
              Subscriber may submit it as a customized feature pursuant to{" "}
              <u>Section </u>
              <u>7</u>
              <u> </u>of this Annex 1.
            </li>
            <li>
              CHEMaxon determines that the Problem reported is not supported.
            </li>
            <li>
              CHEMaxon may not reproduce the Problem, but provide
              recommendations for further investigation.
            </li>
            <li>
              CHEMaxon asks a third-party product team to help, and put
              Subscriber in contact with them for further assistance.
            </li>
            <li>CHEMaxon may not Resolve the Problem reported.</li>
          </ol>
        </li>
        <li>
          <b>Resolution. </b>Given the nature of enterprise software solutions,
          it is not possible to guarantee that Problems can be resolved within a
          given timescale. In some cases, it can take time to examine all
          possible causes of the issue and identify possible resolution paths.
          Chemaxon shall work closely with Subscriber to get the Problem fixed
          as quickly as technically possible – this might involve a short-term
          work-around whilst Chemaxon works on troubleshooting, analysis and a
          longer-term resolution. In the case of complex bug fixes, Chemaxon and
          Subscriber will agree a reasonable timescale for correction of the
          Problem.
        </li>
        <li>
          <b>Support Case Handling.</b> All cases are tracked in a centralized
          system.
        </li>
      </ol>
      <h2
        style={{ textAlign: "left" }}
        id="EndUserSubscriptionAgreement-EUSA-Annex2.TheHostingPartyforChemaxonSaaSandSubscriptionServices"
      >
        Annex 2. The Hosting Party for Chemaxon SaaS and Subscription Services
      </h2>
      <h3 id="EndUserSubscriptionAgreement-EUSA-HostingParty">Hosting Party</h3>
      <p>
        The following third party / parties provide(s) the e-business Hosting
        Environment for Chemaxon SaaS:
      </p>
      <ul>
        <li>Amazon</li>
        <li>
          <a
            className="external-link"
            href="https://aws.amazon.com/legal/"
            rel="nofollow"
          >
            https://aws.amazon.com/legal/
          </a>
        </li>
      </ul>
      <h3 id="EndUserSubscriptionAgreement-EUSA-DocumentsoftheHostingParty">
        Documents of the Hosting Party
      </h3>
      <ul>
        <li>
          <a
            className="external-link"
            href="https://aws.amazon.com/legal/"
            rel="nofollow"
          >
            https://aws.amazon.com/legal/
          </a>
        </li>
      </ul>
      <h2 id="EndUserSubscriptionAgreement-EUSA-Annex3.SpecialconditionsforChemaxonSaaSDemo">
        Annex 3. Special conditions for Chemaxon SaaS Demo
      </h2>
      <p>
        The special terms in this Annex 3 are complementary to other sections of
        this EUSA. It covers and governs terms and conditions that differ or
        alter from or amend the same set forth in this EUSA. The provisions of
        this Annex 3 should be interpreted together with other sections of this
        EUSA to understand complete terms and conditions regarding providing
        CHEMaxon SaaS for your special purposes that is your demo of CHEMaxon
        SaaS. Consequently, provisions of other sections of this EUSA also
        apply. Therefore, it is bly recommended to check and read all sections.
        If there is any inconsistency or conflict between the provisions of this
        Annex 3 and other sections of this EUSA, terms and conditions of this
        Annex 3 shall govern and control regarding your demo of CHEMaxon SaaS.
      </p>
      <h3 id="EndUserSubscriptionAgreement-EUSA-WhatisChemaxonSaaSDemo?">
        What is Chemaxon SaaS Demo?
      </h3>
      <p>
        Welcome to the Chemaxon SaaS Demo (“<b>CSD</b>”) that is provided by{" "}
        <a
          className="external-link"
          href="https://www.chemaxon.com/"
          rel="nofollow"
        >
          Chemaxon
        </a> located at Váci út 133., Budapest, Hungary, H-1138 (“<b>Chemaxon</b>”).
        CSD is a publicly available web resource developed and made available by
        Chemaxon with the purpose of facilitating a nice and simple environment
        to evaluate and try out Chemaxon's proprietary software product,
        Chemaxon Cloud (the “<b>Purpose</b>”). Chemaxon Cloud is a commercially
        available, web-based software to access, display, search and analyze
        scientific data. Number of Chemaxon and vendor tools are reachable
        throughout the software and more will come, combining our b chemistry
        knowledge with all the technical benefits of a browser-based
        environment. Currently, Chemaxon Cloud encompasses various tools that of
        use can be demonstrated here.
      </p>
      <h3 id="EndUserSubscriptionAgreement-EUSA-ChemaxonSaaSDemoTermsofUse">
        Chemaxon SaaS Demo Terms of Use
      </h3>
      <p>
        BY USING CSD, YOU AGREE TO BE BOUND BY THE FOLLOWING TERMS OF USE (
        THE “<b>TERMS OF USE</b>”) AND ALL APPLICABLE LAWS AND REGULATIONS. IF YOU DO
        NOT AGREE TO THESE TERMS OF USE, DO NOT APPLY FOR USING CSD.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Preamble">Preamble</h4>
      <p>
        CHEMaxon is the owner of rights, copyrights, title and interest (
        “<b>RIGHTS</b>”) in Chemaxon Cloud, CSD and the content of CSD and
        related documentation, if any.
      </p>
      <p>
        You desire to evaluate Chemaxon Cloud for the purpose of determining
        whether to enter into a long term license agreement for Chemaxon Cloud;
        and
      </p>
      <p>
        CSD runs on a hosted environment as known as cloud that a hosting party
        makes available for Chemaxon (“<b>Host Party</b>”) to provide services
        set forth in this document (“<b>e-business Hosting Environment</b>”).
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Registration">Registration</h4>
      <p>
        In order to use CSD, you will be required to complete a registration and
        will have a user account with Chemaxon.
      </p>
      <p>
        <b>One account per user.</b> One CSD user can have only one Chemaxon
        Account. Consequences of doing otherwise may include provision of a
        notice, restriction of accessing Chemaxon products and services, or may
        eventually lead to close all accounts in question.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Availability">Availability</h4>
      <p>Chemaxon SaaS Demo provides two ways of testing:</p>
      <ul>
        <li>
          <b>Evaluation</b>: Subject to fees and charges, Chemaxon will set up
          your own test environment in which your activities and data, such as
          input molecules and search queries, are handled as private
          information. They are not accessible to any third parties in any way.
        </li>
        <li>
          <b>Trial</b>: You can use Chemaxon’s common trial environment. You may
          upload content there that are - if Chemaxon specifically states it
          otherwise - accessible to all other visitors and testers. Chemaxon
          does not guarantee any confidentiality. You shall be solely
          responsible for your own uploads and the consequences resulted in.
          Chemaxon may not charge you for this service.
        </li>
      </ul>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Content/DataGenerated">
        Content / Data Generated
      </h4>
      <p>
        The technology used at CSD are based on functionality developed by
        Chemaxon. Wherever possible, performance will use the latest release
        version of the Chemaxon functionality. Chemaxon reserves the rights at
        its sole discretion to add or remove features and functionalities to or
        from CSD, respectively. Chemaxon assumes no liability, obligation or
        undertaking whatsoever with regard to or arising from such updates. Any
        use of data generated on CSD, including chemical structures, information
        related thereof and results of structure based predictions should be
        cited to Chemaxon, date of data generation and a link to Chemaxon.
      </p>
      <p>
        <b>Chemaxon’s rights.</b> Everything that you read or see on CSD is
        copyrighted or otherwise protected and owned by Chemaxon or a third
        party who licensed or granted to Chemaxon the right to use such
        material. Unless otherwise expressly noted, nothing that you read or see
        on CSD may be copied or used except as provided in these Terms of Use or
        with the prior written approval of Chemaxon.
      </p>
      <p>
        <b>Restricted use.</b> Chemaxon does not grant you permission to print
        individual pages from CSD except provided herein. No other permission is
        granted to you to print, copy, reproduce, distribute, license, transfer,
        sale, transmit, upload, download, store, display in public, alter,
        modify or create derivative works of these materials. This grant of
        permission is not a transfer of title, and under this permission you may
        not:
      </p>
      <ul>
        <li>
          use the materials for any commercial purpose, or for any public
          display (commercial or non-commercial); if this is your intention you
          need to contact Chemaxon at{" "}
          <a
            className="external-link"
            href="mailto:sales@chemaxon.com"
            rel="nofollow"
          >
            sales[at]chemaxon[dot]com
          </a>{" "}
          and purchase Chemaxon Cloud license(s);
        </li>
        <li>
          remove any copyright, trademark or other proprietary notations from
          the materials; or
        </li>
        <li>
          transfer the materials to another person or third parties or "mirror"
          the materials on any other server.
        </li>
      </ul>
      <p>
        Chemaxon makes no warranties or representations, other than specified
        herein, to you that your use of any materials displayed on CSD will not
        infringe the rights of third parties. Chemaxon is not in a position to
        assess the validity of any proprietary rights or has no right to
        transfer the same to you or a third party. Therefore, Chemaxon cannot
        provide comment or permission (except as provided herein) concerning any
        input you make to CSD and the use, copying, or distribution of any
        information and data generated on CSD.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-YourResponsibilities-General">
        Your Responsibilities - General
      </h4>
      <p>
        <b>Credentials.</b> You are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer. You accept responsibility for all activities that
        occur under your account or password and such use shall be deemed to be
        use by you. You will ensure that all use of your account fully complies
        with these Terms of Use. Transfer of the account by you to any other
        person or entity is prohibited.
      </p>
      <p>
        <b>Capacity planning.</b> You acknowledge that it is your responsibility
        to determine whether CSD, the e-business Hosting Environment and their
        combination will meet your capacity, performance, or scalability needs.
        You are responsible for planning and for requesting changes to the
        e-business Hosting Environment, including any additional capacity
        required supporting anticipated peaks in demand that may significantly
        increase web site hits, transaction volumes, or otherwise increase
        system resource utilization.
      </p>
      <p>
        <b>Your content and Digital Certificates.</b> You are responsible for
        all your materials uploaded and/or downloaded or actions made to, in or
        from the e-business Hosting Environment, including information, data,
        files, selection, creation, design, usage, licensing, maintenance,
        testing, and support (“<b>Your Components</b>”). You are also
        responsible for any individual’s personal information or any information
        you consider as confidential. CSD may allow you to include within the
        Your Components personal information about others. You acknowledge and
        agree that you are solely responsible for any personal information that
        may be contained in Your Components, including any information which you
        share with third parties, and that you are and remain in compliance with
        applicable data privacy and protection laws.
      </p>
      <p>
        You agree not to submit or transmit to CSD any written material that is
        unlawful, threatening, libelous, defamatory, obscene, pornographic,
        profane, or might in any other way violate any law, regulation, or rule.
        You are solely responsible for any material you submit to CSD. You
        further agree not to upload, email, post or transmit to, or distribute
        or otherwise publish through CSD any written material which disrupts the
        normal operation of CSD, including posting or otherwise transmitting
        material that is not related to the subject at issue or otherwise
        restricts or inhibits any other user from using CSD. Through your usage
        of CSD, you may submit and/or Chemaxon may gather certain limited
        information about you and your website usage. We are free to use such
        information for any purpose we deem appropriate, including marketing
        purposes. For more information, please check our{" "}
        <a
          href="https://docs.chemaxon.com/display/docs/Privacy+Policy+for+Chemaxon+Synergy"
          rel="nofollow"
        >
          Privacy Policy for CSD
        </a>.
      </p>
      <p>
        <b>Security.</b> Security-related services by the Host Party including
        process security management, physical security, and network security are
        specified in documents made available by the Host Party. When using CSD,
        information will be transmitted over a medium that is beyond the control
        and jurisdiction of Chemaxon and its suppliers. Accordingly, Chemaxon
        assumes no liability for or relating to the delay, failure,
        interruption, or corruption of any data or other information transmitted
        in connection with use of CSD. Therefore, you acknowledge that Chemaxon
        does not control and the transfer of data over telecommunication
        facilities, including the Internet. Chemaxon does not warrant that it
        will be able to prevent third party disruptions of the e-business
        Hosting Environment or in connection with Your<b> </b>Components,
        provided that it has taken all reasonable commercial and technical
        measures to avoid this eventuality. You agree and acknowledge that
        Chemaxon shall assume no liability whatsoever for the security-related
        services by the Host Party and/or telecommunication facilities described
        in this Section “Security”.
      </p>
      <p>
        <b>Locations.</b> Chemaxon’s headquarters is located in Budapest, in
        Hungary. Chemaxon makes no representation that the materials on CSD are
        appropriate or available for use in other locations. Access to the
        content may not be legal by certain persons or in certain countries
        outside of Hungary. If you access CSD from outside Hungary, you do so at
        your own risk and are responsible for compliance with the laws of your
        jurisdiction.
      </p>
      <p>
        <b>Performance Report.</b> Within thirty (30) days after the expiration
        of your test session, You may present CHEMaxon with a written report or
        summary evaluating the Software and providing suggestions, criticisms
        and comments about performance of CSD ("<b>Performance Report</b>"). You
        agree to answer all reasonable questions of CHEMaxon relating to the
        operation and performance of CSD. CHEMaxon may use such Performance
        Report and information for research purposes, improvements and
        enhancements to the Chemaxon Cloud without any rights accruing to you.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-YourResponsibilitiesforEvaluations">
        Your Responsibilities for Evaluations
      </h4>
      <p>
        <b>Security considerations.</b> You acknowledge that you have reviewed
        the security features of the e-business Hosting Environment and
        responsibilities of the Host Party and have determined that they meet
        your security needs. You are solely responsible for determining the
        appropriate procedures and controls regarding security of Your
        Components and for the implementation of any such procedures and
        controls.
      </p>
      <p>
        <b>No Refund.</b> Chemaxon generally refuses to refund credits applied
        to use in CSD. However, your written claims may be evaluated otherwise
        at Chemaxon’s sole discretion.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Chemaxon’sResponsibilitiesforEvaluations-Confidentiality">
        Chemaxon’s Responsibilities for Evaluations - Confidentiality
      </h4>
      <p>
        <b>Definition.</b> "<b>Confidential Information</b>" means any
        information disclosed by one party ("<b>Discloser</b>") to the other (
        "<b>Recipient</b>"), directly or indirectly, in writing, orally or by
        inspection of tangible objects, which is designated as "Confidential,"
        "Proprietary" or some similar designation, or learned by Recipient under
        circumstances in which such information would reasonably be understood
        to be confidential. Confidential Information may include information
        disclosed in confidence to Discloser by third parties. For the purposes
        of this document, (a) CSD and content of CSD, (b) the access rights to
        CSD, (c) any information, documentation, benchmark results and/or
        proprietary materials relating to CSD, RIGHTS, or this document, (d) the
        results of any performance, functional or other evaluation of CSD i.e.
        the contents of the Performance Report and (e) Your Components shall be
        deemed as Confidential Information.
      </p>
      <p>
        <b>Exceptions.</b> The confidentiality obligations herein shall not
        apply with respect to any of the Discloser's Confidential information
        which Recipient can demonstrate: (a) was in the public domain at the
        time it was disclosed to Recipient or has become in the public domain
        through no act or omission of Recipient; (b) was known to Recipient,
        without restriction, at the time of disclosure as shown by the files of
        Recipient in existence at the time of disclosure; (c) was disclosed by
        Recipient with the prior written approval of Discloser; (d) was
        independently developed by Recipient without any use of Discloser's
        Confidential Information by employees or other agents of (or contractors
        hired by) Recipient who had no access to or did not rely on Discloser's
        Confidential Information; (e) became known to Recipient, without
        restriction, from a source other than Discloser without breach of this
        Agreement by Recipient and otherwise not in violation of Discloser's
        rights; or (f) is released from the term of this document by mutual
        agreement of the parties in writing.
      </p>
      <p>
        <b>Restrictions on Use and Disclosure.</b> Recipient agrees not to use
        Discloser's Confidential Information or disclose, distribute or
        disseminate Discloser's Confidential Information except in furtherance
        of the performance of its obligations or enforcement of its rights
        hereunder or as otherwise expressly agreed by Discloser in writing.
        Recipient agrees to restrict access to such Confidential Information to
        those employees or consultants of Recipient who need to know such
        Confidential Information for performing as contemplated hereunder and
        have agreed in writing to be bound by a confidentiality obligation no
        less protective than that contained in this Agreement. Recipient shall
        exercise the same degree of care to prevent unauthorized use or
        disclosure of Discloser's Confidential Information to others as it takes
        to preserve and safeguard its own information of like importance, but in
        no event less than reasonable care.
      </p>
      <p>
        <b>Compelled Disclosure.</b> If Recipient is compelled by a court or
        other competent authority or applicable law to disclose Confidential
        Information of Discloser, it shall give Discloser prompt written notice
        and shall provide Discloser with reasonable cooperation at Discloser's
        expense so that Discloser may take steps to oppose such disclosure or
        obtain a restraining order. Recipient shall not be in breach of its
        obligations herein if it makes any legally compelled disclosure provided
        that Recipient meets the foregoing notice and cooperation requirements.
      </p>
      <p>
        <b>Information Security.</b>
        <em> </em>During your testing CSD, Chemaxon will access to e-business
        Hosting Environment, data storage, servers and other aspects of
        information technology environment making available to you (
        “<b>Your Evaluation Environment</b>”), and Chemaxon agrees that any such
        access shall be subject to the following requirements: (i) Chemaxon
        shall only access Your Evaluation Environment as directed by you for
        purposes of assisting your evaluation project; (ii) you are solely
        responsible for determining what in Your Evaluation Environment shall be
        accessible to Chemaxon and Chemaxon shall not attempt to access any
        other portion of Your Evaluation Environment; and (iii) you shall be
        solely responsible for protecting all login credentials in your
        possession that allow access to Your Evaluation Environment and shall
        ensure that such credentials are not shared nor distributed to others.
        Further, Chemaxon shall not copy, record (by writing, electronically, or
        by any other means), transfer, remove from Your Evaluation Environment,
        or otherwise use any information residing in Your Evaluation Environment
        for any purpose other than to conduct your evaluation project. For
        purposes hereof, all information in Your Evaluation Environment accessed
        or made available to Chemaxon shall be deemed your Confidential
        Information.
      </p>
      <p>
        <b>Injunctive Relief.</b> Recipient acknowledges that breach of the
        confidentiality obligations would cause irreparable harm to Discloser,
        the extent of which may be difficult to ascertain. Accordingly,
        Recipient agrees that Discloser is entitled to immediate injunctive
        relief in the event of breach of an obligation of confidentiality by
        Recipient, and that Discloser shall not be required to post a bond or
        show irreparable harm in order to obtain such injunctive relief.
      </p>
      <p>
        <b>Return of Confidential Information.</b> As between the parties,
        Confidential Information shall remain the property of the Discloser. At
        any time, upon Discloser’s reasonable request, Recipient shall promptly
        (and in any event within 30 days) return to Discloser or destroy, at the
        election of the Discloser, any Confidential Information of the
        Discloser. In addition, within 30 days after termination of this
        Agreement, Recipient shall (i) promptly return all tangible materials
        containing such Confidential Information to Discloser, (ii) remove all
        Confidential Information (and any copies thereof) from any computer
        systems of the Recipient, its contractors and its distributors, and
        confirm in writing that all materials containing Confidential
        Information have been destroyed or returned to Discloser, as applicable,
        by Recipient. Recipient shall cause its affiliates, agents, contractors,
        and employees to strictly comply with the foregoing. In case of
        Chemaxon, as Recipient, shall be requested by you to save and deliver
        your data or Your Components to you, additional fee payable by you to
        Chemaxon may apply.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-YourResponsibilitiesforTrials">
        Your Responsibilities for Trials
      </h4>
      <p>
        Trials on CSD are public services and do not support private data. The
        structures provided by users on CSD are publicly accessible. For
        avoidance of doubt, Chemaxon is not the owner of such structures.
      </p>
      <p>
        More generally, before you submit any information to CSD, including any
        comments, remarks, suggestions, ideas, notes, drawings, graphics,
        concepts, or other information or paste any web content, URL, type any
        chemical name or other text including any chemical name, drawing or
        uploading any chemical structure or data or uploading documents of any
        kind containing any chemical structure, please consider that CSD are
        open to the public. You take full responsibility for any information you
        provide to these web sites. Except as expressly set forth on CSD
        otherwise, if you submit any information to CSD, you are giving that
        information, and all your rights in it, to Chemaxon free of charge and
        such information shall be deemed to be non-confidential and Chemaxon
        shall have no obligation of any kind with respect to such information
        and shall be free to store, reproduce, use, disclose and distribute the
        information to others without limitation, without your consent or any
        compensation to you or anyone else. Chemaxon shall be free to use any
        know-how or techniques contained in such information for any purpose
        whatsoever including but not limited to developing, manufacturing and
        marketing products incorporating such information. This is true whether
        you submit such information to us by e-mail, through a form on CSD, on a
        bulletin board, or in any other manner. Chemaxon may from time to time
        monitor, review and, in its sole discretion, modify or delete any
        postings you make on CSD however, Chemaxon is not obligated to do so.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-NoWarranty/LimitedLiability">
        No Warranty / Limited Liability
      </h4>
      <p>
        You may use CSD only for the Purpose defined hereinabove. The content on
        CSD is not intended in any way to be professional chemical advice and
        should not be interpreted as recommendations. Chemaxon do not assume
        liability for any damages whatsoever arising in connection to CSD.
      </p>
      <p>
        CSD AND THE CONTENT IN CSD ARE PROVIDED "AS IS". CHEMaxon, ITS
        LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT PERMITTED BY LAW,
        DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
        INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY
        AND FITNESS FOR PARTICULAR PURPOSE AS WELL AS NON-INFRINGEMENT OF THIRD
        PARTIES' RIGHTS OTHER THAN SPECIFIED HEREIN. SPECIFICALLY, CHEMaxon, ITS
        LICENSORS, AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT
        THE QUALITY, CORRECTNESS, ACCURACY, RELIABILITY, USEFULNESS, VALIDITY,
        COMPLETENESS, CURRENTNESS, SUITABILITY OR TIMELINESS OF THE CONTENT,
        SOFTWARE, TEXT, GRAPHICS, TOOLS, LINKS, OR COMMUNICATIONS PROVIDED ON OR
        THROUGH THE USE OF CSD OR CHEMaxon, OR ON ANY SITE OR SITES "LINKED" TO
        CSD. CHEMaxon MAKES NO WARRANTY THAT CSD WILL BE AVAILABLE,
        UNINTERRUPTED, ERROR FREE, OR FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS.
      </p>
      <p>
        <b>Exclusion of Damages.</b> TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, IN NO EVENT SHALL CHEMaxon, ITS SUBSIDIARIES AND
        AFFILIATES, ITS LICENSORS, ITS SUPPLIERS, OR ANY THIRD PARTIES MENTIONED
        ON CSD BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION,
        INCIDENTAL AND CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH,
        LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
        INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE WEBSITE OR
        THE CONTENT OR ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
        INTERRUPTION, EFFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
        VIRUS, LINE SYSTEM FAILURE, LOSS OF DATA, OR LOSS OF USE RELATED TO CSD
        OR ANY WEBSITE OPERATED BY ANY THIRD PARTY, WHETHER BASED ON WARRANTY,
        CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT CHEMaxon
        IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU ARE DISSATISFIED
        WITH US, ANY OF OUR SERVICES OR THESE TERMS OF USE, YOUR SOLE AND
        EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF CSD.
      </p>
      <p>
        <b>Liability Cap.</b> EXCEPT WITH RESPECT TO EITHER PARTY’S OBLIGATIONS
        OF INDEMNIFICATION, THE TOTAL LIABILITY OF CHEMaxon ARISING OUT OF OR
        RELATED TO THIS AGREEMENT WILL NOT EXCEED THE GREATER OF USD $100 OR THE
        TOTAL AMOUNTS PAID BY YOU FOR CSD, IN THE TWELVE (12) MONTH PERIOD
        IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE LIABILITY.
      </p>
      <p>
        Any claims arising in connection with your use of CSD or any content
        must be brought within one (1) year of the date of the event giving rise
        to such action occurred.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Externalwebpages">
        External web pages
      </h4>
      <p>
        As a resource to our testers, CSD may provide links to other websites.
        However, because we do not control the content of the other websites we
        may link to, and due to their constantly changing nature, we cannot be
        responsible for the content, practices or standards of third-party
        sites. Chemaxon does not endorse the content on any third-party
        websites. Chemaxon is not responsible for the content of linked
        third-party sites, sites framed within CSD, or third-party
        advertisements, and does not make any representations regarding their
        content or accuracy. Your use of third-party websites is at your own
        risk and subject to the terms and conditions of use for such sites.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Trademarksandlogos">
        Trademarks and logos
      </h4>
      <p>
        Unless otherwise indicated, all logos, names, designs, and marks on CSD
        are trademarks or service marks owned or used under license by Chemaxon.
        The use or misuse of any of these marks or other information is strictly
        prohibited. Nothing contained herein shall be construed as conferring by
        implication, estoppel or otherwise any license or right under any patent
        or trademark of Chemaxon or any third party. Except as expressly
        provided above, nothing contained herein shall be construed as
        conferring any license or right under any Chemaxon copyright.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Indemnification">
        Indemnification
      </h4>
      <p>
        <b>By CHEMaxon.</b> CHEMaxon shall defend at its own expense any legal
        action brought against you to the extent that it is based on a claim or
        allegation that the SOFTWARE infringes a patent or copyright of a third
        party, and CHEMaxon will pay any costs and damages awarded against you
        in any such action, or agreed to under a settlement signed by CHEMaxon,
        that are attributable to any such claim but shall not be responsible for
        any compromise made or expense incurred without CHEMaxon’s consent. Such
        defense and payments are subject to the conditions that (a) You give
        CHEMaxon prompt written notice of such claim, (b) tenders to CHEMaxon
        sole control of the defense and settlement of the claim, and (c)
        reasonably cooperates with CHEMaxon when requested in connection with
        the defense and settlement of the claim. CHEMaxon will have no liability
        to so defend and pay for any infringement claim to the extent it (i) is
        based on modification of CSD other than by CHEMaxon, with or without
        authorization; or (ii) is based on the combination or use of CSD with
        any product, data, software (including, without limitation, an open
        source software), program or apparatus, device not provided by CHEMaxon
        in the e-business Hosting Environment if such infringement would not
        have arisen but for such use or combination; or (iii) results from your
        use of CSD after your use of CSD was expired or terminated; or (iv) is
        based on Your Components or generally your failure to comply with its
        obligations under this document.
      </p>
      <p>
        <b>By You.</b> You hereby agree to indemnify and hold harmless Chemaxon,
        its affiliates, officers, directors, employees, agents, shareholders,
        suppliers and any third party providers from and against any and all
        damages, losses, costs and expenses or liability arising out of or in
        connection with the performance of CSD or use, copying, or distribution
        of any information or data generated on CSD, whether alone or in
        combination with any product or service or resulting from any violation
        of this notice.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-YouruseCSD">Your use CSD</h4>
      <p>Furthermore, YOU, on top of the above clauses,</p>
      <ul>
        <li>
          understand and declare that your use of Chemaxon proprietary software,
          CSD is voluntary. You agree to immediately raise any concerns you
          might have. Chemaxon will use its best efforts to handle and response
          such concerns, but will assume no liability, obligation, or
          undertaking whatsoever with regard to such concerns.
        </li>
        <li>
          understand and consent to that all your activities made in CSD,
          including input data and mouse movements may be recorded, analyzed,
          and used internally by Chemaxon in order to (i) understand user’s
          behavior better and (ii) further improve the usability and
          functionality of its software products. For avoidance of doubt, your
          name and personal data will not be used for any other purpose.
        </li>
        <li>
          agree that you are responsible for any information transferred to and
          from CSD and saving and storing your input and output data and
          information used and generated during your sessions in CSD. Chemaxon
          takes no liability for any information regarding the sessions
          including history, calculations, structure list, any results generated
          during the sessions, which may be lost. Chemaxon will do its best
          efforts to avoid such losses.
        </li>
      </ul>
      <p>
        We continuously work on improving CSD. We, therefore, request your
        opinion by email. You can also directly approach us with your questions,
        comments, and requests.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-GoverningLaw">Governing Law</h4>
      <p>
        These Terms of Use are governed by the internal substantive laws of
        Hungary, without resort to its conflict of laws principles. You
        expressly agree that exclusive jurisdiction for any dispute with
        Chemaxon, or in any way relating to your use of CSD, resides in the
        courts of Hungary. However, if your institution is a state university in
        the USA and the constitution or laws of your institution’s state
        expressly require that the laws of your state apply to contracts to
        which the institution enters, then the jurisdictional and substantive
        laws of your state shall govern these Terms of Use without regard to
        conflicts of law provisions. If any provision of these Terms of Use is
        found to be invalid by any court having competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the
        remaining provisions of these Terms of Use.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Termination">Termination</h4>
      <p>
        You may terminate the agreement incorporated in these Terms of Use at
        any time by destroying all materials received from CSD, ceasing to use
        CSD.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Privacypolicy">
        Privacy policy
      </h4>
      <p>
        These Terms of Use constitute the entire agreement between you and
        Chemaxon with respect to the use of CSD and its content. Your use of CSD
        is also subject to Chemaxon’s{" "}
        <a
          href="https://docs.chemaxon.com/display/docs/Privacy+Policy+for+Chemaxon+Synergy"
          rel="nofollow"
        >
          Privacy Policy for CSD
        </a>.
        Chemaxon’s use of the information you provide in connection with your
        account registration will be treated by Chemaxon in conformity with
        Chemaxon’s aforementioned privacy policy.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Updates">Updates</h4>
      <p>
        Chemaxon may modify these Terms of Use at any time without notice to
        you, so it is advisable to review it frequently. The latest version of
        these Terms of Use is posted on the CSD and the product page of Chemaxon
        Cloud, all amended terms shall automatically be effective at your login
        CSD. Whenever modified, this document will automatically appear at your
        login to CSD.
      </p>
      <p>
        The date of the latest version of this document appears on the first
        line of this page.
      </p>
      <h4 id="EndUserSubscriptionAgreement-EUSA-Contactdetails">
        Contact details
      </h4>
      <p>
        For legal issues, Chemaxon may be contacted at{" "}
        <a
          className="external-link"
          href="mailto:compliance@chemaxon.com"
          rel="nofollow"
        >
          compliance [at] chemaxon [dot] com
        </a>.
      </p>
      <p>
        For technical related inquiries, ChemAxon may be contacted at{" "}
        <a
          className="external-link"
          href="mailto:support@chemaxon.com"
          rel="nofollow"
        >
          support [at] chemaxon [dot] com
        </a>.
      </p>
    </div>
  );
};

export default EusaContent;
