export type PermissionMap = {
  [Key in PlatformDomainObjectType]?: PlatformPermission[];
};

export enum PlatformPermission {
  READ = "READ",
  WRITE = "WRITE",
  CREATE = "CREATE",
  DELETE = "DELETE",
  ALL = "*",
}

export enum PlatformDomainObjectType {
  APPLICATION = "application",
  APPLICATION_MENU = "applicationMenu",
  GROUP = "group",
  PROJECT = "project",
  RESOURCE = "resource",
  USER = "user",
  TEAM = "team",
}
