import React from "react";
import * as PropTypes from "prop-types";

import { Button, ElevationBelowXs } from "@chemaxon/design-system";
import { Outline } from "@chemaxon/design-system/color";
import { ListItemIcon, ListItemText, MenuItem, Popover } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Application } from "src/ui/models/Application";

type HideMenuProps = {
  allApps: Application[];
  hiddenApps: Application["id"][];
  toggleVisibility: (app: Application["id"]) => void;
};

export default function HideMenu(props: HideMenuProps) {
  const { allApps, hiddenApps, toggleVisibility } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonText =
    hiddenApps.length === 0
      ? "Hide applications"
      : hiddenApps.length === 1
      ? "1 hidden application"
      : hiddenApps.length + " hidden applications";

  return (
    <React.Fragment>
      <Button colorVariant="secondary" onClick={handleClick}>
        {buttonText}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            mt: 1,
            py: 2,
            borderRadius: 2,
            border: `1px solid ${Outline.variant}`,
            ...ElevationBelowXs,
          },
        }}
      >
        {allApps.map((app, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              toggleVisibility(app.id);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 6,
                mr: 2,
              }}
            >
              {props.hiddenApps.indexOf(app.id) === -1 ? (
                <VisibilityIcon color="secondary" />
              ) : (
                <VisibilityOffIcon color="disabled" />
              )}
            </ListItemIcon>
            <ListItemText primary={app.displayName} />
          </MenuItem>
        ))}
      </Popover>
    </React.Fragment>
  );
}

HideMenu.propTypes = {
  allApps: PropTypes.array,
  hiddenApps: PropTypes.array,
  toggleVisibility: PropTypes.func,
};
