import React, { useState } from "react";
import Dialog from "src/ui/components/dialog/Dialog";
import { Checkbox, FormControlLabel } from "@chemaxon/design-system";

const ConfirmationField = (props: ConfirmationProps) => {
  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox onChange={e => props.onConfirmation(e.target.checked)} />
      }
    />
  );
};

type ConfirmationProps = {
  label: string;
  onConfirmation: (b: boolean) => void;
};

export default function CheckBoxConfirmDialog(props: ConfirmDialogProps) {
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);

  const handleEvent = function (
    eventHandler: React.EventHandler<React.FormEvent<HTMLInputElement>>,
    event: React.FormEvent<HTMLInputElement>,
  ) {
    setConfirmButtonEnabled(false);
    eventHandler(event);
  };

  return (
    <Dialog
      open={props.isShown}
      title={props.title}
      subtitle={props.subtitle}
      content={
        <ConfirmationField
          label={props.checkBoxLabel}
          onConfirmation={setConfirmButtonEnabled}
        />
      }
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          colorVariant: "secondary",
          onClick: handleEvent.bind(null, props.cancel),
        },
        {
          id: "confirm",
          label: props.confirmButtonLabel
            ? props.confirmButtonLabel
            : "Confirm",
          colorVariant: props.destructive ? "destructive" : "primary",
          onClick: handleEvent.bind(null, props.submit),
          loading: props.loading,
          disabled: !confirmButtonEnabled,
        },
      ]}
      onClose={handleEvent.bind(null, props.cancel)}
      divider={props.divider}
    />
  );
}

type ConfirmDialogProps = {
  cancel: () => void;
  submit: () => void;
  isShown: boolean;
  title: string;
  subtitle?: string;
  checkBoxLabel: string;
  confirmButtonLabel: string;
  destructive: boolean;
  loading: boolean;
  divider: boolean;
};
