import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { Typography } from "@chemaxon/design-system";

const FormHeader = ({ title, description }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      {description ? (
        <Typography variant="body1">{description}</Typography>
      ) : null}
    </Stack>
  );
};

FormHeader.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string,
};

export default FormHeader;
