import type { GoogleTagManagerConfig } from "@analytics/google-tag-manager";

type EnvDetails = {
  name: string;
  baseDomainName: string;
};
enum DeploymentEnv {
  Local = "local",
  Preview = "preview",
  Development = "development",
  Integration = "integration",
  Production = "production",
}

enum HostnamePatterns {
  Local = "terminus.lvh.cxcloud.io",
  Preview = "crp-preview.cxcloud.io",
  Development = "crp-dev.cxcloud.io",
  Integration = "crp-int.cxcloud.io",
  Production = "cloud.chemaxon.com",
}

export const getDeploymentEnv = (): EnvDetails => {
  const host = window.location.hostname;

  if (new RegExp(HostnamePatterns.Production).test(host)) {
    return {
      name: DeploymentEnv.Production,
      baseDomainName: HostnamePatterns.Production,
    };
  }

  if (new RegExp(HostnamePatterns.Integration).test(host)) {
    return {
      name: DeploymentEnv.Integration,
      baseDomainName: HostnamePatterns.Integration,
    };
  }

  if (new RegExp(HostnamePatterns.Development).test(host)) {
    return {
      name: DeploymentEnv.Development,
      baseDomainName: HostnamePatterns.Development,
    };
  }

  if (new RegExp(HostnamePatterns.Preview).test(host)) {
    return {
      name: DeploymentEnv.Preview,
      baseDomainName: HostnamePatterns.Preview,
    };
  }

  return {
    name: DeploymentEnv.Local,
    baseDomainName: HostnamePatterns.Local,
  };
};

export const isTenantDomain = (): boolean => {
  return window.location.host !== getDeploymentEnv().baseDomainName;
};

type AnalyticsConfig = {
  [key in DeploymentEnv]?: {
    googleTagManager?: GoogleTagManagerConfig;
  };
};

const GTM_CONTAINER_ID_TERMINUS = "GTM-MG7TXD4";

export const AnalyticsConfig: AnalyticsConfig = {
  [DeploymentEnv.Production]: {
    googleTagManager: {
      containerId: GTM_CONTAINER_ID_TERMINUS,
    },
  },
  // Fix/tweak GA tracking per environment. Enabling them now would mean tracking in all environments,
  // The values however are coming from GTM, which is configured to support different environments.
  // [DeploymentEnv.Integration]: {
  //   googleTagManager: {
  //     containerId: GTM_CONTAINER_ID_TERMINUS,
  //     auth: "dI4sW8TfO544WQo6wo1k1g",
  //     preview: "env-15",
  //   },
  // },
  // [DeploymentEnv.Development]: {
  //   googleTagManager: {
  //     containerId: GTM_CONTAINER_ID_TERMINUS,
  //     auth: "85QNGKo3dLVD0SxLVghN7w",
  //     preview: "env-14",
  //   },
  // },
  // [DeploymentEnv.Preview]: {
  //   googleTagManager: {
  //     containerId: GTM_CONTAINER_ID_TERMINUS,
  //     auth: "GSTi2but5LSoMBgDNLMeYg",
  //     preview: "env-18",
  //   },
  // },
  // [DeploymentEnv.Local]: {
  //   googleTagManager: {
  //     containerId: GTM_CONTAINER_ID_TERMINUS,
  //     auth: "lIn5KlxDBrxyM220X9fp0A",
  //     preview: "env-19",
  //   },
  // },
};

export const getAnalyticsConfig = () => AnalyticsConfig[getDeploymentEnv()];
