import React, { useState } from "react";
import { TextField } from "@chemaxon/design-system";
import { Button } from "@chemaxon/design-system";
import { Stack } from "@mui/material";

import MemberList from "src/ui/components/team-members/MemberList.tsx";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { useAddProject } from "src/ui/services/ProjectService";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import SelectField from "src/ui/components/select/Select";
import {
  DropdownIndicator,
  ClearIndicator,
  Option,
} from "src/ui/components/select/SelectPartials";
import FormActions from "src/ui/components/form/FormActions";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import { TeamMember } from "src/ui/models/TeamMember.ts";
import { ProjectStatus } from "src/ui/models/Project.ts";
import {
  useGetCurrentTeam,
  useGetCurrentUser,
} from "src/ui/services/UserInfoService.ts";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "src/ui/utils/routes.ts";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError.ts";
import { SingleValue } from "react-select";
import RemoveMemberButton from "src/ui/components/removeMemberButton/RemoveMemberButton.tsx";
import { usePermissions } from "src/ui/utils/usePermissions.tsx";
import { useGetTeamMembers } from "src/ui/services/TeamMemberService.ts";
import {
  FormErrors,
  FormField,
  FormData,
  helperTexts,
} from "../ProjectEditorFields";
import log from "loglevel";

export default function AddProjectView() {
  const getCurrentUserQueryResult = useGetCurrentUser();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getTeamMembersQueryResult = useGetTeamMembers();

  const navigate = useNavigate();
  const { checkPermission } = usePermissions();

  const addProjectQueryResult = useAddProject();

  const [formData, setFormData] = useState<FormData>({
    [FormField.id]: 0,
    [FormField.displayId]: "",
    [FormField.title]: "",
    [FormField.externalId]: "",
    [FormField.assigneeId]: 0,
    [FormField.description]: "",
    [FormField.conclusion]: "",
    [FormField.status]: ProjectStatus.open,
    [FormField.memberIds]: [],
    [FormField.newMemberId]: undefined,
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({
    [FormField.id]: "",
    [FormField.displayId]: "",
    [FormField.title]: "",
    [FormField.externalId]: "",
    [FormField.assigneeId]: "",
    [FormField.description]: "",
    [FormField.conclusion]: "",
    [FormField.status]: "",
    [FormField.memberIds]: "",
    [FormField.newMemberId]: "",
  });

  if (
    getTeamMembersQueryResult.isPending ||
    getCurrentUserQueryResult.isPending ||
    getCurrentTeamQueryResult.isPending
  ) {
    return <div>Loading...</div>;
  }

  if (
    getTeamMembersQueryResult.isError ||
    getCurrentUserQueryResult.isError ||
    getCurrentTeamQueryResult.isError
  ) {
    return (
      <>
        <div>Cant access new project</div>
        <div>You may not have permission to view it.</div>
      </>
    );
  }

  const currentUser = getCurrentUserQueryResult.data;
  const currentTeam = getCurrentTeamQueryResult.data;
  const teamMembers = getTeamMembersQueryResult.data ?? [];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const projectData = {
      ...formData,
      created: new Date(),
      authorId: currentUser!.id,
      team: currentTeam!.id,
    };

    addProjectQueryResult
      .mutateAsync(projectData)
      .then(() => {
        navigate(AppRoutePath.ProjectList);
      })
      .catch(error => {
        if (error instanceof SynergySpringMVCError) {
          const newFormErrors: FormErrors = { ...formErrors };

          error.getFieldErrors().forEach(e => {
            newFormErrors[e.field as FormField] = e.message;
          });

          setFormErrors(newFormErrors);
        } else {
          log.error("Can't add project some reason. ", error);
        }
      });
  };

  const handleAddNewMember = (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.newMemberId) {
      return;
    }
    formData.memberIds.push(formData.newMemberId);

    const newFormData = {
      ...formData,
      [FormField.memberIds]: formData.memberIds,
      [FormField.newMemberId]: undefined,
    };

    setFormData(newFormData);
  };

  const handleNewMemberChange = (newValue: SingleValue<MemberOptionType>) => {
    if (newValue === null) {
      return;
    }

    setFormData({
      ...formData,
      [FormField.newMemberId]: newValue.value,
    });
  };

  const handleRemoveMember = (idToRemove: number) => {
    const newFormData = {
      ...formData,
      [FormField.memberIds]: formData.memberIds.filter(
        item => item !== idToRemove,
      ),
    };

    setFormData(newFormData);
  };

  const mapUsers = (teamMembers: TeamMember[]): MemberOptionType[] => {
    if (!teamMembers) {
      return [];
    }
    return teamMembers.map(member => {
      return { label: member.userName, value: member.id };
    });
  };

  const getUserName = (userId: number, teamMembers: TeamMember[]) => {
    if (!teamMembers) {
      return "";
    }
    const user = teamMembers.find(member => member.id === userId);
    return user ? user.userName : "";
  };

  const handleAssigneeChange = (newValue: SingleValue<MemberOptionType>) => {
    const newFormData = {
      ...formData,
      assigneeId: newValue ? newValue.value : 0,
    };
    setFormData(newFormData);
  };

  const handleChange =
    (
      fieldName: FormField,
    ): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    event => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.value,
      };
      setFormData(newFormData);

      // clearing potentially shown error message under the field
      const newFormErrors = {
        ...formErrors,
        [fieldName]: "",
      };
      setFormErrors(newFormErrors);
    };

  const handleStatusChange = (
    newValue: SingleValue<ProjectStatusOptionType>,
  ) => {
    if (newValue === null) {
      return;
    }

    const newFormData = {
      ...formData,
      [FormField.status]: newValue.value,
    };
    setFormData(newFormData);

    const newFormErrors = {
      ...formErrors,
      [FormField.status]: "",
    };
    setFormErrors(newFormErrors);
  };

  const handleCancelButton = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(-1);
  };

  const isInErrorState = (field: FormField) => {
    return !!(formErrors[field] || "");
  };

  const getShownText = (field: FormField) => {
    return isInErrorState(field) ? formErrors[field] : helperTexts[field];
  };

  const getGeneralInputProps = (field: FormField) => {
    return {
      name: field,
      value: formData[field],
      autoComplete: "off",
      id: field,
    };
  };

  const getGeneralProps = (field: FormField) => {
    return {
      className: isInErrorState(field) ? "error" : "",
      helperText: (
        <HelperTextTypography
          text={getShownText(field)}
          isError={isInErrorState(field)}
        />
      ),
      onChange: handleChange(field),
      error: isInErrorState(field),
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  type MemberOptionType = {
    label: string;
    value: number;
  };

  type ProjectStatusOptionType = {
    label: string;
    value: ProjectStatus;
  };

  const renderRemoveButton = (teamMember: TeamMember) => (
    <RemoveMemberButton
      hasPermission={checkPermission(
        PlatformDomainObjectType.PROJECT,
        PlatformPermission.WRITE,
      )}
      onClick={() => handleRemoveMember(teamMember.id)}
    />
  );

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.PROJECT,
          platformPermission: PlatformPermission.CREATE,
        }}
      >
        <Subheader title={"Add Project"} />
        <ViewContainer id={"addProjectContainer"}>
          <form id="addProject" onSubmit={handleSubmit}>
            <FormLayoutVertical>
              <TextField
                label="ID"
                {...getGeneralProps(FormField.displayId)}
                required
                InputProps={getGeneralInputProps(FormField.displayId)}
              />

              <TextField
                label="Title"
                {...getGeneralProps(FormField.title)}
                InputProps={getGeneralInputProps(FormField.title)}
              />

              <TextField
                label="External ID"
                {...getGeneralProps(FormField.externalId)}
                InputProps={getGeneralInputProps(FormField.externalId)}
              />

              <SelectField
                id={FormField.assigneeId}
                label="Assignee"
                {...getGeneralProps(FormField.assigneeId)}
                options={mapUsers(teamMembers)}
                onChange={handleAssigneeChange}
                defaultValue={
                  formData.assigneeId
                    ? {
                        value: formData.assigneeId,
                        label: getUserName(formData.assigneeId, teamMembers),
                      }
                    : null
                }
                placeholder="Select assignee"
                isClearable={true}
                components={{
                  DropdownIndicator,
                  ClearIndicator,
                  // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                  Option,
                }}
              />

              <TextField
                label="Description"
                {...getGeneralProps(FormField.description)}
                InputProps={getGeneralInputProps(FormField.description)}
              />

              <TextField
                label="Conclusion"
                {...getGeneralProps(FormField.conclusion)}
                InputProps={getGeneralInputProps(FormField.conclusion)}
              />

              <SelectField
                id={FormField.status}
                label="Status"
                {...getGeneralProps(FormField.status)}
                defaultValue={
                  typeof formData !== "undefined"
                    ? {
                        value: formData.status,
                        label: capitalizeFirstLetter(formData.status),
                      }
                    : null
                }
                onChange={handleStatusChange}
                isSearchable={false}
                options={[
                  { value: ProjectStatus.open, label: "Open" },
                  { value: ProjectStatus.closed, label: "Closed" },
                ]}
                components={{
                  DropdownIndicator,
                  // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                  Option,
                }}
              />

              <FormLayoutVertical id="listTeamMembersContainer">
                <Stack direction="row" alignItems="flex-start" spacing={6}>
                  <SelectField
                    id={FormField.newMemberId}
                    label="Members"
                    {...getGeneralProps(FormField.newMemberId)}
                    options={mapUsers(
                      teamMembers.filter(
                        user => !formData.memberIds.includes(user.id),
                      ),
                    )}
                    onChange={handleNewMemberChange}
                    placeholder="Add new member"
                    defaultValue={
                      formData.newMemberId
                        ? {
                            value: formData.newMemberId,
                            label: getUserName(
                              formData.newMemberId,
                              teamMembers,
                            ),
                          }
                        : null
                    }
                    isClearable={true}
                    components={{
                      DropdownIndicator,
                      ClearIndicator,
                      // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                      Option,
                    }}
                  />
                  <Button
                    id="addNewMemberIdButton"
                    colorVariant="outlined"
                    onClick={handleAddNewMember}
                    disabled={formData.newMemberId === 0}
                    size="medium"
                    sx={theme => ({ "&&": { mt: theme.spacing(6) } })}
                  >
                    Add
                  </Button>
                </Stack>

                <MemberList
                  memberList={teamMembers.filter(member =>
                    formData[FormField.memberIds].includes(member.id),
                  )}
                  renderRemoveButton={renderRemoveButton}
                />
              </FormLayoutVertical>

              <FormActions>
                <Button
                  colorVariant="primary"
                  type="submit"
                  loading={addProjectQueryResult.isPending}
                >
                  Create project
                </Button>
                <Button colorVariant="secondary" onClick={handleCancelButton}>
                  Cancel
                </Button>
              </FormActions>
            </FormLayoutVertical>
          </form>
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
}
