const allowedFields = [
  "id",
  "team",
  "displayName",
  "address",
  "urlPart",
  "status",
  "teamName",
  "notes",
  "clientId",
  "clientSecret",
  "directlyAccessed",
  "features",
  "identities",
];

export default allowedFields;
