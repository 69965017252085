import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import type {
  Team,
  TeamAddRequest,
  TeamEditRequest,
  TeamGetResponse,
  TeamImportRequest,
  TeamsGetResponse,
} from "src/ui/models/Team";
import { TeamEditResponse, TeamSyncResponse } from "src/ui/models/Team";
import { queryKeys as applicationQueryKeys } from "src/ui/services/ApplicationService";
import { queryKeys as teamMemberQueryKeys } from "src/ui/services/TeamMemberService";

export const SERVICE_URL = "/internal/teams";

class TeamService {
  /**
   * Get list of registered teams.
   */
  static getTeams(): Promise<TeamsGetResponse> {
    return Api.get(SERVICE_URL);
  }

  /**
   * Get team details.
   */
  static getTeam(teamId: number): Promise<TeamGetResponse> {
    return Api.get(`${SERVICE_URL}/${teamId}`);
  }

  /**
   * Add new team.
   */
  static addTeam(input: TeamAddRequest): Promise<number> {
    return Api.post(SERVICE_URL, input);
  }

  /**
   * Edit existing team.
   */
  static editTeam(input: TeamEditRequest): Promise<TeamEditResponse> {
    return Api.put(SERVICE_URL, input);
  }

  /**
   * Remove team.
   */
  static removeTeam(teamId: number): Promise<void> {
    return Api.delete(`${SERVICE_URL}/${teamId}`);
  }

  /**
   * Sync team.
   */
  static syncTeam(teamId: number): Promise<TeamSyncResponse> {
    return Api.get(`${SERVICE_URL}/${teamId}/sync`);
  }

  /**
   * Import team.
   */
  static importTeam(teamJson: TeamImportRequest): Promise<number> {
    return Api.post("/internal/import", teamJson);
  }

  /**
   * Lookup team in given teams by the team id.
   */
  static lookupTeamById(teams: Team[], teamId: number) {
    if (!teams) {
      return;
    }
    return teams.find(team => {
      return team.id == teamId;
    });
  }

  /**
   * Upload custom EUSA contract to the team defined by the team id.
   */
  static uploadEusa(teamId: number, eusaFile: File): Promise<number> {
    return Api.postMultipartData(
      `${SERVICE_URL}/${teamId}/uploadEusa`,
      eusaFile,
    );
  }

  /**
   * Gets the custom EUSA contract of the given team defined by its id.
   */
  static getCustomEusa(teamId: number) {
    return Api.getPdf(`${SERVICE_URL}/${teamId}/eusa`).then(
      (response: Response) => {
        let filename = "";
        if (response.status === 204) {
          return {
            path: filename,
            mimeType: "",
            content: null,
          };
        }
        const disposition = response.headers.get("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        return {
          path: filename,
          mimeType: response.headers.get("Content-Type"),
          content: response.body,
        };
      },
    );
  }

  static getCustomEusaUrl(teamId: number) {
    return `${SERVICE_URL}/${teamId}/eusa`;
  }

  /**
   * Remove team.
   */
  static removeEusa(teamId: number): Promise<number> {
    return Api.delete(`${SERVICE_URL}/${teamId}/eusa`);
  }
}

const QUERY_KEYS_NAMESPACE = "teams";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
  details: () => [QUERY_KEYS_NAMESPACE, "detail"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
  detail: (teamId: number | undefined) =>
    [...baseQueryKeys.details(), teamId] as const,
} satisfies QueryKeysDefinition;

export const useGetTeams = () =>
  useQuery({
    queryKey: queryKeys.list(),
    queryFn: () => TeamService.getTeams(),
  });

export const useGetTeam = (teamId: number) =>
  useQuery({
    queryKey: queryKeys.detail(teamId),
    queryFn: () => TeamService.getTeam(teamId),
  });

export const useAddTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: TeamAddRequest) => TeamService.addTeam(input),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: teamMemberQueryKeys.list() });
    },
  });
};

export const useEditTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: TeamEditRequest) => TeamService.editTeam(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(input.id) });
    },
  });
};

export const useRemoveTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamId: number) => TeamService.removeTeam(teamId),
    onSuccess: (_, teamId) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(teamId) });
      queryClient.invalidateQueries({ queryKey: applicationQueryKeys.list() });
    },
  });
};

export const useSyncTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamId: number) => TeamService.syncTeam(teamId),
    onSuccess: (_, teamId) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(teamId) });
      queryClient.invalidateQueries({ queryKey: applicationQueryKeys.list() });
    },
  });
};

export const useImportTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamJson: TeamImportRequest) =>
      TeamService.importTeam(teamJson),
    onSuccess: teamId => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(teamId) });
      queryClient.invalidateQueries({ queryKey: applicationQueryKeys.list() });
    },
  });
};

export default TeamService;
