import PropTypes from "prop-types";
import React from "react";
import ResourcesUtil from "src/ui/utils/ResourcesUtil";
import MiniCard from "src/ui/components/card/MiniCard";

function ResourceCard(props) {
  return (
    <MiniCard
      href={props.entryPointUrl}
      avatarSrc={ResourcesUtil.getAvatarPath(props.resourceType)}
      title={ResourcesUtil.getDisplayName(props.resourceType)}
    />
  );
}

ResourceCard.propTypes = {
  resourceType: PropTypes.string,
  entryPointUrl: PropTypes.string,
};

export default ResourceCard;
