import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import { components } from "react-select";
import * as React from "react";
import PropTypes from "prop-types";

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon
        sx={{
          color: "#5A6A72",
        }}
      />
    </components.DropdownIndicator>
  );
}

export function Option(props) {
  return (
    <components.Option {...props}>
      {props.isSelected && (
        <DoneIcon
          sx={
            props.theme.size === "medium"
              ? {
                  position: "absolute",
                  width: 16,
                  height: 16,
                  color: "#195EE6",
                  left: 10,
                  top: 8,
                }
              : {
                  position: "absolute",
                  width: 16,
                  height: 16,
                  color: "#195EE6",
                  left: 12,
                  top: 10,
                }
          }
        />
      )}
      {props.label}
    </components.Option>
  );
}
Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <ClearIcon
        sx={
          props.selectProps.size === "medium"
            ? {
                width: 12,
                height: 12,
              }
            : {
                width: 16,
                height: 16,
              }
        }
      />
    </components.MultiValueRemove>
  );
}

MultiValueRemove.propTypes = {
  selectProps: PropTypes.object,
};

export function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
}
