import PropTypes from "prop-types";
import React from "react";

class InputBase extends React.Component {
  setValue(value) {
    this.context.form.setValue(this.props.cursor, value);
  }

  getValue() {
    return this.context.form.getValue(this.props.cursor);
  }

  getError() {
    return this.context.form.getFieldError(this.props.cursor);
  }

  getFieldName() {
    return this.props.cursor; //TODO: to be removed
  }
}

InputBase.propTypes = {
  cursor: PropTypes.string,
};
InputBase.contextTypes = {
  form: PropTypes.object,
};

export default InputBase;
