import React from "react";
import Select, { GroupBase, Props as ReactSelectProps } from "react-select";
import { FormControl, InputLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { selectStyles } from "src/ui/components/select/SelectStyle";

export type SelectFieldProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  label: string;
  helperText?: React.ReactNode;
  required?: boolean;
  error?: boolean;
} & ReactSelectProps<Option, IsMulti, Group>;

export default function SelectField<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: SelectFieldProps<Option, IsMulti, Group>) {
  const { label, helperText, required, error, ...rest } = props;
  return (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...rest}
        styles={selectStyles}
        menuPortalTarget={document.body}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
