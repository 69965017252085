import PropTypes from "prop-types";
import React from "react";
import _, { flow } from "lodash";
import { Button } from "@chemaxon/design-system";
import MUIDataTable from "mui-datatables";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";
import Subheader from "src/ui/components/subheader/Subheader";
import withRouter from "src/ui/utils/WithRouter";
import { withTeamMembers } from "src/ui/utils/withTeamMembers";
import { withProjects } from "src/ui/utils/withProjects";

import "src/ui/views/projects/list/ListProjectsView.scss";
import { Restricted } from "src/ui/utils/Restricted";
import SynergyMUIDataTableSearchBox from "src/ui/components/mui-datatables-search-box/SynergyMUIDataTableSearchBox.jsx";
import { withCheckPermission } from "src/ui/utils/withCheckPermission";

class ListProjectsView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hasPermission = this.props.checkPermission(
      PlatformDomainObjectType.PROJECT,
      PlatformPermission.CREATE,
    );
    const createProjectButton = hasPermission ? (
      <Button
        colorVariant="primary"
        id="addProjectButton"
        onClick={() => {
          this.props.router.navigate("/project/add");
        }}
      >
        Create project
      </Button>
    ) : null;

    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.PROJECT,
            platformPermission: PlatformPermission.READ,
          }}
        >
          <Subheader title={"Projects"} button={createProjectButton} />
          <ViewContainer id={"listProjectsContainer"} sx={{ px: 0 }}>
            <ContainerWithLoaderAndError
              data={this.props.projects}
              content={projects => {
                const tableColumns = this.getTableColumns();
                const tableData = this.getTableData(projects);
                return (
                  <MUIDataTable
                    className="synergy-table"
                    columns={tableColumns}
                    data={tableData}
                    options={this.getMuiTableOptions()}
                  />
                );
              }}
            />
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }

  getMuiTableOptions() {
    return {
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <SynergyMUIDataTableSearchBox
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      },
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      onRowClick: this.editProject,
      elevation: 0,
      filter: false,
      viewColumns: false,
      sortOrder: {
        name: "ID",
        direction: "asc",
      },
    };
  }

  getTableColumns() {
    const columns = [];

    columns.push({
      name: "",
      options: {
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      },
    });
    columns.push({
      name: "ID",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 220,
            },
          };
        },
        customBodyRender: this.renderId,
      },
    });
    columns.push({
      name: "Title",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
        customBodyRender: this.renderTitle,
      },
    });
    columns.push({
      name: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: ListProjectsView.renderStatusCell,
      },
    });
    columns.push({
      name: "Assignee",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 220,
            },
          };
        },
        customBodyRender: this.renderAssignee,
      },
    });

    return columns;
  }

  getTableData(projects) {
    const rows = [];

    projects.forEach(project => {
      const cells = [];

      cells.push(project.id);
      cells.push(project.displayId);
      cells.push(project.title);
      cells.push(project.status);
      cells.push(
        this.mapAssigneeIdToName(this.props.teamMembers, project.assigneeId),
      );

      rows.push(cells);
    });

    return rows;
  }

  editProject = row => {
    this.props.router.navigate("/project/edit/" + row[0]);
  };

  mapAssigneeIdToName(teamMembers, assigneeId) {
    const assignee = _.find(
      teamMembers,
      teamMember => teamMember.id === assigneeId,
    );
    return assignee ? assignee.userName : "";
  }

  static renderStatusCell(status) {
    return (
      <div>
        <Chip
          label={status.toUpperCase()}
          style={{
            fontSize: "0.8em",
            color: "#FFFFFF",
            fontWeight: "500",
            backgroundColor: status === "closed" ? "#E0E0E0" : "#00BAB0",
          }}
        />
      </div>
    );
  }

  renderTitle = title => {
    return this.renderName(title, 40);
  };

  renderId = id => {
    return this.renderName(id, 25);
  };

  renderAssignee = assignee => {
    return this.renderName(assignee, 25);
  };

  renderName = (name, charLength) => {
    if (name && name.length > charLength) {
      let truncName = name.substring(0, charLength - 1) + "...";
      return (
        <Tooltip title={name}>
          <span>{truncName}</span>
        </Tooltip>
      );
    }
    return <span>{name}</span>;
  };
}

ListProjectsView.propTypes = {
  projects: PropTypes.array,
  teamMembers: PropTypes.array,
  router: PropTypes.object,
  checkPermission: PropTypes.func,
};

const enhance = flow(
  withProjects,
  withTeamMembers,
  withRouter,
  withCheckPermission,
);

export default enhance(ListProjectsView);
