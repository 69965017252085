import { SnackbarKey } from "notistack";
import Close from "@chemaxon/design-system/icons/Close";
import { IconButton } from "@chemaxon/design-system";
import * as React from "react";

export interface Props {
  closeSnackbar: (key?: SnackbarKey | undefined) => void;
}

export function onCloseAction(closeSnackbar: (key?: SnackbarKey) => void) {
  return (key: SnackbarKey | undefined) => (
    <SnackbarCloseButton closeSnackbar={() => closeSnackbar(key)} />
  );
}

export default function SnackbarCloseButton(props: Props) {
  return (
    <IconButton
      onClick={() => {
        props.closeSnackbar();
      }}
      aria-label={"close-snackbar"}
    >
      <Close />
    </IconButton>
  );
}
