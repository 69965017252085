import { useEffect } from "react";
import { useGetCurrentUser } from "src/ui/services/UserInfoService";

export const useFreshdesk = () => {
  const getCurrentUserQueryResult = useGetCurrentUser();

  const currentUser = getCurrentUserQueryResult.data;

  useEffect(() => {
    if (currentUser && window.FreshWidget) {
      window.FreshWidget.init("", {
        loadOnEvent: "immediate",
        queryString:
          "&helpdesk_ticket[requester]=" +
          currentUser.userName +
          "&widgetType=popup&formTitle=Questions%3F+Get+in+touch!&submitTitle=Send+message&submitThanks=Thank+you+for+your+message",
        utf8: "✓",
        widgetType: "popup",
        buttonType: "text",
        buttonText: "Questions? Get in touch!",
        buttonColor: "white",
        buttonBg: "#536172",
        alignment: "3",
        offset: "335px",
        submitThanks: "Thank you for your message",
        formHeight: "500px",
        url: "https://chemaxon.freshdesk.com",
      });
    }
  }, [currentUser]);
};

declare global {
  interface Window {
    FreshWidget: {
      init: (...args: unknown[]) => void;
    };
  }
}
