import {
  Chip,
  Secondary,
  Spacing1,
  Spacing2,
  Spacing3,
  Spacing5,
  TextField,
} from "@chemaxon/design-system";
import ArrowDown from "@chemaxon/design-system/icons/ArrowDown";
import { TeamMember } from "src/ui/models/TeamMember";
import { useState } from "react";
import { Autocomplete, Box } from "@mui/material";
import { Typography } from "@chemaxon/design-system/typography";
import { Outline, Surface } from "@chemaxon/design-system/color";

export default function GroupMemberSelector(props: Props) {
  const [groupMemberIds, setGroupMemberIds] = useState<number[]>(
    props.groupMemberIds,
  );

  const getMemberName = (id: number) => {
    const teamMember = props.teamMembers.find(tm => tm.id === id);

    return teamMember ? teamMember.userName : "";
  };

  const handleDelete = (memberId: number) => {
    setGroupMemberIds((groupMemberIds: number[]) =>
      groupMemberIds.filter(id => id !== memberId),
    );
    props.onRemove(memberId);
  };

  const addToGroup = (memberId: number) => {
    setGroupMemberIds(groupMemberIds => [...groupMemberIds, memberId]);
    props.onAdd(memberId);
  };

  return (
    <>
      <Autocomplete
        id="teamMemberSelector"
        options={props.teamMembers
          .filter(tm => !groupMemberIds.includes(tm.id))
          .map(tm => ({ value: tm.id, label: tm.userName }))}
        onChange={(_, value) => addToGroup(value.value)}
        popupIcon={<ArrowDown />}
        disableClearable
        renderInput={params => (
          <TextField
            {...params}
            label="Add members to the group"
            placeholder="Type to search"
            size={"medium"}
            InputProps={{
              ...params.InputProps,
              sx: {
                border: "1px solid",
                borderColor: Outline.default,
                borderRadius: 1,
                padding: `${Spacing3} ${Spacing3} ${Spacing3} ${Spacing5}`,
              },
            }}
            error={props.error}
            helperText={props.helperText}
          />
        )}
        renderTags={() => null}
      />
      {groupMemberIds.length != 0 && (
        <>
          <Typography variant={"label2"}>
            Team members added to the group
          </Typography>
          <Box
            sx={{
              gap: Spacing3,
              marginTop: Spacing2,
              border: "1px solid",
              borderColor: Outline.default,
              borderRadius: 1,
              backgroundColor: Surface.variant,
              padding: `${Spacing3} ${Spacing3} ${Spacing3} ${Spacing5}`,
              "& > :not(:last-child)": { marginRight: 1 },
              "& > *": { marginBottom: 1 },
            }}
          >
            {groupMemberIds.map(option => (
              <Chip
                key={"groupMember_" + option}
                size={"small"}
                unselectable={"on"}
                sx={{
                  backgroundColor: Secondary.container,
                  color: Secondary.containerText,
                  "& .MuiChip-deleteIcon": {
                    backgroundColor: Secondary.container,
                    color: Secondary.containerText,
                    padding: Spacing1,
                  },
                }}
                label={getMemberName(option)}
                onDelete={() => handleDelete(option)}
              />
            ))}
          </Box>
        </>
      )}
    </>
  );
}

type Props = {
  groupMemberIds: number[];
  teamMembers: TeamMember[];
  onAdd: (value: number) => void;
  onRemove: (value: number) => void;
  error: string;
  helperText: string;
};
