import React from "react";

import { usePermissions } from "src/ui/utils/usePermissions";

export const withCheckPermission = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithCheckPermission = (props: TProps) => {
    const { checkPermission } = usePermissions();

    return <WrappedComponent {...props} checkPermission={checkPermission} />;
  };

  ComponentWithCheckPermission.displayName = `WithCheckPermission(${displayName})`;

  return ComponentWithCheckPermission;
};
