import PropTypes from "prop-types";

import React from "react";
import InputBase from "../base/InputBase";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EventIcon from "@mui/icons-material/Event";
import moment from "moment";

/*
 * Offset in minutes for UTC-12.
 * By using the end of the day in UTC-12 for expiration dates, we ensure that expiration won't happen prematurely in any
 * timezone, since all other timezones are ahead of it.
 */
export const UTC_OFFSET = -12 * 60;

class DatePicker extends InputBase {
  getSelectedValue() {
    const storedValue = this.getValue();
    const endOfCurrentDay = moment().utcOffset(UTC_OFFSET).endOf("day");
    return storedValue
      ? moment(storedValue).utcOffset(UTC_OFFSET).format("YYYY-MM-DD")
      : endOfCurrentDay;
  }

  render() {
    return (
      <div className="synergy-form-control-container">
        <MuiDatePicker
          {...this.props}
          value={this.getSelectedValue()}
          format="YYYY-MM-DD"
          onChange={date => {
            if (this.props.onChange) {
              this.props.onChange(event);
            }
            let endOfSelectedDayAtUtcOffset = date
              .endOf("day")
              .subtract(UTC_OFFSET, "minutes");
            this.setValue(endOfSelectedDayAtUtcOffset.toISOString());
          }}
          id={this.getFieldName()}
          disabled={this.props.readOnly ? true : false}
          margin="normal"
          fullWidth
          keyboard
          leftArrowIcon={<KeyboardArrowLeftIcon />}
          rightArrowIcon={<KeyboardArrowRightIcon />}
          keyboardIcon={<EventIcon />}
        />
      </div>
    );
  }
}

DatePicker.UTC_OFFSET = UTC_OFFSET;

DatePicker.propTypes = {
  updateSynergyFormGroup: PropTypes.func,
};
export default DatePicker;
