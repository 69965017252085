import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Api from "src/ui/services/Api";
import { Group, GroupAddRequest, GroupEditRequest } from "src/ui/models/Group";
import type { QueryKeysDefinition } from "src/types/queryKeys";
import { queryKeys as userInfoQueryKeys } from "src/ui/services/UserInfoService";

export const SERVICE_URL = "/internal/groups";

class GroupService {
  /**
   * Get list of registered groups.
   */
  static getGroups(): Promise<Group[]> {
    return Api.get(SERVICE_URL);
  }

  /**
   * Get group details.
   */
  static getGroup(groupId: number): Promise<Group> {
    return Api.get(`${SERVICE_URL}/${groupId}`);
  }

  /**
   * Add new group.
   */
  static addGroup(input: GroupAddRequest): Promise<number> {
    return Api.post(SERVICE_URL, input);
  }

  /**
   * Edit existing group.
   */
  static editGroup(input: GroupEditRequest): Promise<void> {
    return Api.put(SERVICE_URL, input);
  }

  /**
   * Remove group.
   */
  static removeGroup(groupId: number): Promise<void> {
    return Api.delete(`${SERVICE_URL}/${groupId}`);
  }
}

const QUERY_KEYS_NAMESPACE = "groups";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
  details: () => [QUERY_KEYS_NAMESPACE, "detail"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
  detail: (groupId: number) => [...baseQueryKeys.details(), groupId] as const,
} satisfies QueryKeysDefinition;

export const useGetGroups = () =>
  useQuery({
    queryKey: queryKeys.list(),
    queryFn: () => GroupService.getGroups(),
  });

export const useGetGroup = (groupId: number) =>
  useQuery({
    queryKey: queryKeys.detail(groupId),
    queryFn: () => GroupService.getGroup(groupId),
  });

export const useAddGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: GroupAddRequest) => GroupService.addGroup(input),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: userInfoQueryKeys.me(),
      });
    },
  });
};

export const useEditGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: GroupEditRequest) => GroupService.editGroup(input),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(variables.id),
      });
      queryClient.invalidateQueries({
        queryKey: userInfoQueryKeys.me(),
      });
    },
  });
};

export const useRemoveGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (groupId: number) => GroupService.removeGroup(groupId),
    onSuccess: (_data, groupId) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(groupId),
      });
      queryClient.invalidateQueries({
        queryKey: userInfoQueryKeys.me(),
      });
    },
  });
};

export default GroupService;
