import { Content } from "@chemaxon/design-system/color";
import Sync from "@chemaxon/design-system/icons/Sync";
import MoreMenuListButton from "src/ui/components/more-menu/MoreMenuListButton";

export default function SyncButton(props: Props) {
  return (
    <MoreMenuListButton
      onClick={props.onClick}
      icon={<Sync />}
      color={Content.default}
      text="Sync"
    />
  );
}

type Props = {
  onClick: () => void;
};
