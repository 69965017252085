import React from "react";
import PropTypes from "prop-types";
import { Avatar, Typography } from "@chemaxon/design-system";
import { CardActionArea, CardHeader } from "@mui/material";
import { BaseCard } from "src/ui/components/card/BaseCard";

const MiniCard = ({ href, avatarSrc, title, subheader }) => {
  return (
    <BaseCard>
      <CardActionArea href={href} target="_blank">
        <CardHeader
          avatar={
            <Avatar
              variant="square"
              size="md"
              src={avatarSrc}
              sx={{ backgroundColor: "transparent" }}
              imgProps={{
                sx: {
                  objectFit: "contain",
                },
              }}
            />
          }
          title={<Typography variant="label1">{title}</Typography>}
          subheader={
            subheader && <Typography variant="body2">{subheader}</Typography>
          }
          disableTypography
        />
      </CardActionArea>
    </BaseCard>
  );
};

MiniCard.propTypes = {
  href: PropTypes.string,
  avatarSrc: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default MiniCard;
