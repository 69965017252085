import Dialog from "src/ui/components/dialog/Dialog";
import { CornerExtraLarge } from "@chemaxon/design-system";
import GroupEditor from "src/ui/components/groups/GroupEditor";

export default function AddGroup(props: Props) {
  return (
    <Dialog
      title="Create new group"
      open={props.isShown}
      content={<GroupEditor onCancel={props.onCancel} />}
      onClose={props.onCancel}
      divider={false}
      borderRadius={CornerExtraLarge.borderRadius}
      maxWidth="md"
    />
  );
}

type Props = {
  isShown: boolean;
  onCancel: () => void;
};
