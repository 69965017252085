import * as React from "react";
import { useState } from "react";
import { TextField } from "@chemaxon/design-system";
import classnames from "classnames";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PropTypes from "prop-types";
import { classes, Root } from "src/ui/components/Number/NumberStyle.js";

CustomMuiNumber.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  classes: PropTypes.any,
  inputProps: PropTypes.any,
  InputProps: PropTypes.any,
  onChange: PropTypes.func,
  valueChangeHandler: PropTypes.func,
};

export default function CustomMuiNumber(props) {
  const [inputValue, setInputValue] = useState(props.InputProps.value);

  const increase = () => {
    if (!props.disabled) {
      const value = Number(inputValue) + 1;
      props.valueChangeHandler(value);
      setInputValue(value);
    }
  };

  const decrease = () => {
    if (!props.disabled) {
      let value = Number(inputValue) - 1;
      props.valueChangeHandler(value);
      setInputValue(value);
    }
  };

  const change = e => {
    if (!props.disabled) {
      setInputValue(e.target.value);
      props.onChange(e);
    }
  };

  const disabledIconClass = props.disabled ? classes.disabledIcon : "";

  const rootClass = classnames(
    classes.input,
    classes[props.size],
    (props.classes || {}).root,
  );

  const inputClass = classnames(classes.number, (props.classes || {}).input);

  const focusedClass = classnames(
    classes.focused,
    (props.classes || {}).focused,
  );

  const disabledClass = classnames(
    classes.disabled,
    (props.classes || {}).disabled,
  );

  const errorClass = classnames(classes.error, (props.classes || {}).error);

  const inputElementClass = classnames(
    classes.inputElement,
    classes.number,
    (props.inputProps || {}).className,
  );

  const {
    // eslint-disable-next-line no-unused-vars
    valueChangeHandler,
    inputProps,
    ...propsToBeSpread
  } = props;

  return (
    <Root className={classes.numberContainer}>
      <TextField
        {...propsToBeSpread}
        type="number"
        classes={{
          ...props.classes,
          root: rootClass,
          input: inputClass,
          focused: focusedClass,
          disabled: disabledClass,
          error: errorClass,
        }}
        value={inputValue}
        onChange={change}
        inputProps={{
          ...inputProps,
          className: inputElementClass,
        }}
      />

      <figure
        className={`${classes.plus} ${
          classes[`${props.size}IconContainer`]
        } ${disabledIconClass}`}
      >
        <ArrowDropUpIcon className={classes.icon} onClick={increase} />
      </figure>

      <figure
        className={`${classes.minus} ${
          classes[`${props.size}IconContainer`]
        } ${disabledIconClass}`}
      >
        <ArrowDropDownIcon className={classes.icon} onClick={decrease} />
      </figure>
    </Root>
  );
}
