import PropTypes from "prop-types";
import React from "react";

import "src/ui/components/modal-overlay/ModalOverlay.scss";

class ModalOverlay extends React.Component {
  render() {
    return (
      <div
        className="modal-overlay"
        onClick={this.props.onClick}
        data-class="modalOverlay"
      >
        {this.props.children}
      </div>
    );
  }
}

ModalOverlay.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

export default ModalOverlay;
