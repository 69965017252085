import { queryOptions, useQuery } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import { UserInfoResponse } from "src/ui/models/UserInfo";

export const SERVICE_URL = "/resources/me";

class UserInfoService {
  static getMe(): Promise<UserInfoResponse> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "userinfo";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  me: () => [QUERY_KEYS_NAMESPACE, "me"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const getMeQueryOptions = <TData = UserInfoResponse>(
  select?: (data: UserInfoResponse) => TData,
) =>
  queryOptions({
    queryKey: queryKeys.me(),
    queryFn: () => UserInfoService.getMe(),
    select,
  });

export const useGetMeQuery = <TData = UserInfoResponse>(
  select?: (data: UserInfoResponse) => TData,
) => useQuery(getMeQueryOptions(select));

export const useGetCurrentUser = () =>
  useGetMeQuery(data => data.principal.user);

export const useGetCurrentTeam = () =>
  useGetMeQuery(data => data.principal.team);

export const useGetCurrentGroups = () =>
  useGetMeQuery(data => data.principal.groups);

export const useAuthentication = () => {
  const me = useGetMeQuery();
  return useQuery({
    queryKey: ["auth"],
    queryFn: () => {
      return Boolean(me.data);
    },
    enabled: Boolean(me.data),
  });
};

export default UserInfoService;
