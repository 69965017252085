const colors = {
  action: "#4881D7",
  actionDark: "#2966C3",
  actionLight: "#74A3EB",
  success: "#7CC88D",
  successDark: "#1D9A8B",
  attention: "#E5E561",
  danger: "#DF4A5F",
  dangerDark: "#C3344A",
  black: "#1F1f31",
  white: "#FFFFFF",

  gray00: "#363641",
  gray01: "#6D7B7F",
  gray02: "#A8B0B3",
  gray03: "#D1D3D3",
  gray04: "#E2E5E6",
  gray05: "#F7F7F8",

  psp01: "#536172",
  psp02: "#70808E",
  pspAction: "#5E79A2",
  gray045: "#EEEEEF",
  fontPrimary: "#576164",
  fontSecondary: "#818A8D",
  fontDisabled: "#D0D6D7",

  transparent: "transparent",
};

const fontSizes = {
  xxxxl: 24,
  xxxl: 20,
  xxl: 18,
  xl: 14,
  l: 13,
  m: 12,
  s: 11,
  none: 0,
};

const fontFamilies = {
  arial: "Arial",
  helvetica: "Helvetica",
};

const fontWeights = {
  regular: 400,
  bold: 800,
  light: 200,
};

const fontStyles = {
  regular: "normal",
  italic: "italic",
};

const textDecoration = {
  none: "none",
};

const button = {
  width: 75 + "px",
  height: 30 + "px",
  lineHeight: 30 + "px",
  border: 0,
  cursor: "pointer",
  margin: "0 3px",
};

const input = {
  borderWidth: "0 0 1px 0",
  borderStyle: "solid",
  borderColor: colors.gray03,
  padding: "5px 10px",
  width: "100%",
  color: colors.gray01,
};

const config = {
  colors: colors,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  fontFamilies: fontFamilies,
  fontStyles: fontStyles,
  button: button,
  actionButton: {
    width: button.width,
    height: button.height,
    lineHeight: button.lineHeight,
    margin: button.margin,
    border: button.border,
    cursor: button.cursor,
    color: colors.white,
    backgroundColor: colors.pspAction,
  },
  removeButton: {
    height: 1.5 * fontSizes.xxxl + "px",
    lineHeight: 1.5 * fontSizes.xxxl + "px",
    border: button.border,
    cursor: button.cursor,
    backgroundColor: colors.transparent,
    padding: "1px 6px 1px 6px",
    textDecoration: textDecoration.none,
    color: colors.danger,
    float: "right",
  },
  noButton: {
    width: button.width,
    height: button.height,
    lineHeight: button.lineHeight,
    margin: button.margin,
    border: button.border,
    cursor: button.cursor,
    backgroundColor: colors.transparent,
    padding: "1px 6px 1px 6px",
    textDecoration: textDecoration.none,
    color: colors.gray02,
  },
  input: input,
  inputError: {
    borderWidth: input.borderWidth,
    borderStyle: input.borderStyle,
    borderColor: colors.danger,
    padding: input.padding,
    width: input.width,
  },
  errorMessage: {
    color: colors.white,
    backgroundColor: colors.danger,
    borderWidth: input.borderWidth,
    borderStyle: input.borderStyle,
    borderColor: colors.danger,
    padding: input.padding,
    width: input.width,
  },
  sidePanel: {
    width: 220,
    backgroundColor: colors.psp01,
    item: {
      color: colors.white,
      fontFamily: fontFamilies.arial,
      selected: {
        backgroundColor: colors.psp02,
      },
    },
  },
  appsPanel: {
    item: {
      color: colors.gray01,
    },
    backgroundColor: colors.white,
  },
  topMenuBar: {
    height: 62,
    synergyIcon: {
      color: colors.gray01,
    },
    hamburgerIcon: {
      color: colors.gray01,
      selected: {
        color: colors.psp01,
      },
    },
    appsIcon: {
      color: colors.gray01,
      selected: {
        color: colors.white,
      },
    },
  },
  app: {
    backgroundColor: colors.gray04,
    color: colors.black,
    contentArea: {
      backgroundColor: colors.white,
      fontFamily: fontFamilies.arial,
      fontSize: fontSizes.xxxl,
    },
  },
  responsiveBreakpoints: [
    { name: "desktop", minWidth: 1200, numOfDashboardColumns: 12 },
    { name: "tablet", minWidth: 768, numOfDashboardColumns: 6 },
    { name: "mobile", minWidth: 0, numOfDashboardColumns: 1 },
  ],
  h15: {
    fontSize: fontSizes.xxxl + "px",
    lineHeight: fontSizes.xxxl + "px",
    color: colors.fontPrimary,
  },
};

export default config;
