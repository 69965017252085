import MoreMenuListButton from "src/ui/components/more-menu/MoreMenuListButton";
import Edit from "@chemaxon/design-system/icons/Edit";
import { Content } from "@chemaxon/design-system/color";

export default function ManageButton(props: Props) {
  return (
    <MoreMenuListButton
      onClick={props.onClick}
      icon={<Edit />}
      color={Content.default}
      text="Manage"
    />
  );
}

type Props = {
  onClick: () => void;
};
