import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Outline } from "@chemaxon/design-system/color";
import { Surface } from "@chemaxon/design-system/color";
import { IconButton } from "@chemaxon/design-system";

import { CornerLarge } from "@chemaxon/design-system";
import { ElevationBelowXs } from "@chemaxon/design-system";
import { useEffect, useState } from "react";

export interface MoreMenuOption {
  key: string;
  option: React.ReactNode;
  loading: boolean;
}
interface Props {
  id: number;
  options: MoreMenuOption[];
}

export default function MoreMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLElement | undefined | null
  >(null);
  const [selected, setSelected] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(props.options.some(option => option.loading));
  }, [...props.options.map(option => option.loading)]);

  const open = Boolean(anchorEl);
  const iconButtonId = props.id + "_more-menu-icon-button";
  const menuId = props.id + "_more-menu-id";
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!pending) {
      setAnchorEl(event.currentTarget);
      setSelected(curr => !curr);
    }
  };
  const handleClose = (event: React.UIEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelected(false);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id={iconButtonId}
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={e => {
          e.stopPropagation();
        }}
        selected={selected}
        variant="text"
        loading={pending}
        aria-disabled={pending}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={menuId}
        MenuListProps={{
          "aria-labelledby": iconButtonId,
          sx: {
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            padding: "0px",
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: "160px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: CornerLarge.borderRadius,
              border: `1px solid var(--Border-Border-variant-main, ${Outline.variant})`,
              background: `var(--Surface-main, ${Surface.default})`,
              boxShadow: ElevationBelowXs.boxShadow,
              padding: "8px 1px",
            },
          },
        }}
      >
        {props.options?.map(option => (
          <MenuItem
            component="div"
            key={option.key}
            onClick={handleClose}
            sx={{
              padding: "0px",
            }}
          >
            {option.option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
