import "./login.css";
import "./background.svg";

const Login = () => {
  const teamSubdomain = window.location.hostname.split(".")[0];
  const targetPath = window.location.pathname;
  return (
    <>
      <div id="background">
        <div id="welcome-message">Welcome to Chemaxon Cloud</div>
        <div>
          <div id="login" className="button">
            <a
              href={`/oauth2/authorization/${teamSubdomain}?targetPath=${targetPath}`}
            >
              Log in
            </a>
          </div>
        </div>
      </div>
      <div id="features">
        <div>
          <div className="title">Manage</div>
          <div className="description">
            Manage your users, applications, and data from a single platform
          </div>
        </div>
        <div>
          <div className="title">Collaborate</div>
          <div className="description">
            Collaborate with colleagues from other teams and contract research
            organizations smoothly
          </div>
        </div>
        <div>
          <div className="title">Design</div>
          <div className="description">
            Design new ideas, drug molecules and compound libraries faster by
            accessing all your research data from a single entry point
          </div>
        </div>
        <div>
          <div className="title">Research</div>
          <div className="description">
            Empower your research with query, analysis and library design tools
          </div>
        </div>
        <div>
          <div className="title">Enjoy</div>
          <div className="description">
            Focus on the fun part of your work and forget about tedious data
            conversion and file sharing by using a set of integrated chemical
            and biological applications
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
