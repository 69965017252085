import Api, { ApiWithoutSessionHandling } from "src/ui/services/Api";

const SERVICE_URL = "/internal/notifications";

class NotificationSettingsService {
  /**
   * Get the list of notification settings for the user.
   */
  static getNotificationSettings() {
    return Api.get(SERVICE_URL + "/notification-settings");
  }
  /**
   * Update notification settings for the user.
   */
  static updateNotificationSettings(unsubscribedList) {
    return Api.put(SERVICE_URL + "/notification-settings", unsubscribedList);
  }

  static unsubscribe(uid, notificationType) {
    return ApiWithoutSessionHandling.delete(
      SERVICE_URL +
        `/unsubscribe?uid=${uid}&notificationType=${notificationType}`,
    );
  }
}

export default NotificationSettingsService;
