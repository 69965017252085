//import log from "loglevel";
import { ApiWithoutSessionHandling as Api } from "src/ui/services/Api";

class ProductDataService {
  /**
   * Get the list of applications available as products to sale.
   */
  static getApplications() {
    return Api.get("/public/product-data/applications");
  }
  /**
   * Get the list of solutions available for sale.
   */
  static getSolutions() {
    return Api.get("/public/product-data/solutions");
  }
  /**
   * Get the price discounts for products.
   */
  static getPriceDiscount() {
    return Api.get("/public/product-data/price-discount");
  }
  /**
   * Get the price constant.
   */
  static getPriceConstant() {
    return Api.get("/public/product-data/price-constant");
  }
}

export default ProductDataService;
