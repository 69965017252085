import React from "react";

import { useGetTeamMembers } from "src/ui/services/TeamMemberService";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";

export const withTeamMembers = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTeamMembers = (props: TProps) => {
    const getTeamMembersQueryResult = useGetTeamMembers();

    const teamMembers = adaptUseQueryResponseForContainerWithLoader(
      getTeamMembersQueryResult,
    );

    return <WrappedComponent {...props} teamMembers={teamMembers} />;
  };

  ComponentWithTeamMembers.displayName = `WithTeamMembers(${displayName})`;

  return ComponentWithTeamMembers;
};
