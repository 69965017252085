import React from "react";

import {
  useGetCurrentGroups,
  useGetCurrentTeam,
  useGetCurrentUser,
} from "src/ui/services/UserInfoService";

export const withUserInfo = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithUserInfo = (props: TProps) => {
    const getCurrentTeamQueryResult = useGetCurrentTeam();
    const getCurrentGroupsQueryResult = useGetCurrentGroups();
    const getCurrentUserQueryResult = useGetCurrentUser();

    const userInfo = {
      currentTeam: getCurrentTeamQueryResult.data,
      currentGroups: getCurrentGroupsQueryResult.data,
      currentUser: getCurrentUserQueryResult.data,
    };

    return <WrappedComponent {...props} {...userInfo} />;
  };

  ComponentWithUserInfo.displayName = `WithTeams(${displayName})`;

  return ComponentWithUserInfo;
};
