import { createTheme } from "@mui/material/styles";
import { MuiFormElementOverrides } from "src/ui/utils/MuiFormElementOverrides";

const theme = createTheme({
  palette: {
    primary: {
      // currently we only use a single shade
      50: "#00bab0",
      100: "#00bab0",
      200: "#00bab0",
      300: "#00bab0",
      400: "#00bab0",
      500: "#00bab0",
      600: "#00bab0",
      700: "#00bab0",
      800: "#00bab0",
      900: "#00bab0",
      A100: "#00bab0",
      A200: "#00bab0",
      A400: "#00bab0",
      A700: "#00bab0",
      contrastText: "#fff",
      contrastDefaultColor: "light",
    },
    secondary: {
      50: "#f5a623",
      100: "#f5a623",
      200: "#f5a623",
      300: "#f5a623",
      400: "#f5a623",
      500: "#f5a623",
      600: "#f5a623",
      700: "#f5a623",
      800: "#f5a623",
      900: "#f5a623",
      A100: "#f5a623",
      A200: "#f5a623",
      A400: "#f5a623",
      A700: "#f5a623",
      contrastText: "#fff",
      contrastDefaultColor: "light",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          color: "white" + " !important",
        },
      },
    },
    ...MuiFormElementOverrides,
  },
});

export default theme;
