import Dialog from "src/ui/components/dialog/Dialog";
import { Group } from "src/ui/models/Group";
import { CornerExtraLarge } from "@chemaxon/design-system";
import GroupEditor from "src/ui/components/groups/GroupEditor";

export default function EditGroup(props: Props) {
  if (props.group === null) {
    return;
  }

  return (
    <Dialog
      title="Manage group"
      open={props.isShown}
      content={<GroupEditor group={props.group} onCancel={props.onCancel} />}
      onClose={props.onCancel}
      divider={false}
      borderRadius={CornerExtraLarge.borderRadius}
      maxWidth="md"
    />
  );
}

type Props = {
  isShown: boolean;
  onCancel: () => void;
  group: Group | null;
};
