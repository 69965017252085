import React from "react";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import ApplicationList from "src/ui/components/applications/ApplicationList";
import Subheader from "src/ui/components/subheader/Subheader";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";

class ListApplicationsView extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.APPLICATION,
            platformPermission: PlatformPermission.WRITE,
          }}
        >
          <Subheader title={"Applications"} />
          <ViewContainer id={"listApplicationsContainer"} sx={{ px: 0 }}>
            <ApplicationList />
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }
}
export default ListApplicationsView;
