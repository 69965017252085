/*
 * REST api error that contains a json body
 * json data can contain form filed validation details in 'data.fielderrors'
 */

class SynergySpringMVCError extends Error {
  public data: Data;
  public response: Response | undefined;
  public getFieldErrors: () => FieldError[];

  constructor(data: Data, response?: Response) {
    super(data.message || "Spring MVC Error");
    this.data = data;
    this.response = response; // fetch() adds response to Error object this way,
    // this makes SynergySpringMVCError compatible with errors thrown by fetch()
    this.getFieldErrors = () =>
      this.data.fieldErrors && this.data.fieldErrors.length
        ? this.data.fieldErrors
        : [];

    Object.setPrototypeOf(this, SynergySpringMVCError.prototype);
  }
}

export default SynergySpringMVCError;

type Data = {
  message: string;
  fieldErrors?: FieldError[];
};

export type FieldError = {
  field: string;
  message: string;
};
