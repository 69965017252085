import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { Stack } from "@mui/material";
import { Button, TextField } from "@chemaxon/design-system";
import _ from "lodash";
import log from "loglevel";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { useEditProject, useGetProject } from "src/ui/services/ProjectService";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import MemberList from "src/ui/components/team-members/MemberList";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import SelectField from "src/ui/components/select/Select";
import {
  ClearIndicator,
  DropdownIndicator,
  Option,
} from "src/ui/components/select/SelectPartials";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormActions from "src/ui/components/form/FormActions";
import FormSectionTitle from "src/ui/components/form/FormSectionTitle";
import { Restricted } from "src/ui/utils/Restricted";
import {
  useGetCurrentTeam,
  useGetCurrentUser,
} from "src/ui/services/UserInfoService";
import { useGetTeamMembers } from "src/ui/services/TeamMemberService";
import { usePermissions } from "src/ui/utils/usePermissions";
import {
  FormData,
  FormErrors,
  FormField,
  helperTexts,
} from "src/ui/views/projects/ProjectEditorFields";
import {
  ProjectEditRequest,
  ProjectGetResponse,
  ProjectStatus,
} from "src/ui/models/Project";
import { AppRoutePath } from "src/ui/utils/routes";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";
import { TeamMember } from "src/ui/models/TeamMember";
import RemoveMemberButton from "src/ui/components/removeMemberButton/RemoveMemberButton";

type EditProjectViewParams = {
  projectId: string;
};

type MemberOptionType = {
  label: string;
  value: number;
};

type ProjectStatusOptionType = {
  label: string;
  value: ProjectStatus;
};

const getBackendFormData = (project: ProjectGetResponse): FormData => ({
  [FormField.id]: project.id,
  [FormField.displayId]: project.displayId,
  [FormField.title]: project.title,
  [FormField.externalId]: project.externalId,
  [FormField.assigneeId]: project.assigneeId,
  [FormField.description]: project.description,
  [FormField.conclusion]: project.conclusion,
  [FormField.status]: project.status,
  [FormField.memberIds]: [...project.memberIds],
  [FormField.newMemberId]: undefined,
});

export default function EditProjectView() {
  const navigate = useNavigate();
  const { checkPermission } = usePermissions();
  const { projectId } = useParams<EditProjectViewParams>();

  const getCurrentUserQueryResult = useGetCurrentUser();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getTeamMembersQueryResult = useGetTeamMembers();
  const getProjectQueryResult = useGetProject(Number(projectId));

  const editProjectQueryResult = useEditProject();
  const [formData, setFormData] = useState<FormData | null>(null);

  const [formErrors, setFormErrors] = useState<FormErrors>({
    [FormField.id]: "",
    [FormField.displayId]: "",
    [FormField.title]: "",
    [FormField.externalId]: "",
    [FormField.assigneeId]: "",
    [FormField.description]: "",
    [FormField.conclusion]: "",
    [FormField.status]: "",
    [FormField.memberIds]: "",
    [FormField.newMemberId]: "",
  });

  useEffect(() => {
    if (formData || !getProjectQueryResult.data) {
      return;
    }

    const newData = {
      ...getBackendFormData(getProjectQueryResult.data),
    } satisfies Partial<FormData>;

    setFormData(newData);
  }, [formData, getProjectQueryResult.data]);

  if (
    getProjectQueryResult.isPending ||
    getTeamMembersQueryResult.isPending ||
    getCurrentUserQueryResult.isPending ||
    getCurrentTeamQueryResult.isPending ||
    !formData
  ) {
    return <div>Loading...</div>;
  }

  if (
    getProjectQueryResult.isError ||
    getTeamMembersQueryResult.isError ||
    getCurrentUserQueryResult.isError ||
    getCurrentTeamQueryResult.isError
  ) {
    return (
      <>
        <div>This project cannot be viewed</div>
        <div>
          It may have been deleted or you do not have permission to view it.
        </div>
      </>
    );
  }

  const project = getProjectQueryResult.data;
  const currentUser = getCurrentUserQueryResult.data;
  const currentTeam = getCurrentTeamQueryResult.data;
  const teamMembers = getTeamMembersQueryResult.data;

  const backendFormData: FormData = getBackendFormData(project);

  const isFormFieldModified = (fieldName: FormField) =>
    !_.isEqual(backendFormData[fieldName], formData[fieldName]);

  const isProjectModified = () => {
    return Boolean(
      isFormFieldModified(FormField.displayId) ||
        isFormFieldModified(FormField.title) ||
        isFormFieldModified(FormField.externalId) ||
        isFormFieldModified(FormField.assigneeId) ||
        isFormFieldModified(FormField.description) ||
        isFormFieldModified(FormField.conclusion) ||
        isFormFieldModified(FormField.status) ||
        isFormFieldModified(FormField.memberIds),
    );
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const projectData = {
      ...project,
      ...formData,
      teamId: currentTeam.id,
    } satisfies ProjectEditRequest;

    editProjectQueryResult
      .mutateAsync(projectData)
      .then(() => {
        navigate(AppRoutePath.ProjectList);
      })
      .catch(error => {
        if (error instanceof SynergySpringMVCError) {
          const newFormErrors: FormErrors = { ...formErrors };

          error.getFieldErrors().forEach(e => {
            newFormErrors[e.field as FormField] = e.message;
          });

          setFormErrors(newFormErrors);
        } else {
          log.error("Failed to save changes of the project.", error);
        }
      });
  };

  const handleAddNewMember = (event: React.FormEvent) => {
    event.preventDefault();

    if (!formData[FormField.newMemberId]) {
      return;
    }

    let newMemberIds: number[] = [];

    if (formData[FormField.memberIds]) {
      newMemberIds = newMemberIds.concat([...formData[FormField.memberIds]]);
    }

    newMemberIds.push(formData[FormField.newMemberId]);

    const newFormData = {
      ...formData,
      [FormField.memberIds]: newMemberIds,
      [FormField.newMemberId]: undefined,
    };

    setFormData(newFormData);
  };

  const handleNewMemberChange = (newValue: SingleValue<MemberOptionType>) => {
    if (newValue === null) {
      return;
    }

    setFormData({
      ...formData,
      [FormField.newMemberId]: newValue.value,
    });
  };

  const handleRemoveMember = (idToRemove: number) => {
    if (!formData[FormField.memberIds]) {
      return;
    }

    const newFormData = {
      ...formData,
      [FormField.memberIds]: formData[FormField.memberIds].filter(
        item => item !== idToRemove,
      ),
    };

    setFormData(newFormData);
  };

  const mapTeamMembersToOptions = (
    teamMembers: TeamMember[],
  ): MemberOptionType[] => {
    return teamMembers.map(member => {
      return { label: member.userName, value: member.id };
    });
  };

  const getUserName = (userId: number | null, teamMembers: TeamMember[]) => {
    if (!userId || !teamMembers) {
      return "";
    }
    const user = teamMembers.find(member => member.id === userId);
    return user ? user.userName : "";
  };

  const handleAssigneeChange = (newValue: SingleValue<MemberOptionType>) => {
    const newFormData = {
      ...formData,
      [FormField.assigneeId]: newValue ? newValue.value : 0,
    };
    setFormData(newFormData);
  };

  const handleChange =
    (
      fieldName: FormField,
    ): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    event => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.value,
      };
      setFormData(newFormData);

      // clearing potentially shown error message under the field
      const newFormErrors = {
        ...formErrors,
        [fieldName]: "",
      };
      setFormErrors(newFormErrors);
    };

  const handleStatusChange = (
    newValue: SingleValue<ProjectStatusOptionType>,
  ) => {
    if (newValue === null) {
      return;
    }

    const newFormData = {
      ...formData,
      [FormField.status]: newValue.value,
    };
    setFormData(newFormData);

    const newFormErrors = {
      ...formErrors,
      [FormField.status]: "",
    };
    setFormErrors(newFormErrors);
  };

  const handleCancelButton = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(-1);
  };

  const isInErrorState = (field: FormField) => {
    return !!(formErrors[field] || "");
  };

  const getShownText = (field: FormField) => {
    return isInErrorState(field) ? formErrors[field] : helperTexts[field];
  };

  const getGeneralInputProps = (field: FormField) => {
    return {
      name: field,
      value: formData[field],
      autoComplete: "off",
      id: field,
    };
  };

  const getGeneralProps = (field: FormField) => {
    return {
      className: isInErrorState(field) ? "error" : "",
      helperText: (
        <HelperTextTypography
          text={getShownText(field)}
          isError={isInErrorState(field)}
        />
      ),
      onChange: handleChange(field),
      error: isInErrorState(field),
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderRemoveButton = (teamMember: TeamMember) => (
    <RemoveMemberButton
      hasPermission={checkPermission(
        PlatformDomainObjectType.PROJECT,
        PlatformPermission.WRITE,
      )}
      onClick={() => handleRemoveMember(teamMember.id)}
    />
  );

  const hasPermission =
    checkPermission(
      PlatformDomainObjectType.PROJECT,
      PlatformPermission.CREATE,
    ) || currentUser.id === backendFormData[FormField.assigneeId];

  const assigneeUserName = getUserName(
    formData[FormField.assigneeId],
    teamMembers,
  );

  const projectMemberList = teamMembers.filter(member =>
    formData[FormField.memberIds].includes(member.id),
  );

  const projectNotMemberList = teamMembers.filter(
    member => !formData[FormField.memberIds].includes(member.id),
  );

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.PROJECT,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader
          title={
            formData[FormField.title]
              ? `Edit Project: ${formData[FormField.title]}`
              : ""
          }
        />
        <ViewContainer id={"editProjectContainer"}>
          <form id="editProject" onSubmit={handleSubmit}>
            <FormLayoutVertical>
              <TextField
                label="ID"
                {...getGeneralProps(FormField.displayId)}
                required
                disabled={!hasPermission}
                InputProps={getGeneralInputProps(FormField.displayId)}
              />

              <TextField
                label="Title"
                {...getGeneralProps(FormField.title)}
                disabled={!hasPermission}
                InputProps={getGeneralInputProps(FormField.title)}
              />

              <TextField
                label="External ID"
                {...getGeneralProps(FormField.externalId)}
                disabled={!hasPermission}
                InputProps={getGeneralInputProps(FormField.externalId)}
              />

              {hasPermission ? (
                <SelectField
                  id={FormField.assigneeId}
                  label="Assignee"
                  {...getGeneralProps(FormField.assigneeId)}
                  options={mapTeamMembersToOptions(teamMembers)}
                  onChange={handleAssigneeChange}
                  defaultValue={{
                    value: formData[FormField.assigneeId],
                    label: assigneeUserName,
                  }}
                  placeholder="Select assignee"
                  isClearable={true}
                  className="add-member-input"
                  components={{
                    DropdownIndicator,
                    ClearIndicator,
                    // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                    Option,
                  }}
                />
              ) : (
                <TextField
                  label="Assignee"
                  disabled
                  InputProps={{
                    id: "assigneeId",
                    name: "assigneeId",
                    onChange: handleChange(FormField.assigneeId),
                    autoComplete: "off",
                  }}
                  value={assigneeUserName}
                  error={isInErrorState(FormField.assigneeId)}
                />
              )}

              <TextField
                label="Description"
                {...getGeneralProps(FormField.description)}
                disabled={!hasPermission}
                InputProps={getGeneralInputProps(FormField.description)}
              />

              <TextField
                label="Conclusion"
                {...getGeneralProps(FormField.conclusion)}
                disabled={!hasPermission}
                InputProps={getGeneralInputProps(FormField.conclusion)}
              />

              <SelectField
                id={FormField.status}
                label="Status"
                {...getGeneralProps(FormField.status)}
                defaultValue={
                  formData[FormField.status]
                    ? {
                        value: formData[FormField.status],
                        label: capitalizeFirstLetter(
                          formData[FormField.status],
                        ),
                      }
                    : null
                }
                onChange={handleStatusChange}
                isSearchable={false}
                options={[
                  { value: ProjectStatus.open, label: "Open" },
                  { value: ProjectStatus.closed, label: "Closed" },
                ]}
                components={{
                  DropdownIndicator,
                  // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                  Option,
                }}
              />

              <FormLayoutVertical id="listTeamMembersContainer">
                <FormSectionTitle>Members</FormSectionTitle>

                {hasPermission ? (
                  <Stack direction="row" alignItems="flex-start" spacing={6}>
                    <SelectField
                      id={FormField.newMemberId}
                      label="Members"
                      {...getGeneralProps(FormField.newMemberId)}
                      options={mapTeamMembersToOptions(projectNotMemberList)}
                      onChange={handleNewMemberChange}
                      placeholder="Add new member"
                      defaultValue={
                        formData[FormField.newMemberId]
                          ? {
                              value: formData[FormField.newMemberId],
                              label: getUserName(
                                formData[FormField.newMemberId],
                                teamMembers,
                              ),
                            }
                          : null
                      }
                      isClearable={true}
                      components={{
                        DropdownIndicator,
                        ClearIndicator,
                        // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                        Option,
                      }}
                    />
                    <Button
                      id="addNewMemberIdButton"
                      colorVariant="outlined"
                      onClick={handleAddNewMember}
                      disabled={!formData[FormField.newMemberId]}
                      size="medium"
                      sx={theme => ({ "&&": { mt: theme.spacing(6) } })}
                    >
                      Add
                    </Button>
                  </Stack>
                ) : null}

                <MemberList
                  memberList={projectMemberList}
                  renderRemoveButton={renderRemoveButton}
                />
              </FormLayoutVertical>

              {isProjectModified() ? (
                <FormActions>
                  <Button
                    colorVariant="primary"
                    type="submit"
                    loading={editProjectQueryResult.isPending}
                  >
                    Save
                  </Button>
                  <Button colorVariant="secondary" onClick={handleCancelButton}>
                    Cancel
                  </Button>
                </FormActions>
              ) : null}
            </FormLayoutVertical>
          </form>
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
}
