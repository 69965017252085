import {
  ListItem,
  Spacing1,
  Spacing2,
  Spacing4,
} from "@chemaxon/design-system";
import { ListItemIcon, ListItemText } from "@mui/material";
import { ReactElement } from "react";

export default function MoreMenuListButton(props: Props) {
  return (
    <ListItem
      onClick={props.onClick}
      sx={{
        display: "flex",
        padding: `${Spacing1} ${Spacing4}`,
        alignItems: "center",
        gap: Spacing2,
        alignSelf: "stretch",
        [`& svg.MuiSvgIcon-root`]: {
          color: `${props.color} !important`,
        },
        [`& div.MuiListItemIcon-root`]: {
          margin: "0px !important",
          padding: "0px !important",
        },
      }}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText sx={{ color: props.color }}>{props.text}</ListItemText>
    </ListItem>
  );
}

type Props = {
  onClick: () => void;
  icon: ReactElement;
  color: string;
  text: string;
};
