import PropTypes from "prop-types";
import React from "react";
import log from "loglevel";
import { flow } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import { Button, TextField } from "@chemaxon/design-system";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { useImportTeam } from "src/ui/services/TeamService";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import Subheader from "src/ui/components/subheader/Subheader";
import withRouter from "src/ui/utils/WithRouter";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormActions from "src/ui/components/form/FormActions";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";

import "./ImportTeamView.scss";
import { withSnackbar } from "src/ui/utils/withSnackbar";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

const HELPER_TEXTS = {
  teamJson: "Please enter the JSON copied from Synergy team export.",
};

class ImportTeamView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        teamJson: "",
      },
      formErrors: {
        teamJson: "",
      },
      importingTeam: false,
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      importingTeam: true,
    });

    let teamData = {};

    try {
      teamData = JSON.parse(this.state.formData.teamJson);
    } catch (e) {
      console.log(e);
      this.setState({
        formErrors: { teamJson: "The JSON you specified is not correct." },
      });
      this.setState({
        importingTeam: false,
      });
      return;
    }

    this.props.teamService
      .importTeam(teamData)
      .then(teamId => {
        this.props.router.navigate("/team/edit/" + teamId);
      })
      .catch(err => {
        let newFormErrors = {};
        let errorMessage = "";
        const fieldErrors = err.getFieldErrors();
        if (fieldErrors.length > 0) {
          err.getFieldErrors().forEach(e => {
            newFormErrors[e.field] = e.message;
          });
        } else if (err.data.trace !== undefined) {
          errorMessage = err.data.trace;
        } else {
          this.props.snackbar.enqueueSnackbar(
            `Failed to import the team! Check the content of the JSON!`,
            {
              variant: "error",
              persist: true,
              action: onCloseAction(this.props.snackbar.closeSnackbar),
            },
          );
        }
        this.setState({
          formErrors: newFormErrors,
        });
        log.error("Failed to import team.", err);
      })
      .finally(() => {
        this.setState({
          importingTeam: false,
        });
      });
  };

  handleTextFieldChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: event.target.value,
      };
      this.setState({
        formData: newFormData,
      });

      // clearing potentionally shown error message under the field
      delete this.state.formErrors[fieldName];
    };
  };

  isInErrorState(field) {
    return !!(this.state.formErrors[field] || "");
  }

  getShownText(field) {
    return this.isInErrorState(field)
      ? this.state.formErrors[field]
      : HELPER_TEXTS[field];
  }

  getGeneralInputProps(field) {
    return {
      name: field,
      value: this.state.formData[field],
      autoComplete: "off",
      id: field,
    };
  }

  getGeneralProps(field) {
    return {
      helperText: (
        <HelperTextTypography
          text={this.getShownText(field)}
          isError={this.isInErrorState(field)}
        />
      ),
      error: this.isInErrorState(field),
    };
  }

  render() {
    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.TEAM,
            platformPermission: PlatformPermission.CREATE,
          }}
        >
          <Subheader title="Import team" />
          <ViewContainer id={"importTeamContainer"}>
            <form onSubmit={this.handleSubmit} id="importTeam">
              <FormLayoutVertical>
                <TextField
                  {...this.getGeneralProps("teamJson")}
                  InputProps={this.getGeneralInputProps("teamJson")}
                  type="text"
                  label="Team JSON"
                  variant="outlined"
                  autoFocus
                  required
                  multiline
                  rows={10}
                  onChange={this.handleTextFieldChange("teamJson")}
                />

                <FormActions>
                  <Button
                    type="submit"
                    colorVariant="primary"
                    loading={this.props.teamService.isImportTeamLoading}
                  >
                    Import team
                  </Button>
                  <Button
                    onClick={this.handleBackButton}
                    colorVariant="secondary"
                  >
                    Go back
                  </Button>
                </FormActions>

                <Backdrop
                  open={this.state.importingTeam}
                  className="importteam-loader"
                  timeout={300}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </FormLayoutVertical>
            </form>
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }

  handleBackButton = () => {
    this.props.router.navigate("/teams");
  };
}

ImportTeamView.propTypes = {
  router: PropTypes.object,
  teamService: PropTypes.shape({
    importTeam: PropTypes.func,
    isImportTeamLoading: PropTypes.bool,
  }),
  snackbar: PropTypes.shape({
    enqueueSnackbar: PropTypes.func,
    closeSnackbar: PropTypes.func,
  }),
};

const withTeamService = WrappedComponent => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTeamService = props => {
    const mutateImportTeam = useImportTeam();

    const teamService = {
      importTeam: mutateImportTeam.mutateAsync,
      isImportTeamLoading: mutateImportTeam.isPending,
    };

    return <WrappedComponent {...props} teamService={teamService} />;
  };

  ComponentWithTeamService.displayName = `WithTeamService(${displayName})`;

  return ComponentWithTeamService;
};

const enhance = flow(withSnackbar, withTeamService, withRouter);

export default enhance(ImportTeamView);
