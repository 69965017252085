import React from "react";
import { AnalyticsProvider } from "use-analytics";

import { analyticsInstance } from "src/ui/utils/analytics";

type Props = {
  children: React.ReactNode;
};

export const CustomAnalyticsProvider = ({ children }: Props) => {
  return (
    <AnalyticsProvider instance={analyticsInstance}>
      {children}
    </AnalyticsProvider>
  );
};
