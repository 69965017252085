import { styled } from "@mui/material/styles";

const PREFIX = "Number";
export const classes = {
  input: `${PREFIX}-input`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  inputElement: `${PREFIX}-inputElement`,
  multiline: `${PREFIX}-multiline`,
  focused: `${PREFIX}-focused`,
  error: `${PREFIX}-error`,
  disabled: `${PREFIX}-disabled`,
  numberContainer: `${PREFIX}-numberContainer`,
  number: `${PREFIX}-number`,
  plus: `${PREFIX}-plus`,
  minus: `${PREFIX}-minus`,
  mediumIconContainer: `${PREFIX}-mediumIconContainer`,
  largeIconContainer: `${PREFIX}-largeIconContainer`,
  icon: `${PREFIX}-icon`,
  disabledIcon: `${PREFIX}-disabledIcon`,
};
export const Root = styled("div")(() => ({
  [`&.${classes.numberContainer}`]: {
    position: "relative",
    minWidth: 60,
  },

  [`& .${classes.input}`]: {
    width: "100%",
    minWidth: 60,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "#F1F3F4",
    borderRadius: 4,
    fontSize: "16px",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor: "#F1F3F4",
    },
    lineHeight: 1.25,
  },

  [`& .${classes.medium}`]: {
    minHeight: 32,
    padding: "0 8px",
  },

  [`& .${classes.large}`]: {
    minHeight: 40,
    padding: "0 12px",
  },

  [`& .${classes.inputElement}`]: {
    paddingRight: "10px",
    "&::placeholder": {
      color: "#F1F3F4",
      opacity: 1,
      fontStyle: "italic",
    },
  },

  [`& .${classes.multiline}`]: {
    paddingTop: 8,
    paddingBottom: 8,
  },

  [`& .${classes.focused}`]: {
    boxShadow: "0 0 0 1px #F1F3F4",
    borderColor: "#F1F3F4",
    "&:hover": {
      borderColor: "#F1F3F4",
    },
  },

  [`& .${classes.error}`]: {
    boxShadow: "0 0 0 1px #F1F3F4",
    borderColor: "#F1F3F4",
    "&:hover": {
      borderColor: "#F1F3F4",
    },
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: "#F1F3F4",
    cursor: "not-allowed",
    opacity: 0.8,
    "& $inputElement": {
      opacity: 0.65,
    },
    "&:hover": {
      borderColor: "#F1F3F4",
    },
  },
  [`& .${classes.number}`]: {
    "&::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
  [`& .${classes.plus}`]: {
    position: "absolute",
    right: "0px",
    top: "24px",
    bottom: "0px",
    width: "20px",
    height: "20px",
    margin: 0,
    cursor: "pointer",
    borderLeft: "1px solid #C6CED2",
    borderBottom: "1px solid #C6CED2",
    zIndex: 1,
  },
  [`& .${classes.minus}`]: {
    position: "absolute",
    bottom: "0px",
    top: "44px",
    width: "20px",
    height: "20px",
    right: "0px",
    margin: 0,
    cursor: "pointer",
    borderLeft: "1px solid #C6CED2",
    zIndex: 1,
  },
  [`& .${classes.mediumIconContainer}`]: {
    width: 16,
    height: 16,
  },
  [`& .${classes.largeIconContainer}`]: {
    width: 20,
    height: 20,
  },
  [`& .${classes.icon}`]: {
    color: "#5A6A72",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 17,
    height: 17,
    "&:hover": {
      color: "#2D3539",
    },
  },
  [`& .${classes.disabledIcon}`]: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
}));
