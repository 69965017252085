import TopMenuBar from "src/ui/components/top-menu-bar/TopMenuBar";
import { Outlet } from "react-router-dom";
import React from "react";
import { Toolbar, Container } from "@mui/material";

export const DefaultLayout = () => {
  return (
    <Container
      id="plexus-portal"
      maxWidth="lg"
      sx={{
        px: {
          xs: 4,
          sm: 8,
        },
        pb: 8,
      }}
    >
      <TopMenuBar />
      <Toolbar />

      <Outlet />
    </Container>
  );
};
