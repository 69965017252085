export type Project = {
  id: number;
  assigneeId: number;
  authorId: number;
  conclusion: string;
  created: Date;
  description: string;
  displayId: string;
  externalId: string;
  memberIds: number[];
  status: ProjectStatus;
  team: number;
  title: string;
};

export enum ProjectStatus {
  open = "open",
  closed = "closed",
}

export type ProjectsGetResponse = Project[];

export type ProjectAddRequest = Omit<Project, "id">;

export type ProjectEditRequest = Project & {
  teamId: number;
};

export type ProjectGetResponse = Project;
