export type ResourceInfo = {
  id: string;
  entryPointUrl: string;
  type: string;
};

export type ResourcesInfoGetResponse = ResourceInfo[];

export type Resource = {
  id: number;
  team: number;
  resourceConfig: ResourceConfig;
};

export type ResourcesGetResponse = Resource[];

export type ResourceAddRequest = {
  team: number;
  resourceConfig: Partial<ResourceConfig>;
};

export type ResourceUpdateRequest = {
  team: number;
  id: number;
  resourceConfig: Partial<ResourceConfig>;
  resourceType: null;
};

export type ResourceConfig = ResourceConfigGoogle | ResourceConfigEgnyte;

export type ResourceConfigEgnyte = {
  clientId: string;
  clientSecret: string;
  domain: string;
  subdomain: string;
  type: ResourceConfigType.Egnyte;
};

export type ResourceConfigGoogle = {
  clientId: string;
  clientSecret: string;
  type: ResourceConfigType.Google;
};

export enum ResourceConfigType {
  Google = "google",
  Egnyte = "egnyte",
}
