import { queryOptions, useQuery } from "@tanstack/react-query";

import type { QueryKeysDefinition } from "src/types/queryKeys";
import Api from "src/ui/services/Api";
import { PublicTenantInfoResponse } from "src/ui/models/PublicTenantInfoResponse";

export const SERVICE_URL = "/public/tenant";

class PublicTenantInfoService {
  static getPublicTenantInfo(): Promise<PublicTenantInfoResponse> {
    return Api.get(SERVICE_URL);
  }
}

const QUERY_KEYS_NAMESPACE = "publictenantinfo";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  env: () => [QUERY_KEYS_NAMESPACE, "publictenantinfo"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const getPublicTenantInfoQueryOptions = <
  TData = PublicTenantInfoResponse,
>(
  select?: (data: PublicTenantInfoResponse) => TData,
) =>
  queryOptions({
    queryKey: queryKeys.env(),
    queryFn: () => PublicTenantInfoService.getPublicTenantInfo(),
    select,
  });

export const useGetPublicTenantInfoQuery = <TData = PublicTenantInfoService>(
  select?: (data: PublicTenantInfoResponse) => TData,
) => useQuery(getPublicTenantInfoQueryOptions(select));

export default PublicTenantInfoService;
