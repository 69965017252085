import React from "react";
import PropTypes from "prop-types";
import Grow from "@mui/material/Grow";
import { TextField } from "@chemaxon/design-system";
import Search from "@chemaxon/design-system/icons/Search";
import { Box } from "@mui/material";

class SynergyMUIDataTableSearchBox extends React.Component {
  handleTextChange = event => {
    this.props.onSearch(event.target.value);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  render() {
    const { options, searchText } = this.props;

    return (
      <Grow appear in={true} timeout={300}>
        <Box
          sx={{
            display: "flex",
            flex: "1 0 auto",
          }}
          ref={el => (this.rootRef = el)}
        >
          <TextField
            variant="standard"
            InputProps={{
              "data-test-id": options.textLabels.toolbar.search,
              endAdornment: <Search color="primary" />,
              sx: {
                width: 200,
                height: 32,
                fontSize: 14,
                paddingRight: 0,
              },
            }}
            inputProps={{
              "aria-label": options.textLabels.toolbar.search,
            }}
            value={searchText || ""}
            onChange={this.handleTextChange}
            inputRef={el => (this.searchField = el)}
            placeholder={options.searchPlaceholder}
            sx={{
              marginBottom: 0,
              marginTop: 0,
              flex: "0.8 0",
            }}
          />
        </Box>
      </Grow>
    );
  }
}

SynergyMUIDataTableSearchBox.propTypes = {
  classes: PropTypes.object,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onHide: PropTypes.func,
  options: PropTypes.object,
};

export default SynergyMUIDataTableSearchBox;
