import { useEffect } from "react";
import log from "loglevel";

import { useGetPermissions } from "src/ui/services/PermissionService";
import {
  createCheckPermission,
  createIsTeamAdmin,
} from "src/ui/services/permissionUtils";

export const usePermissions = () => {
  const getPermissionsQueryResult = useGetPermissions();

  useEffect(() => {
    if (getPermissionsQueryResult.error) {
      log.error(
        "Failed to load permission info from server.",
        getPermissionsQueryResult.error,
      );
    }
  }, [getPermissionsQueryResult.error]);

  const permissions = getPermissionsQueryResult.data ?? {};

  const checkPermission = createCheckPermission(permissions);
  const isUserTeamAdmin = createIsTeamAdmin(checkPermission);

  return {
    queryResult: getPermissionsQueryResult,
    permissions,
    checkPermission,
    isUserTeamAdmin,
  };
};
