/**
 * If the build is configured to use a non-HTML custom entry via build.rollupOptions.input,
 * then it is necessary to manually import the polyfill.
 * @see https://vitejs.dev/config/build-options.html#build-modulepreload
 **/
import "vite/modulepreload-polyfill";

import { createRoot } from "react-dom/client";
import App from "src/ui/app/App";
import log from "loglevel";
import LeadGenLandingPageApp from "src/ui/app/LeadGenLandingPageApp";
import { isTenantDomain } from "src/ui/utils/config";

//logging
log.setLevel("debug");
log.getLogger("api").setLevel("error");
log.getLogger("permissionUtils").setLevel("error");
log.getLogger("appState").setLevel("error");

//router
let AppRoot = () => (isTenantDomain() ? <App /> : <LeadGenLandingPageApp />);

// setting up body to full screen
document.body.style.height = "100%";

const container = document.getElementById("app");
const reactRoot = createRoot(container);
reactRoot.render(<AppRoot />);
