import { deepmerge } from "@mui/utils";
import { cxnTheme } from "@chemaxon/design-system";
import { Content } from "@chemaxon/design-system/color";
import { body3Strong } from "@chemaxon/design-system/typography/jscss";

import { MuiFormElementOverrides } from "src/ui/utils/MuiFormElementOverrides";
import linkOverrides from "src/ui/theme/LinkOverrides";

const synergyTheme = deepmerge(cxnTheme, {
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          zIndex: 0,
          borderColor: "black",
        },
        sortAction: ({ theme }) => ({
          "& svg": {
            marginTop: theme.spacing(0),
            width: 20,
          },
        }),
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        left: ({ theme }) => ({
          "& input": {
            fontSize: "16px",
          },
        }),
        actions: {
          "& button[data-testid=Search-iconButton]": {
            display: "none",
          },
        },
        root: {
          paddingLeft: 16,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        footer: {
          borderBottom: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          ...body3Strong,
          color: Content.default,
          paddingTop: 11,
          paddingBottom: 5,
        },
      },
    },
    ...MuiFormElementOverrides,
    ...linkOverrides,
  },
});

export default synergyTheme;
