import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="copyright">
          Copyright © 1998-{this.getCurrentYear()} Chemaxon Ltd. All rights
          reserved.
        </div>
        <div className="links">
          <a href="https://docs.chemaxon.com/display/docs/Privacy+Policy+for+ChemAxon+Synergy">
            Privacy Policy
          </a>
          <a href="https://docs.chemaxon.com/display/docs/End+User+Subscription+Agreement+-+EUSA">
            EUSA
          </a>
        </div>
      </div>
    );
  }

  getCurrentYear() {
    let currentDate = new Date();
    return currentDate.getFullYear();
  }
}

export default Footer;
