import React from "react";

import { useGetProjects } from "src/ui/services/ProjectService";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";

export const withProjects = <TProps extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithProjects = (props: TProps) => {
    const getProjectsQueryResult = useGetProjects();

    const projects = adaptUseQueryResponseForContainerWithLoader(
      getProjectsQueryResult,
    );

    return <WrappedComponent {...props} projects={projects} />;
  };

  ComponentWithProjects.displayName = `WithProjects(${displayName})`;

  return ComponentWithProjects;
};
