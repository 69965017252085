import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Api from "src/ui/services/Api";
import {
  AppInfoFeature,
  Application,
  ApplicationAddRequest,
  ApplicationEditRequest,
  ApplicationInfo,
  FeatureNamespace,
} from "src/ui/models/Application";
import type { QueryKeysDefinition } from "src/types/queryKeys";
import queryClient from "src/ui/utils/queryClient";

export const SERVICE_URL = "/internal/applications";

class ApplicationService {
  /**
   * Get list of registered applications.
   */
  static getApplications(): Promise<Application[]> {
    return Api.get(`${SERVICE_URL}?skipHealthCheck=true`);
  }

  /**
   * Get list of registered applications with health check.
   */
  static getApplicationsWithHealthCheck(): Promise<Application[]> {
    return Api.get(`${SERVICE_URL}?skipHealthCheck=false`);
  }

  /**
   * Get application details.
   */
  static getApplication(appId: number | undefined): Promise<Application> {
    return typeof appId === "undefined"
      ? Promise.reject(new Error("Invalid app id"))
      : Api.get(`${SERVICE_URL}/${appId}`);
  }

  /**
   * Retrieves application info from remote server.
   *
   * @param appInfoUrl url to fetch info from
   */
  static getApplicationInfo(
    appInfoUrl: string | undefined,
  ): Promise<ApplicationInfo | undefined> {
    if (typeof appInfoUrl === "undefined") {
      return Promise.reject(new Error("Invalid appInfoUrl"));
    }

    const url = `${SERVICE_URL}/info?applicationUrl=${encodeURIComponent(
      appInfoUrl,
    )}`;
    // TODO: test if URL even exists
    return Api.get(url).then(appInfo =>
      this.addAppInfoFeature(appInfo, appInfoUrl),
    );
  }

  static addAppInfoFeature(
    originalAppInfo: ApplicationInfo,
    appInfoUrl: string,
  ) {
    const appInfoNamespace = FeatureNamespace.AppInfo;
    const appInfoFeature = {
      namespace: appInfoNamespace,
      attributes: { url: appInfoUrl },
    } satisfies AppInfoFeature;

    // If originalAppInfo is undefined that means that the request response for the URL wasn't 200
    // but if it was 200, it's still possible that the feature object doesn't exist
    // therefore we don't want to filter on it
    if (!originalAppInfo) return;

    const otherFeatures = ((originalAppInfo || {}).features || []).filter(
      f => f.namespace !== appInfoNamespace,
    );

    return {
      ...originalAppInfo,
      features: [...otherFeatures, appInfoFeature],
    };
  }

  /**
   * Add new application.
   */
  static addApplication(data: ApplicationAddRequest): Promise<number> {
    return Api.post(`${SERVICE_URL}/skeleton`, data);
  }

  /**
   * Edit existing application.
   */
  static editApplication(data: ApplicationEditRequest): Promise<Application> {
    return Api.put(SERVICE_URL, data);
  }

  /**
   * Remove application.
   */
  static removeApplication(appId: number): Promise<void> {
    return Api.delete(`${SERVICE_URL}/${appId}`);
  }
}

const QUERY_KEYS_NAMESPACE = "applications";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
  details: () => [QUERY_KEYS_NAMESPACE, "detail"] as const,
  appInfos: () => [QUERY_KEYS_NAMESPACE, "appInfo"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
  listWithSkipHealthcheck: (skipHealthCheck: boolean) =>
    [...baseQueryKeys.list(), skipHealthCheck] as const,
  detail: (appId: number | undefined) =>
    [...baseQueryKeys.details(), appId] as const,
  appInfo: (appInfoUrl: string | undefined) =>
    [...baseQueryKeys.appInfos(), appInfoUrl] as const,
} satisfies QueryKeysDefinition;

export const useGetApplications = () =>
  useQuery({
    queryKey: queryKeys.listWithSkipHealthcheck(true),
    queryFn: () => ApplicationService.getApplications(),
  });

export const useGetApplicationsWithHealthCheck = () =>
  useQuery({
    queryKey: queryKeys.listWithSkipHealthcheck(false),
    queryFn: () => ApplicationService.getApplicationsWithHealthCheck(),
  });

export const useGetApplication = <TData = Application>(
  appId: number | undefined,
  enabled: boolean = true,
  select?: (data: Application) => TData,
) =>
  useQuery({
    queryKey: queryKeys.detail(appId),
    queryFn: () => ApplicationService.getApplication(appId),
    enabled,
    select,
  });

export const useGetApplicationInfo = (
  appInfoUrl: string | undefined,
  enabled: boolean = true,
) =>
  useQuery({
    queryKey: queryKeys.appInfo(appInfoUrl),
    queryFn: () => ApplicationService.getApplicationInfo(appInfoUrl),
    enabled,
  });

export const useAddApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: ApplicationAddRequest) =>
      ApplicationService.addApplication(input),
    onSuccess: () => {
      // Invalidates data cached by both
      // useGetApplications and useGetApplicationsWithHealthCheck.
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
    },
  });
};

export const useEditApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: ApplicationEditRequest) =>
      ApplicationService.editApplication(input),
    onSuccess: (_, input) => {
      // Invalidates data cached by both
      // useGetApplications and useGetApplicationsWithHealthCheck.
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(input.id),
      });
    },
  });
};

export const useRemoveApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appId: number) => ApplicationService.removeApplication(appId),
    onSuccess: (_, appId) => {
      // Invalidates data cached by both
      // useGetApplications and useGetApplicationsWithHealthCheck.
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(appId),
      });
    },
  });
};

queryClient.setQueryDefaults(queryKeys.appInfos(), {
  staleTime: 0,
});

export default ApplicationService;
