import ArrowDown from "@chemaxon/design-system/icons/ArrowDown";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import { AppRoutePath } from "src/ui/utils/routes";
import {
  default as Navigation,
  NavigationTabProps,
  NestedNavigationTabProps,
} from "src/ui/components/navigation/Navigation";
import { matchPath, useLocation } from "react-router-dom";
import { usePermissions } from "src/ui/utils/usePermissions";

const generateId = (name: string) => name.replace(/\s/g, "-").toLowerCase();

const getNavigationTab = (
  name: string,
  path: string,
  tabProps?: Partial<Omit<NavigationTabProps, "id" | "label" | "href">>,
): NavigationTabProps => {
  return {
    id: generateId(`navigation-${name}`),
    label: name,
    href: path,
    ...tabProps,
  };
};

type NavigationTabDefinition = {
  tab: NavigationTabProps;
  permissions:
    | Parameters<ReturnType<typeof usePermissions>["checkPermission"]>
    | boolean;
  matchingRoutes: AppRoutePath[];
};

type NestedNavigationTabDefinition = NavigationTabDefinition & {
  nestedTabs?: NavigationTabDefinition[];
};

enum NAVIGATION_LABELS {
  DASHBOARD = "Dashboard",
  APPLICATIONS = "Applications",
  TEAMS = "Teams",
  PROJECTS = "Projects",
  TEAM_MEMBERS = "Team Members",
  GROUPS = "Groups",
  RESOURCES = "Resources",
  CONFIGURE = "Configure",
}

const checkTabDefinitionPermissions =
  (checkPermission: ReturnType<typeof usePermissions>["checkPermission"]) =>
  (tabDefinition: NestedNavigationTabDefinition) =>
    typeof tabDefinition.permissions === "boolean"
      ? tabDefinition.permissions
      : checkPermission(...tabDefinition.permissions);

const getNavigationTabs = ({
  currentPath,
  checkPermission,
  isUserTeamAdmin,
}: {
  currentPath?: string;
  checkPermission: ReturnType<typeof usePermissions>["checkPermission"];
  isUserTeamAdmin: ReturnType<typeof usePermissions>["isUserTeamAdmin"];
}): NavigationTabProps[] => {
  const navigationTabDefinitions: NestedNavigationTabDefinition[] = [
    {
      tab: getNavigationTab(
        NAVIGATION_LABELS.DASHBOARD,
        AppRoutePath.Dashboard,
      ),
      permissions: [
        PlatformDomainObjectType.APPLICATION,
        PlatformPermission.READ,
      ],
      matchingRoutes: [AppRoutePath.Dashboard, AppRoutePath.Notifications],
    },
    {
      tab: getNavigationTab(
        NAVIGATION_LABELS.APPLICATIONS,
        AppRoutePath.ApplicationList,
      ),
      permissions: [
        PlatformDomainObjectType.APPLICATION,
        PlatformPermission.WRITE,
      ],
      matchingRoutes: [
        AppRoutePath.ApplicationList,
        AppRoutePath.ApplicationAdd,
        AppRoutePath.ApplicationEdit,
      ],
    },
    {
      tab: getNavigationTab(NAVIGATION_LABELS.TEAMS, AppRoutePath.TeamList),
      permissions: [PlatformDomainObjectType.TEAM, PlatformPermission.WRITE],
      matchingRoutes: [
        AppRoutePath.TeamList,
        AppRoutePath.TeamAdd,
        AppRoutePath.TeamEdit,
      ],
    },
    {
      tab: getNavigationTab(
        NAVIGATION_LABELS.PROJECTS,
        AppRoutePath.ProjectList,
      ),
      permissions: [PlatformDomainObjectType.PROJECT, PlatformPermission.WRITE],
      matchingRoutes: [
        AppRoutePath.ProjectList,
        AppRoutePath.ProjectAdd,
        AppRoutePath.ProjectEdit,
      ],
    },
    {
      tab: getNavigationTab(NAVIGATION_LABELS.CONFIGURE, "#", {
        icon: <ArrowDown />,
        iconPosition: "end",
        onClick: event => event.preventDefault(),
      }),
      permissions: isUserTeamAdmin(),
      matchingRoutes: [
        AppRoutePath.TeamMemberList,
        AppRoutePath.TeamMemberAdd,
        AppRoutePath.GroupList,
        AppRoutePath.ResourceList,
      ],
      nestedTabs: [
        {
          tab: getNavigationTab(
            NAVIGATION_LABELS.TEAM_MEMBERS,
            AppRoutePath.TeamMemberList,
          ),
          permissions: [
            PlatformDomainObjectType.USER,
            PlatformPermission.WRITE,
          ],
          matchingRoutes: [
            AppRoutePath.TeamMemberList,
            AppRoutePath.TeamMemberAdd,
          ],
        },
        {
          tab: getNavigationTab(
            NAVIGATION_LABELS.GROUPS,
            AppRoutePath.GroupList,
          ),
          permissions: [
            PlatformDomainObjectType.GROUP,
            PlatformPermission.WRITE,
          ],
          matchingRoutes: [AppRoutePath.GroupList],
        },
        {
          tab: getNavigationTab(
            NAVIGATION_LABELS.RESOURCES,
            AppRoutePath.ResourceList,
          ),
          permissions: [
            PlatformDomainObjectType.RESOURCE,
            PlatformPermission.WRITE,
          ],
          matchingRoutes: [AppRoutePath.ResourceList],
        },
      ],
    },
  ];
  const transformTabDefinitions = (
    tabDefinitions: NestedNavigationTabDefinition[],
  ): NestedNavigationTabProps[] =>
    tabDefinitions.filter(checkTabDefinitionPermissions(checkPermission)).map(
      tabDefinition =>
        ({
          ...tabDefinition.tab,
          ...(currentPath !== undefined
            ? {
                selected: matchTabDefinitionByPath(currentPath)(tabDefinition),
              }
            : {}),
          ...(tabDefinition.nestedTabs
            ? { nestedTabs: transformTabDefinitions(tabDefinition.nestedTabs) }
            : {}),
        } satisfies NavigationTabProps | NestedNavigationTabProps),
    );

  return transformTabDefinitions(navigationTabDefinitions);
};

const matchTabDefinitionByPath =
  (tabPath: string) => (tab: NavigationTabDefinition) =>
    tab.matchingRoutes.some(route => Boolean(matchPath(route, tabPath)));

const matchTabByLabel = (tabLabel: string) => (tab: NavigationTabProps) =>
  tab.id === generateId(`navigation-${tabLabel}`);

const useNavigationTabs = () => {
  const { pathname } = useLocation();
  const { checkPermission, isUserTeamAdmin } = usePermissions();

  const navigationTabs: NestedNavigationTabProps[] = getNavigationTabs({
    currentPath: pathname,
    checkPermission,
    isUserTeamAdmin,
  });
  const configureNestedTabs: NavigationTabProps[] =
    navigationTabs.find(matchTabByLabel(NAVIGATION_LABELS.CONFIGURE))
      ?.nestedTabs || [];

  const activeNavigationTabIndex = navigationTabs.findIndex(
    tab => tab.selected,
  );
  const activeConfigureNestedTabIndex = configureNestedTabs.findIndex(
    tab => tab.selected,
  );

  const hasActiveNavigationTab = activeNavigationTabIndex >= 0;
  const hasActiveConfigureNestedTab = activeConfigureNestedTabIndex >= 0;

  let activeIndex = 0;

  if (hasActiveConfigureNestedTab) {
    activeIndex = navigationTabs.findIndex(
      matchTabByLabel(NAVIGATION_LABELS.CONFIGURE),
    );
  } else if (hasActiveNavigationTab) {
    activeIndex = activeNavigationTabIndex;
  }

  return { activeIndex, navigationTabs };
};

export default function NavigationToolbar() {
  const { activeIndex, navigationTabs } = useNavigationTabs();

  return <Navigation activeIndex={activeIndex} tabs={navigationTabs} />;
}
