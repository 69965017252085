import { Box, Paper } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { CornerExtraLarge } from "@chemaxon/design-system";
import { Outline } from "@chemaxon/design-system/color";
import { Spacing11, Spacing12 } from "@chemaxon/design-system/spacing";

interface Props {
  children: React.ReactNode;
  id: string;
  transparentBg?: boolean;
  sx?: SxProps<Theme>;
}

function ViewContainer({ children, id, transparentBg = false, sx }: Props) {
  return transparentBg ? (
    <Box
      id={id}
      sx={{
        mt: {
          xs: Spacing12,
          sm: Spacing11,
        },
      }}
    >
      {children}
    </Box>
  ) : (
    <Paper
      id={id}
      sx={{
        p: 4,
        mt: {
          xs: Spacing12,
          sm: Spacing11,
        },
        borderRadius: CornerExtraLarge,
        border: "1px solid",
        borderColor: Outline.default,
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

export default ViewContainer;
