export type TeamMember = {
  id: number;
  userName: string;
  operator: boolean;
  idpUserDetails: IdpUserDetails;
  uid: string;
  team: number;
  status: UserStatus;
  lastActive: Date;
  acceptedEUSA: boolean;
  whenMemberJoinedTeam: Date;
  groups: number[];
  projects: number[];
};

export type IdpUserDetails = {
  name: string;
};

export type AddTeamMemberRequest = {
  users: User[];
  teamId: number;
  groups: string[];
};

export type User = {
  userName: string;
};

export enum UserStatus {
  JOINED = "JOINED",
  INVITED = "INVITED",
  WAITING_FOR_REGISTRATION = "WAITING_FOR_REGISTRATION",
}
