import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  createSearchParams,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import NotificationSettingsView from "src/ui/views/notification-settings/NotificationSettingsView";
import Unsubscribe from "src/ui/views/notification-settings/Unsubscribe";
import { QueryClientProvider } from "@tanstack/react-query";
import log from "loglevel";

//views
import HomeView from "src/ui/views/home/HomeView";
import ListApplicationsView from "src/ui/views/applications/list/ListApplicationsView";
import AddApplicationView from "src/ui/views/applications/add/AddApplicationView";
import EditApplicationView from "src/ui/views/applications/edit/EditApplicationView";
import ListTeamsView from "src/ui/views/teams/list/ListTeamsView";
import AddTeamView from "src/ui/views/teams/add/AddTeamView";
import ImportTeamView from "src/ui/views/teams/import/ImportTeamView";
import EditTeamView from "src/ui/views/teams/edit/EditTeamView";
import ListTeamMembersView from "src/ui/views/team-members/list/ListTeamMembersView";
import AddTeamMembersView from "src/ui/views/team-members/add/AddTeamMembersView";
import ListGroupsView from "src/ui/views/groups/list/ListGroupsView";
import ListProjectsView from "src/ui/views/projects/list/ListProjectsView";
import AddProjectView from "src/ui/views/projects/add/AddProjectView";
import EditProjectView from "src/ui/views/projects/edit/EditProjectView";
import ResourcesView from "src/ui/views/resources/ResourcesView";
import { DefaultLayout } from "src/ui/components/layout/Layout";
import SynergyTheme from "src/ui/utils/SynergyTheme";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Background } from "@chemaxon/design-system/color";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AppRoutePath } from "src/ui/utils/routes";
import {
  useAuthentication,
  useGetMeQuery,
} from "src/ui/services/UserInfoService";
import { useGetEnvQuery } from "src/ui/services/EnvInfoService";
import { useGetPublicTenantInfoQuery } from "src/ui/services/PublicTenantInfoService";
import { usePermissions } from "src/ui/utils/usePermissions";
import { useFreshdesk } from "src/ui/utils/useFreshdesk";
import { usePendo } from "src/ui/utils/usePendo";
import {
  errors as ErrorTypes,
  ErrorPage,
  LoginErrorPage,
  RouterErrorPage,
} from "src/ui/components/errorpage/ErrorPage";
import { useGetCsrfQuery } from "src/ui/services/CsrfService";
import Api from "src/ui/services/Api";
import queryClient from "src/ui/utils/queryClient";
import { CustomAnalyticsProvider } from "src/ui/utils/CustomAnalyticsProvider";
import Login from "src/ui/components/login/Login";
import Eusa from "src/ui/components/eusa/Eusa";
import { sessionStatus } from "src/ui/services/sessionStatus";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";

const theme = createTheme(SynergyTheme);

const appGlobalStyles = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: Background.variant,
        minHeight: "100vh",
      },
    }}
  />
);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <CustomAnalyticsProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppInternal />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomAnalyticsProvider>
  </QueryClientProvider>
);

const Authentication = ({ children }) => {
  const getCsrfQueryResults = useGetCsrfQuery();
  const getEnvQueryResults = useGetEnvQuery();
  const auth = useAuthentication();
  const me = useGetMeQuery();

  useEffect(() => {
    if (getCsrfQueryResults.data) {
      Api.init(getCsrfQueryResults.data);
    }
  }, [getCsrfQueryResults.data]);

  useEffect(() => {
    if (getEnvQueryResults.data) {
      sessionStatus.setMaxInactiveInternal(
        getEnvQueryResults.data.maxInactiveInterval,
      );
      sessionStatus.createSessionTimeoutTimer();
    }
  }, [getEnvQueryResults.data]);

  if (auth.isPending) {
    return "Loading...";
  }

  if (!auth.data) {
    return <Login />;
  }

  if (!me?.data?.principal.user.acceptedEUSA) {
    return <Eusa />;
  }

  return <ExternalServices>{children}</ExternalServices>;
};

Authentication.propTypes = {
  children: PropTypes.element,
};

const ExternalServices = ({ children }) => {
  useFreshdesk();
  usePendo();

  const { queryResult: permissionsQueryResult } = usePermissions();
  const getMeQueryResults = useGetMeQuery();

  useEffect(() => {
    if (permissionsQueryResult.data && getMeQueryResults.data) {
      log.info("CURRENT USER: ", getMeQueryResults.data.principal.user);
      log.info("PERMISSIONS: ", permissionsQueryResult.data);
    }
  }, [permissionsQueryResult.data, getMeQueryResults.data]);

  if (
    permissionsQueryResult.data === undefined ||
    getMeQueryResults.data === undefined
  ) {
    return "Loading...";
  }
  return children;
};

const AppInternal = () => {
  const terminusRouter = createBrowserRouter(
    createRoutesFromElements(TerminusRoutes()),
  );

  return (
    <>
      <CssBaseline />
      {appGlobalStyles}
      <RouterProvider router={terminusRouter}></RouterProvider>
    </>
  );
};

const TenantCheck = ({ children }) => {
  const getPublicTenantInfoQueryResults = useGetPublicTenantInfoQuery();
  const navigate = useNavigate();

  if (getPublicTenantInfoQueryResults.isPending) {
    return "Loading...";
  }

  if (getPublicTenantInfoQueryResults?.data) {
    if (!getPublicTenantInfoQueryResults.data.exists) {
      navigate({
        pathname: AppRoutePath.ErrorPage,
        search: `?${createSearchParams({
          statusCode: ErrorTypes.TeamDoesNotExist.statusCode,
        })}`,
      });
    }
    if (getPublicTenantInfoQueryResults.data.expired) {
      navigate({
        pathname: AppRoutePath.ErrorPage,
        search: `?${createSearchParams({
          statusCode: ErrorTypes.TeamExpired.statusCode,
        })}`,
      });
    }
  }
  return children;
};

const TerminusRoutes = () => {
  return (
    <>
      <Route
        element={
          <TenantCheck>
            <Authentication>
              <SnackbarProvider>
                <DefaultLayout />
              </SnackbarProvider>
            </Authentication>
          </TenantCheck>
        }
      >
        <Route errorElement={<RouterErrorPage />}>
          <Route path={AppRoutePath.Dashboard} element={<HomeView />} />
          <Route
            path={AppRoutePath.ApplicationList}
            element={<ListApplicationsView />}
          />
          <Route
            path={AppRoutePath.ApplicationAdd}
            element={<AddApplicationView />}
          />
          <Route
            path={AppRoutePath.ApplicationEdit}
            element={<EditApplicationView />}
          />
          <Route path={AppRoutePath.TeamList} element={<ListTeamsView />} />
          <Route path={AppRoutePath.TeamAdd} element={<AddTeamView />} />
          <Route path={AppRoutePath.TeamEdit} element={<EditTeamView />} />
          <Route
            path={AppRoutePath.TeamMemberList}
            element={<ListTeamMembersView />}
          />
          <Route
            path={AppRoutePath.TeamMemberAdd}
            element={<AddTeamMembersView />}
          />
          <Route path={AppRoutePath.TeamImport} element={<ImportTeamView />} />
          <Route path={AppRoutePath.GroupList} element={<ListGroupsView />} />
          <Route
            path={AppRoutePath.ProjectList}
            element={<ListProjectsView />}
          />
          <Route path={AppRoutePath.ProjectAdd} element={<AddProjectView />} />
          <Route
            path={AppRoutePath.ProjectEdit}
            element={<EditProjectView />}
          />
          <Route path={AppRoutePath.ResourceList} element={<ResourcesView />} />
          <Route
            path={AppRoutePath.Notifications}
            element={<NotificationSettingsView />}
          />
          <Route path="*" element={<ErrorPage isColored={false} />} />
        </Route>
      </Route>
      <Route path={AppRoutePath.Unsubscribe} element={<Unsubscribe />} />
      <Route path={AppRoutePath.ErrorPage} element={<ErrorPage />} />
      <Route path={AppRoutePath.LoginErrorPage} element={<LoginErrorPage />} />
    </>
  );
};

export default App;
