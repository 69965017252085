import GoogleDriveAvatar from "../images/google-drive.png";
import EgnyteAvatar from "../images/egnyte.svg";

const resourceOptions = {
  google: {
    displayName: "Google Drive",
    avatar: GoogleDriveAvatar,
    inputFields: [
      {
        id: "clientId",
        label: "Client ID",
        type: "text",
        required: true,
      },
      {
        id: "clientSecret",
        label: "Client Secret",
        type: "text",
        required: true,
      },
    ],
  },
  egnyte: {
    displayName: "Egnyte",
    avatar: EgnyteAvatar,
    inputFields: [
      {
        required: true,
        id: "clientId",
        label: "Client ID",
        type: "text",
      },
      {
        required: true,
        id: "clientSecret",
        label: "Client Secret",
        type: "text",
      },
      {
        required: true,
        id: "domain",
        label: "Domain",
        type: "text",
      },
      {
        required: true,
        id: "subdomain",
        label: "Subdomain",
        type: "text",
      },
    ],
  },
};

export default class ResourcesUtil {
  static getDisplayName(resourceType) {
    return resourceOptions[resourceType].displayName;
  }

  static getAvatarPath(resourceType) {
    return resourceOptions[resourceType].avatar;
  }
}
