import React, { useState } from "react";
import _ from "lodash";
import { Button, TextFieldProps } from "@chemaxon/design-system";
import { TextField } from "@chemaxon/design-system";
import { Switch } from "@chemaxon/design-system";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormActions from "src/ui/components/form/FormActions";
import { Group, GroupAddRequest, GroupEditRequest } from "src/ui/models/Group";
import { useGetCurrentTeam } from "src/ui/services/UserInfoService";
import { useAddGroup, useEditGroup } from "src/ui/services/GroupService";
import { useGetTeamMembers } from "src/ui/services/TeamMemberService";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";
import GroupMemberSelector from "src/ui/views/groups/GroupMemberSelector";
import { FormControl } from "@mui/material";
import Info from "@chemaxon/design-system/icons/Info";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

const HELPER_TEXTS = {
  autoAssign:
    "When auto-assign is on, new team members will be automatically added to this group when invited",
  groupName: "This group cannot be renamed or deleted",
};

enum FormField {
  groupName = "groupName",
  autoAssign = "autoAssign",
  memberIds = "memberIds",
}

type FormData = {
  groupName: string;
  autoAssign: boolean;
  memberIds: number[];
};

type FormErrors = Record<FormField, string>;

const INITIAL_FORM_ERRORS = {
  [FormField.groupName]: "",
  [FormField.autoAssign]: "",
  [FormField.memberIds]: "",
} satisfies FormErrors;

const INITIAL_FORM_DATA = {
  [FormField.groupName]: "",
  [FormField.autoAssign]: false,
  [FormField.memberIds]: [],
} satisfies FormData;

export default function GroupEditor(props: Props) {
  const currentTeam = useGetCurrentTeam().data;

  const createNewGroup = (): Group => ({
    id: 0,
    team: currentTeam ? currentTeam.id : 0,
    memberIds: [],
    groupName: "",
    autoAssign: false,
    isTeamAdminGroup: false,
  });

  const teamMembers = useGetTeamMembers().data;
  const mutateAddGroup = useAddGroup();
  const mutateEditGroup = useEditGroup();
  const [group] = useState<Group>(props.group ? props.group : createNewGroup());

  const [formData, setFormData] = useState<FormData>(
    props.group
      ? {
          groupName: group.groupName,
          autoAssign: group.autoAssign,
          memberIds: group.memberIds,
        }
      : INITIAL_FORM_DATA,
  );
  const [formErrors, setFormErrors] = useState<FormErrors>(INITIAL_FORM_ERRORS);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = (error: unknown) => {
    if (error instanceof SynergySpringMVCError) {
      const newFormErrors: FormErrors = { ...formErrors };

      error.getFieldErrors().forEach(e => {
        // seems like the backend returns errors with "group.fieldName" structure
        newFormErrors[e.field as FormField] = e.message;
      });

      setFormErrors(newFormErrors);
    } else {
      enqueueSnackbar(`An error occured while processing your request.`, {
        variant: "error",
        persist: true,
        action: onCloseAction(closeSnackbar),
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (currentTeam === undefined) {
      return;
    }

    event.preventDefault();

    const groupData = {
      ...formData,
      team: currentTeam.id,
    };

    if (!props.group) {
      addGroup(groupData satisfies GroupAddRequest);
    } else {
      editGroup({
        ...groupData,
        id: group.id,
        isTeamAdminGroup: group.isTeamAdminGroup,
      } satisfies GroupEditRequest);
    }
  };

  const addGroup = (group: GroupAddRequest) => {
    mutateAddGroup
      .mutateAsync(group)
      .then(() => {
        props.onCancel();
        enqueueSnackbar(`Group created successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
      })
      .catch(err => handleError(err));
  };

  const editGroup = (group: GroupEditRequest) => {
    mutateEditGroup
      .mutateAsync(group)
      .then(() => {
        props.onCancel();
        enqueueSnackbar(`Group updated successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
      })
      .catch(err => handleError(err));
  };

  const handleAddToGroup = (id: number) => {
    const memberIds: number[] = Object.assign([], formData.memberIds);
    memberIds.push(id);

    const newFormData = {
      ...formData,
      memberIds,
    };
    setFormData(newFormData);

    const newFormErrors = {
      ...formErrors,
      memberIds: "",
    };
    setFormErrors(newFormErrors);
  };

  const handleRemoveFromGroup = (id: number) => {
    const memberIds: number[] = Object.assign([], formData.memberIds).filter(
      memberId => id != memberId,
    );

    const newFormData = {
      ...formData,
      memberIds,
    };
    setFormData(newFormData);

    const newFormErrors = {
      ...formErrors,
      memberIds: "",
    };
    setFormErrors(newFormErrors);
  };

  const handleSwitchChange = (fieldName: FormField) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.checked,
      };

      setFormData(newFormData);
    };
  };

  const handleChange =
    (fieldName: FormField): TextFieldProps["onChange"] =>
    event => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.value,
      } satisfies Partial<FormData>;

      setFormData(newFormData);

      const newFormErrors = {
        ...formErrors,
      };

      // clearing potentially shown error message under the field
      delete newFormErrors[fieldName];

      setFormErrors(newFormErrors);
    };

  const getGeneralProps = (field: FormField): TextFieldProps => {
    return {
      className: !formErrors[field] ? "" : "error",
      helperText: formErrors[field],
      onChange: handleChange(field),
      error: !!formErrors[field],
      margin: "normal",
      fullWidth: true,
    };
  };

  const getGeneralInputProps = (field: FormField) => ({
    id: field,
    name: field,
    value: formData[field],
    autoComplete: "off",
  });

  const renderNameField = () => {
    return (
      <TextField
        label="Group name"
        {...getGeneralProps(FormField.groupName)}
        InputProps={getGeneralInputProps(FormField.groupName)}
        disabled={group && group.isTeamAdminGroup}
        required
      />
    );
  };

  const renderMembersField = () => {
    return (
      <GroupMemberSelector
        groupMemberIds={formData.memberIds ?? []}
        teamMembers={teamMembers ?? []}
        {...getGeneralProps(FormField.memberIds)}
        onAdd={handleAddToGroup}
        onRemove={handleRemoveFromGroup}
      />
    );
  };

  const renderCancelButton = () => {
    return (
      <Button colorVariant="secondary" onClick={props.onCancel}>
        Cancel
      </Button>
    );
  };

  const isGroupUnChanged = () =>
    props.group != null &&
    _.isMatch(formData, {
      groupName: props.group.groupName,
      memberIds: props.group.memberIds,
      autoAssign: props.group.autoAssign,
    }) &&
    _.isMatch(
      {
        groupName: props.group.groupName,
        memberIds: props.group.memberIds,
        autoAssign: props.group.autoAssign,
      },
      formData,
    );

  const renderSubmitButton = () => {
    return (
      <Button
        colorVariant="primary"
        type="submit"
        disabled={isGroupUnChanged()}
        loading={
          props.group ? mutateEditGroup.isPending : mutateAddGroup.isPending
        }
      >
        {props.group ? "Save changes" : "Create"}
      </Button>
    );
  };

  const renderAdminGroupHelper = () =>
    group.isTeamAdminGroup ? (
      <FormHelperText
        sx={{
          verticalAlign: "middle",
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Info fontSize={"small"} />
        <HelperTextTypography text={HELPER_TEXTS.groupName} isError={false} />
      </FormHelperText>
    ) : null;

  return (
    <form id="groupEditor" onSubmit={handleSubmit}>
      <FormLayoutVertical>
        <FormControl>
          {renderNameField()}
          {renderAdminGroupHelper()}
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={formData.autoAssign}
                onChange={handleSwitchChange(FormField.autoAssign)}
                name="autoAssign"
                id="autoAssign"
              />
            }
            label="Auto assign"
          />
          <FormHelperText>
            <HelperTextTypography
              text={HELPER_TEXTS.autoAssign}
              isError={false}
            />
          </FormHelperText>
        </FormControl>
        {renderMembersField()}

        <FormActions justifyContent="flex-end">
          {renderCancelButton()}
          {renderSubmitButton()}
        </FormActions>
      </FormLayoutVertical>
    </form>
  );
}

type Props = {
  group?: Group;
  onCancel: () => void;
};
