import { Typography } from "@chemaxon/design-system";
import { Error as ErrorColor } from "@chemaxon/design-system/color";
import Error from "@chemaxon/design-system/icons/Error";
import { Stack } from "@mui/material";

export const HelperTextTypography = (props: Props) => {
  let text;
  if (props.isError) {
    text = (
      <Stack direction="row" alignItems="center" component="span">
        <Error fontSize="small" sx={{ mr: 1 }} />
        <span>{props.text}</span>
      </Stack>
    );
  } else {
    text = props.text;
  }

  return (
    <Typography
      variant="body3"
      component="span"
      {...(props.isError && { color: ErrorColor.default })}
    >
      {text}
    </Typography>
  );
};

interface Props {
  text: string;
  isError: boolean;
}
