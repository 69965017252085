import { useState } from "react";
import { Button, TextField, TextFieldProps } from "@chemaxon/design-system";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import log from "loglevel";

import Dialog from "src/ui/components/dialog/Dialog";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import {
  useAddResource,
  useDeleteResource,
  useGetResources,
  useUpdateResource,
} from "src/ui/services/ResourceService";
import ConfirmDialog from "src/ui/components/confirmation/ConfirmDialog";
import Subheader from "src/ui/components/subheader/Subheader";
import SelectField from "src/ui/components/select/Select";
import {
  ClearIndicator,
  DropdownIndicator,
  Option,
} from "src/ui/components/select/SelectPartials";
import { Restricted } from "src/ui/utils/Restricted";
import {
  ResourceAddRequest,
  ResourceConfig,
  ResourceConfigType,
  ResourcesGetResponse,
  ResourceUpdateRequest,
} from "src/ui/models/Resource";
import SynergySpringMVCError from "src/ui/services/SynergySpringMVCError";
import { useGetCurrentTeam } from "src/ui/services/UserInfoService";
import { usePermissions } from "src/ui/utils/usePermissions";

import "src/ui/components/form/form-elements/inputs/Select.scss";
import "src/ui/views/resources/ResourcesView.scss";
import * as React from "react";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

type InputFieldDefinition = {
  id: FormField;
  label: string;
  type: "text";
  required: boolean;
};

type FormDefinition = {
  displayName: string;
  inputFields: InputFieldDefinition[];
};

type ResourceOptionItem = { value: ResourceConfigType; label: string };

type DataRow = [
  resourceId: number,
  displayName: string,
  resourceData: { id: number; displayName: string },
];

enum FormField {
  type = "type",
  clientId = "clientId",
  clientSecret = "clientSecret",
  domain = "domain",
  subdomain = "subdomain",
}

type FormFieldsPerResourceType = {
  [key in keyof typeof RESOURCE_OPTIONS]: (typeof RESOURCE_OPTIONS)[key]["inputFields"][number]["id"];
};

const RESOURCE_OPTIONS = {
  [ResourceConfigType.Google]: {
    displayName: "Google Resource",
    inputFields: [
      {
        id: FormField.clientId,
        label: "Client ID",
        type: "text",
        required: true,
      },
      {
        id: FormField.clientSecret,
        label: "Client Secret",
        type: "text",
        required: true,
      },
    ],
  },
  [ResourceConfigType.Egnyte]: {
    displayName: "Egnyte Resource",
    inputFields: [
      {
        required: true,
        id: FormField.clientId,
        label: "Client ID",
        type: "text",
      },
      {
        required: true,
        id: FormField.clientSecret,
        label: "Client Secret",
        type: "text",
      },
      {
        required: true,
        id: FormField.domain,
        label: "Domain",
        type: "text",
      },
      {
        required: true,
        id: FormField.subdomain,
        label: "Subdomain",
        type: "text",
      },
    ],
  },
} satisfies Record<ResourceConfigType, FormDefinition>;

type FormErrors = Record<FormField, string>;

type FormData =
  | {
      [FormField.type]: ResourceConfigType.Google;
      [FormField.clientId]: string;
      [FormField.clientSecret]: string;
    }
  | {
      [FormField.type]: ResourceConfigType.Egnyte;
      [FormField.clientId]: string;
      [FormField.clientSecret]: string;
      [FormField.domain]: string;
      [FormField.subdomain]: string;
    }
  | { [FormField.type]: null };

const INITIAL_FORM_ERRORS = {
  [FormField.type]: "",
  [FormField.clientId]: "",
  [FormField.clientSecret]: "",
  [FormField.domain]: "",
  [FormField.subdomain]: "",
} satisfies FormErrors;

const INITIAL_FORM_DATA = {
  [FormField.type]: null,
} satisfies FormData;

const parseFormData = (
  formData: Partial<FormData>,
): Partial<ResourceConfig> | null => {
  switch (formData.type) {
    case ResourceConfigType.Egnyte:
      return {
        type: ResourceConfigType.Egnyte,
        clientId: formData.clientId,
        clientSecret: formData.clientSecret,
        domain: formData.domain,
        subdomain: formData.subdomain,
      };

    case ResourceConfigType.Google:
      return {
        type: ResourceConfigType.Google,
        clientId: formData.clientId,
        clientSecret: formData.clientSecret,
      };

    default:
      return null;
  }
};

const ResourcesView = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [formData, setFormData] =
    useState<Partial<FormData>>(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState<FormErrors>(INITIAL_FORM_ERRORS);
  const [resourceToRemove, setResourceToRemove] = useState<DataRow[2] | null>(
    null,
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { checkPermission } = usePermissions();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getResourcesQueryResult = useGetResources();
  const mutateAddResource = useAddResource();
  const mutateUpdateResource = useUpdateResource();
  const mutateDeleteResource = useDeleteResource();

  const currentTeam = getCurrentTeamQueryResult.data;
  const resources = getResourcesQueryResult.data ?? [];

  const mapResourceOptionsToAutoComplete = (): ResourceOptionItem[] => {
    const enabledResources = resources.map(
      resources => resources.resourceConfig.type,
    );
    const availableResources = (
      Object.keys(RESOURCE_OPTIONS) as Array<keyof typeof RESOURCE_OPTIONS>
    ).filter(resource => !enabledResources.includes(resource));

    return availableResources.map(resource => {
      return {
        value: resource,
        label: RESOURCE_OPTIONS[resource].displayName,
      };
    });
  };

  const getResourceOptionItem = (resourceType?: ResourceConfigType | null) => {
    return mapResourceOptionsToAutoComplete().find(
      resourceOption => resourceOption.value === resourceType,
    );
  };

  const handleError = (error: unknown) => {
    if (error instanceof SynergySpringMVCError) {
      const newFormErrors: FormErrors = { ...formErrors };

      error.getFieldErrors().forEach(e => {
        // seems like the backend returns errors with "group.fieldName" structure
        newFormErrors[e.field.split(".")[1] as FormField] = e.message;
      });

      setFormErrors(newFormErrors);
    } else {
      enqueueSnackbar(`An error occured while processing your request.`, {
        variant: "error",
        persist: true,
        action: onCloseAction(closeSnackbar),
      });
    }
  };

  const handleOpenEditDialog = (id: number) => {
    const editResource = resources.find(resource => resource.id === id);

    if (editResource === undefined) {
      return;
    }

    setIsInEditMode(true);
    setOpenAddDialog(true);
    setFormData(editResource.resourceConfig);
    setFormErrors(INITIAL_FORM_ERRORS);
    closeSnackbar();
  };

  const handleOpenDialog = () => {
    setOpenAddDialog(true);
    setIsInEditMode(false);
    setFormData(INITIAL_FORM_DATA);
    setFormErrors(INITIAL_FORM_ERRORS);
    closeSnackbar();
  };

  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setFormData(INITIAL_FORM_DATA);
    setFormErrors(INITIAL_FORM_ERRORS);
    closeSnackbar();
  };

  const handleAddResource = (event: React.UIEvent) => {
    event.preventDefault();
    if (formData === null || currentTeam === undefined) {
      return;
    }

    const parsedFormData = parseFormData(formData);

    if (parsedFormData === null) {
      return;
    }

    const postData = {
      team: currentTeam.id,
      resourceConfig: {
        ...parsedFormData,
      },
    } satisfies ResourceAddRequest;

    console.log(postData);

    mutateAddResource
      .mutateAsync(postData)
      .then(() => {
        setOpenAddDialog(false);
        enqueueSnackbar(`Resource added successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const handleUpdateResource = (event: React.UIEvent) => {
    event.preventDefault();
    const updateId = resources.find(
      resource => resource.resourceConfig.type === formData.type,
    )?.id;

    if (
      updateId === undefined ||
      formData === null ||
      currentTeam === undefined
    ) {
      return;
    }

    const parsedFormData = parseFormData(formData);

    if (parsedFormData === null) {
      return;
    }

    const postData = {
      team: currentTeam.id,
      id: updateId,
      resourceConfig: {
        ...parsedFormData,
      },
      resourceType: null,
    } satisfies ResourceUpdateRequest;

    mutateUpdateResource
      .mutateAsync(postData)
      .then(() => {
        setOpenAddDialog(false);
        enqueueSnackbar(`Resource updated successfully.`, {
          variant: "success",
          action: onCloseAction(closeSnackbar),
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const handleResourceChange = (optionItem: ResourceOptionItem | null) => {
    setFormData(optionItem ? { type: optionItem.value } : INITIAL_FORM_DATA);
    setFormErrors(INITIAL_FORM_ERRORS);
    setGeneralError("");
  };

  const handleChange =
    (fieldName: FormField): TextFieldProps["onChange"] =>
    event => {
      const newFormData = {
        ...formData,
        [fieldName]: event.target.value,
      } satisfies Partial<FormData>;

      setFormData(newFormData);

      const newFormErrors = {
        ...formErrors,
      };

      // clearing potentially shown error message under the field
      delete newFormErrors[fieldName];

      setFormErrors(newFormErrors);
    };

  const getTableColumns = (): MUIDataTableColumnDef[] => {
    const columns: MUIDataTableColumnDef[] = [];

    columns.push({ name: "id", options: { filter: false, display: false } });
    columns.push({ name: "Name", options: { filter: true, sort: true } });

    if (
      checkPermission(
        PlatformDomainObjectType.RESOURCE,
        PlatformPermission.DELETE,
      )
    ) {
      columns.push({
        name: "",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          customBodyRender: renderRemoveButton,
        },
      });
    }

    return columns;
  };

  const getTableData = (resources: ResourcesGetResponse): DataRow[] =>
    resources.map(
      resource =>
        [
          resource.id,
          RESOURCE_OPTIONS[resource.resourceConfig.type].displayName,
          {
            id: resource.id,
            displayName:
              RESOURCE_OPTIONS[resource.resourceConfig.type].displayName,
          },
        ] satisfies DataRow,
    );

  const getMuiTableOptions = (): MUIDataTableOptions => {
    return {
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      elevation: 0,
      filter: false,
      viewColumns: false,
      search: false,
      pagination: false,
      onRowClick: row => handleOpenEditDialog(Number(row[0])),
      textLabels: {
        body: {
          noMatch: "No resource has been added.",
        },
      },
      sortOrder: {
        name: "Name",
        direction: "asc",
      },
    };
  };

  const renderAddDialog = () => {
    return (
      <Dialog
        open={openAddDialog}
        title={isInEditMode ? `Edit ${formData.type}` : "Add Resource"}
        content={
          <form
            onSubmit={isInEditMode ? handleUpdateResource : handleAddResource}
            id="add-dialog-form"
          >
            {!isInEditMode && (
              <SelectField
                id={FormField.type}
                margin="normal"
                fullWidth={true}
                multiline={true}
                options={mapResourceOptionsToAutoComplete()}
                onChange={handleResourceChange}
                placeholder="Choose resource"
                defaultValue={getResourceOptionItem(formData.type)}
                isClearable={true}
                size="large"
                components={{
                  DropdownIndicator,
                  ClearIndicator,
                  // @ts-expect-error the Option component is not typed correctly, but the Select component should be replaced with DS Select anyway.
                  Option,
                }}
              />
            )}
            {renderFormForResource(formData.type)}
          </form>
        }
        actions={[
          {
            id: "add-dialog-cancel",
            label: "Cancel",
            colorVariant: "secondary",
            onClick: handleCloseDialog,
          },
          {
            id: isInEditMode ? "add-dialog-apply" : "add-dialog-add",
            label: isInEditMode ? "Apply" : "Add",
            type: "submit",
            form: "add-dialog-form",
            colorVariant: "primary",
            disabled: formData.type === null,
            loading:
              mutateAddResource.isPending || mutateUpdateResource.isPending,
          },
        ]}
        onClose={handleCloseDialog}
      />
    );
  };

  const renderFormForResource = (resourceType?: ResourceConfigType | null) => {
    if (!resourceType) {
      return;
    }

    return (
      <>
        {RESOURCE_OPTIONS[resourceType].inputFields.map((field, index) => (
          <TextField
            {...getGeneralProps(field.id)}
            InputProps={getGeneralInputProps(field.id)}
            required={field.required}
            key={index}
            label={field.label}
          />
        ))}
      </>
    );
  };

  const getGeneralProps = (field: FormField): TextFieldProps => {
    return {
      className: !formErrors[field] ? "" : "error",
      helperText: formErrors[field],
      onChange: handleChange(field),
      error: !!formErrors[field],
      margin: "normal",
      fullWidth: true,
    };
  };

  const getFieldValue = (field: FormField) => {
    switch (formData.type) {
      case ResourceConfigType.Google:
        return (
          formData[
            field as FormFieldsPerResourceType[ResourceConfigType.Google]
          ] || ""
        );
      case ResourceConfigType.Egnyte:
        return (
          formData[
            field as FormFieldsPerResourceType[ResourceConfigType.Egnyte]
          ] || ""
        );
      default:
        return "";
    }
  };

  const getGeneralInputProps = (field: FormField) => ({
    id: field,
    name: field,
    value: getFieldValue(field),
    autoComplete: "off",
  });

  const renderRemoveButton = (resource: DataRow[2]) => (
    <Button
      colorVariant="text"
      onClick={e => {
        setResourceToRemove(resource);
        e.stopPropagation();
      }}
    >
      Remove
    </Button>
  );

  const hideConfirmDialog = () => {
    setResourceToRemove(null);
  };

  const removeResource = () => {
    if (resourceToRemove === null) {
      return;
    }

    mutateDeleteResource
      .mutateAsync(String(resourceToRemove.id))
      .then(() => {
        hideConfirmDialog();
        enqueueSnackbar(
          `${resourceToRemove.displayName} removed successfully.`,
          { variant: "success", action: onCloseAction(closeSnackbar) },
        );
      })
      .catch(() => {
        log.error("Unable to remove resource");
        hideConfirmDialog();
        enqueueSnackbar(
          `Unable to remove resource: ${resourceToRemove.displayName}`,
          {
            variant: "error",
            persist: true,
            action: onCloseAction(closeSnackbar),
          },
        );
      });
  };

  const addResourceButton = (
    <Button
      colorVariant="primary"
      id="addResourceButton"
      onClick={handleOpenDialog}
    >
      Add Resource
    </Button>
  );

  const tableColumns = getTableColumns();
  const tableData = getTableData(resources);

  return (
    <>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.RESOURCE,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader title={"Resources"} button={addResourceButton} />
        <ViewContainer id={"listResourcesContainer"}>
          <>
            {renderAddDialog()}
            <MUIDataTable
              columns={tableColumns}
              data={tableData}
              options={getMuiTableOptions()}
              title="Resources"
            />
            <ConfirmDialog
              isShown={resourceToRemove !== null}
              title={
                "Are you sure, you want to remove " +
                (resourceToRemove ? resourceToRemove.displayName : null) +
                "?"
              }
              submit={removeResource}
              cancel={hideConfirmDialog}
              destructive={true}
              loading={mutateDeleteResource.isPending}
            />
          </>
        </ViewContainer>
      </Restricted>
    </>
  );
};

export default ResourcesView;
