import { Box } from "@mui/material";
import { Spacing4 } from "@chemaxon/design-system/spacing";
import { Typography } from "@chemaxon/design-system/typography";
import { Button } from "@chemaxon/design-system";
import React from "react";
import errorMono from "@chemaxon/design-system/illustrations/Error-monochrome-M.png";
import errorColored from "@chemaxon/design-system/illustrations/Error-colored-M.png";
import errorForbidden from "@chemaxon/design-system/illustrations/ErrorForbidden-colored-M.png";
import errorLogin from "@chemaxon/design-system/illustrations/ErrorLogin-colored-M.png";
import errorServer from "@chemaxon/design-system/illustrations/ErrorServer-colored-M.png";
import errorUnauthorised from "@chemaxon/design-system/illustrations/ErrorUnauthorised-colored-M.png";
import { useRouteError } from "react-router-dom";

type ErrorType = {
  statusCode: string;
  errorName: string;
  illustration: string;
  colored: boolean;
  description: React.ReactNode;
  action?: {
    label: string;
  };
};

export const errors: Record<string, ErrorType> = {
  Unauthorized: {
    statusCode: "401",
    errorName: "Unauthorized",
    illustration: errorUnauthorised,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        We are sorry, but you are not authorised to access this page.
        <br />
        Please contact your team administrator or us at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  Forbidden: {
    statusCode: "403",
    errorName: "Forbidden",
    illustration: errorForbidden,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        We are sorry, but you do not have access to this page or resource.
        <br />
        Please contact your team administrator or us at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  NotFoundMono: {
    statusCode: "404",
    errorName: "Page not found",
    illustration: errorMono,
    colored: false,
    description: (
      <Typography variant={"body1"}>
        We are sorry, but the page you were looking for could not be found.
        <br />
        Please check the URL.
      </Typography>
    ),
    action: {
      label: "Back to Home",
    },
  },
  NotFound: {
    statusCode: "404",
    errorName: "Page not found",
    illustration: errorColored,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        We are sorry, but the page you were looking for could not be found.
        <br />
        Please contact your team administrator or us at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  InternalServerError: {
    statusCode: "500",
    errorName: "Internal server error",
    illustration: errorServer,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        Please contact your team administrator or us at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  GeneralError: {
    illustration: errorColored,
    errorName: "General error",
    colored: true,
    description: (
      <Typography variant={"body1"}>
        Please contact your team administrator or us at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  LoginError: {
    statusCode: "loginerror",
    errorName: "Login error",
    illustration: errorLogin,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        An unexpected error has occurred during login, please try it again.
        <br />
        If you are still unable to log in, contact your team administrator or us
        at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
    action: {
      label: "Back to Log in",
    },
  },
  TeamDoesNotExist: {
    statusCode: "teamdoesnotexist",
    errorName: "Team does not exist",
    illustration: errorColored,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        Team with the specified subdomain does not exist. Is there a typo in the
        URL?
        <br />
        If you are still unable to log in, contact your team administrator or us
        at
        <br />
        <Typography variant={"link1"}>
          <a href="mailto:support@chemaxon.com">support@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
  TeamExpired: {
    statusCode: "teamexpired",
    errorName: "Team subscription expired ",
    illustration: errorColored,
    colored: true,
    description: (
      <Typography variant={"body1"}>
        Your Chemaxon Cloud subscription has ended.
        <br />
        We hope you've enjoyed using Chemaxon Cloud so far and want to continue
        using it long into the future!
        <br />
        Please contact our sales team (sales@chemaxon.com) to continue working
        with us!
        <Typography variant={"link1"}>
          <a href="mailto:sales@chemaxon.com">sales@chemaxon.com</a>
        </Typography>
      </Typography>
    ),
  },
};

const getErrorType = (
  statusCode: string | null,
  isColored: boolean,
): ErrorType => {
  return (
    Object.values(errors).find(
      error => error.statusCode === statusCode && error.colored === isColored,
    ) || errors.GeneralError
  );
};

interface ErrorPageProps {
  isColored?: boolean;
}

interface ErrorProps {
  statusCode?: string;
  errorName: string;
  errorDescription: React.ReactNode;
  errorType: ErrorType;
}

export function LoginErrorPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const errorType = errors.LoginError;
  const errorName = queryParameters.get("error") || errorType.errorName;
  const errorDescription =
    queryParameters.get("errorDescription") || errorType.description;

  return (
    <ErrorContent
      errorDescription={errorDescription}
      errorName={errorName}
      errorType={errorType}
    />
  );
}

export function ErrorPage({ isColored = true }: ErrorPageProps) {
  const queryParameters = new URLSearchParams(window.location.search);

  const statusCode = queryParameters.get("statusCode");
  const errorType = getErrorType(statusCode, isColored);
  const errorName = queryParameters.get("errorName") || errorType.errorName;
  const errorDescription =
    queryParameters.get("errorDescription") || errorType.description;

  return (
    <ErrorContent
      errorDescription={errorDescription}
      errorName={errorName}
      errorType={errorType}
      statusCode={statusCode}
    />
  );
}

export function RouterErrorPage({ isColored = true }: ErrorPageProps) {
  const error = useRouteError();

  const statusCode = error.response?.status;
  const errorType = getErrorType(statusCode, isColored);
  const errorName = error.response?.error || errorType.errorName;
  const errorDescription = error.response?.description || errorType.description;

  return (
    <ErrorContent
      errorDescription={errorDescription}
      errorName={errorName}
      errorType={errorType}
      statusCode={statusCode}
    />
  );
}

export function ErrorContent({
  statusCode,
  errorName,
  errorDescription,
  errorType,
}: ErrorProps) {
  let subtitle = "";

  if (errorType === errors.GeneralError && statusCode) {
    subtitle = `Error code: ${statusCode}`;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        minHeight: "100vh",
        gap: Spacing4,
      }}
    >
      <Box
        component="img"
        alt={errorType.illustration}
        src={new URL(errorType.illustration, import.meta.url).href}
      />
      <Typography variant={"h5"}>
        <strong>{errorName}</strong>
      </Typography>
      {subtitle && (
        <Typography variant={"h6"}>
          <strong>{subtitle}</strong>
        </Typography>
      )}
      {errorDescription}
      {errorType.action && (
        <Button id="backToLogin" colorVariant="primary" size="medium">
          <a href={"/"} style={{ textDecoration: "none", color: "inherit" }}>
            {errorType.action.label}
          </a>
        </Button>
      )}
    </Box>
  );
}
