import Analytics, { AnalyticsPlugin } from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

import { getAnalyticsConfig } from "src/ui/utils/config";

const ANALYTICS_APP_NAME = "terminus";

export enum GtmCustomEvents {
  PendoInitUser = "pendo_init_user",
}

const analyticsConfig = getAnalyticsConfig();

const plugins: AnalyticsPlugin[] = [];

if (analyticsConfig?.googleTagManager?.containerId) {
  const { auth, containerId, preview } = analyticsConfig.googleTagManager;
  plugins.push(
    googleTagManager({
      containerId,
      ...(auth ? { auth } : {}),
      ...(preview ? { preview } : {}),
    }),
  );
}

const analyticsInstance = Analytics({
  app: ANALYTICS_APP_NAME,
  plugins,
});

export { analyticsInstance };
