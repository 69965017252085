import React from "react";
import { Typography } from "@chemaxon/design-system/typography";
import { Background, Outline } from "@chemaxon/design-system/color";
import { Stack } from "@mui/material";

interface Props {
  button?: React.ReactNode;
  title: string;
}

function Subheader(props: Props) {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 0,
        px: 8,
        py: 4,
        bgcolor: Background.light,
        position: "absolute",
        width: "100%",
        right: "0",
        borderBottom: "1px solid",
        borderColor: Outline.default,
      }}
    >
      <Typography variant="h6" component="h1">
        {props.title}
      </Typography>
      {props.button}
    </Stack>
  );
}

export default Subheader;
