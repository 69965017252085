import PropTypes from "prop-types";
import React from "react";
import log from "loglevel";
import { flow } from "lodash";
import moment from "moment";
import { Button, Switch, TextField } from "@chemaxon/design-system";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddIcon from "@mui/icons-material/PlaylistAdd";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dropzone from "react-dropzone";
import Link from "@mui/material/Link";
import { Backdrop, Divider, FormGroup, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Delete from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import TeamService, {
  useEditTeam,
  useGetTeam,
} from "src/ui/services/TeamService";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";
import ApplicationList from "src/ui/components/applications/ApplicationList";
import MemberList from "src/ui/components/team-members/MemberList";
import Dialog from "src/ui/components/dialog/Dialog";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import CustomMuiNumber from "src/ui/components/Number/Number";
import withRouter from "src/ui/utils/WithRouter";
import FormActions from "src/ui/components/form/FormActions";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormSectionTitle from "src/ui/components/form/FormSectionTitle";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import { withTeamMembers } from "src/ui/utils/withTeamMembers";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";

import "./EditTeamView.scss";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";
import { withSnackbar } from "src/ui/utils/withSnackbar";

const HELPER_TEXTS = {
  teamName: "Please enter the name of the team (e.g. biopharma)",
  expirationDate:
    "The subscription expires the day after the set date (UTC-12)",
  teamSize: "Maximum size of the team",
  subdomain: "Subdomain of the instance",
  adminEmails: "Please enter the email address of the Team Administrator",
};

const UTC_OFFSET = -12 * 60;

class EditTeamView extends React.Component {
  constructor() {
    super();
    this.state = {
      originalData: undefined,
      formData: undefined,
      formErrors: {
        teamName: "",
        teamSize: "",
        expirationDate: "",
        subdomain: "",
      },
      originalCustomEusa: null,
      customEusa: null,
      hasCustomEusa: false,
      loadingEusa: false,
      alertEusa: false,
      confirmEusaEdit: false,
      fileLimitExceeded: false,
      savingTeam: false,
    };
  }

  onAddApplication = () => {
    this.props.router.navigate(
      "/application/add?teamId=" + this.state.formData.id,
    );
  };

  handleDateChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: moment(event.target.value)
          .utcOffset(UTC_OFFSET)
          .add({ day: 1 })
          .startOf("day")
          .toISOString(),
      };
      const newFormErrors = {
        ...this.state.formErrors,
        [fieldName]:
          moment(newFormData[fieldName]) < moment()
            ? "Date should be in the future."
            : "",
      };
      this.setState({
        formData: newFormData,
        formErrors: newFormErrors,
      });
    };
  };

  handleChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: event.target.value,
      };
      this.setState({
        formData: newFormData,
      });

      // clearing potentionally shown error message under the field
      delete this.state.formErrors[fieldName];
    };
  };

  handleNumberFieldChange = fieldName => {
    return value => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: value,
      };
      this.setState({
        formData: newFormData,
      });

      // clearing potentionally shown error message under the field
      delete this.state.formErrors[fieldName];
    };
  };

  handleSwitchChange = fieldName => {
    return event => {
      const newFormData = {
        ...this.state.formData,
        [fieldName]: event.target.checked,
      };

      this.setState({
        formData: newFormData,
      });
    };
  };
  handleCustomEUSASwitch = event => {
    this.setState({
      hasCustomEusa: event.target.checked,
    });
  };

  removeEusaFile = () => {
    this.setState({
      customEusa: null,
      hasCustomEusa: false,
    });
  };

  handleEusaDialogClose = () => {
    this.setState({
      alertEusa: false,
      confirmEusaEdit: false,
    });
  };

  handleEusaDeleteClose = () => {
    TeamService.removeEusa(this.state.formData.id)
      .then(() => {
        this.setState({
          formData: this.state.formData,
          originalData: this.state.formData,
          originalCustomEusa: null,
          customEusa: null,
          originalHasCustomEusa: false,
          hasCustomEusa: false,
        });
      })
      .catch(err => {
        this.setState({
          formErrorMessage: "Failed to delete the custom EUSA file.",
        });
        log.error("Failed to delete the custom EUSA file.", err);
      });
    this.handleEusaDialogClose();
  };

  isInErrorState(field) {
    return !!(this.state.formErrors[field] || "");
  }

  getShownText(field) {
    return this.isInErrorState(field)
      ? this.state.formErrors[field]
      : HELPER_TEXTS[field];
  }

  getGeneralInputProps(field) {
    return {
      name: field,
      value: this.state.formData[field],
      autoComplete: "off",
      id: field,
    };
  }

  getGeneralProps(field) {
    return {
      helperText: (
        <HelperTextTypography
          text={this.getShownText(field)}
          isError={this.isInErrorState(field)}
        />
      ),
      onChange: this.handleChange(field),
      error: this.isInErrorState(field),
      className: this.isInErrorState(field) ? "error" : "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.TEAM,
            platformPermission: PlatformPermission.WRITE,
          }}
        >
          <Subheader
            title={
              this.state.formData
                ? "Edit team: " + this.state.formData.teamName
                : ""
            }
          />
          <ViewContainer id={"editTeamContainer"}>
            <ContainerWithLoaderAndError
              errorMainMessage="This team can't be viewed"
              errorSubMessage="It may have been deleted or you don't have permission to view it."
              data={this.state.formData}
              content={data => {
                return (
                  <React.Fragment>
                    <FormLayoutVertical divider={<Divider />}>
                      <form onSubmit={this.handleSubmit} id="editTeam">
                        <FormLayoutVertical>
                          <TextField
                            {...this.getGeneralProps("teamName")}
                            InputProps={this.getGeneralInputProps("teamName")}
                            label="Team name"
                            autoFocus
                            required
                          />

                          <FormLayoutVertical className="editteam-custom-eusa-group">
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={this.handleCustomEUSASwitch}
                                  name="customEusa"
                                  checked={this.state.hasCustomEusa}
                                />
                              }
                              label="Custom subscription agreement"
                            />
                            {this.state.hasCustomEusa ? (
                              <React.Fragment>
                                <Dropzone
                                  onDrop={() => {
                                    this.setState({ loadingEusa: true });
                                  }}
                                  accept={"application/pdf"}
                                  maxFiles={1}
                                  maxSize={16777216}
                                  onDropRejected={(fileRejections, event) => {
                                    this.props.snackbar.enqueueSnackbar(
                                      `Upload EUSA failed! Reasons: ${fileRejections[0].errors.reduce(
                                        (accumulator, currentValue) => {
                                          if (!accumulator) {
                                            return `${accumulator} ${currentValue.message}`;
                                          }
                                          return `${accumulator}, ${currentValue.message}`;
                                        },
                                        "",
                                      )}`,
                                      {
                                        variant: "error",
                                        persist: true,
                                        action: onCloseAction(
                                          this.props.snackbar.closeSnackbar,
                                        ),
                                      },
                                    );
                                    this.setState({ loadingEusa: false });
                                  }}
                                  onDropAccepted={(acceptedFiles, event) => {
                                    this.setState({
                                      customEusa: acceptedFiles[0],
                                      loadingEusa: false,
                                    });
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="editteam-drop-zone">
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Typography align="center">
                                          Drag & drop or&nbsp;
                                          <Link underline="hover" href="#">
                                            browse
                                          </Link>{" "}
                                          pdf file <br />
                                          (maximum size is 16MB)
                                        </Typography>
                                      </div>
                                      {this.state.fileLimitExceeded ? (
                                        <div className="editteam-eusa-error">
                                          File limit exceeded
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.customEusa ? (
                                        <aside>
                                          <div className="editteam-custom-eusa">
                                            <div className="editteam-eusa-path">
                                              File:&nbsp;
                                              {this.state.customEusa.path}
                                            </div>

                                            <div>
                                              <Tooltip title="Download">
                                                <IconButton
                                                  edge="end"
                                                  aria-label="Download"
                                                  href={TeamService.getCustomEusaUrl(
                                                    this.state.formData.id,
                                                  )}
                                                  size="large"
                                                >
                                                  <CloudDownloadIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <IconButton
                                                edge="end"
                                                aria-label="Remove"
                                                onClick={this.removeEusaFile}
                                                size="large"
                                              >
                                                <Delete />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </aside>
                                      ) : null}
                                    </section>
                                  )}
                                </Dropzone>
                                <Backdrop
                                  open={this.state.loadingEusa}
                                  className="editteam-loader"
                                  timeout={300}
                                >
                                  <CircularProgress color="inherit" />
                                </Backdrop>
                              </React.Fragment>
                            ) : null}
                          </FormLayoutVertical>

                          <Dialog
                            open={this.state.alertEusa}
                            title="Custom EUSA"
                            subtitle="Upload the custom EUSA contract file or uncheck the EUSA switch."
                            actions={[
                              {
                                id: "eusa-dialog-close",
                                label: "Close",
                                colorVariant: "secondary",
                                onClick: this.handleEusaDialogClose,
                              },
                            ]}
                            onClose={this.handleEusaDialogClose}
                          />

                          <Dialog
                            open={this.state.confirmEusaEdit}
                            title="Delete custom EUSA"
                            subtitle="Do you want to delete the EUSA contract file?"
                            actions={[
                              {
                                id: "eusa-dialog-close",
                                label: "Close",
                                colorVariant: "secondary",
                                onClick: this.handleEusaDialogClose,
                              },
                              {
                                id: "eusa-dialog-delete",
                                label: "Delete",
                                colorVariant: "destructive",
                                onClick: this.handleEusaDeleteClose,
                              },
                            ]}
                            onClose={this.handleEusaDialogClose}
                          />

                          <TextField
                            type="date"
                            required
                            {...this.getGeneralProps("expirationDate")}
                            InputProps={{
                              value: moment(
                                this.state.formData.expirationDate,
                              ).format("YYYY-MM-DD"),
                              id: "expirationDate",
                            }}
                            label="Subscription expiration"
                            onChange={this.handleDateChange("expirationDate")}
                          />

                          <CustomMuiNumber
                            {...this.getGeneralProps("teamSize")}
                            InputProps={this.getGeneralInputProps("teamSize")}
                            inputProps={{ min: 1 }}
                            required
                            label="Team size"
                            valueChangeHandler={this.handleNumberFieldChange(
                              "teamSize",
                            )}
                          />

                          <TextField
                            {...this.getGeneralProps("subdomain")}
                            InputProps={this.getGeneralInputProps("subdomain")}
                            label="Subdomain"
                            required
                          />

                          <TextField
                            {...this.getGeneralProps("adminEmails")}
                            InputProps={{
                              ...this.getGeneralInputProps("adminEmails"),
                              disabled: true,
                            }}
                            label="Administrators' emails"
                          />

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.formData.liveChatOn}
                                  onChange={this.handleSwitchChange(
                                    "liveChatOn",
                                  )}
                                  name="liveChatOn"
                                />
                              }
                              label="Live chat"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.formData.demoTeam}
                                  onChange={this.handleSwitchChange("demoTeam")}
                                  name="demoTeam"
                                />
                              }
                              label="Demo team"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.formData.federatedAuth}
                                  onChange={this.handleSwitchChange(
                                    "federatedAuth",
                                  )}
                                  name="federatedAuth"
                                />
                              }
                              label="Federated authentication"
                              id="federatedAuth"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    this.state.formData.emailNotifications
                                  }
                                  onChange={this.handleSwitchChange(
                                    "emailNotifications",
                                  )}
                                  name="emailNotifications"
                                />
                              }
                              label="Email notifications"
                            />
                          </FormGroup>

                          {(this.state.originalData !== this.state.formData ||
                            this.state.originalCustomEusa !==
                              this.state.customEusa ||
                            this.state.originalHasCustomEusa !==
                              this.state.hasCustomEusa) && (
                            <FormActions>
                              <Button
                                colorVariant="primary"
                                className="editteam-submit-btn"
                                type="submit"
                                loading={
                                  this.props.teamService.isEditTeamLoading
                                }
                              >
                                Save
                              </Button>
                              <Button
                                colorVariant="secondary"
                                onClick={this.handleCancelButton}
                              >
                                Cancel
                              </Button>
                            </FormActions>
                          )}
                        </FormLayoutVertical>
                      </form>

                      <FormLayoutVertical id="listApplicationsContainer">
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FormSectionTitle>Applications</FormSectionTitle>

                          <IconButton
                            color="primary"
                            id="addApplicationButton"
                            onClick={this.onAddApplication}
                            size="large"
                          >
                            <AddIcon />
                          </IconButton>
                        </Stack>

                        <ApplicationList
                          filters={{
                            teamId: this.state.formData.id,
                          }}
                        />
                      </FormLayoutVertical>

                      <FormLayoutVertical id="listTeamMembersContainer">
                        <FormSectionTitle>Members</FormSectionTitle>

                        <ContainerWithLoaderAndError
                          data={this.props.teamMembers}
                          content={teamMembers => {
                            return (
                              <MemberList
                                memberList={teamMembers.filter(
                                  item => item.team === this.state.formData.id,
                                )}
                              />
                            );
                          }}
                        />
                      </FormLayoutVertical>
                    </FormLayoutVertical>

                    <FormActions>
                      <Button
                        colorVariant="secondary"
                        data-back-to-list
                        onClick={this.handleCancelButton}
                      >
                        Back to the list
                      </Button>
                    </FormActions>

                    <Backdrop
                      open={this.state.savingTeam}
                      className="editteam-loader"
                      timeout={300}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </React.Fragment>
                );
              }}
            />
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }

  componentDidMount() {
    if (this.props.router.location.state?.formErrorMessage) {
      this.props.snackbar.enqueueSnackbar(
        this.props.router.location.state.formErrorMessage,
        {
          variant: "error",
          persist: true,
          action: onCloseAction(this.props.snackbar.closeSnackbar),
        },
      );
    }
    this.props.teamService
      .getTeam()
      .then(data => {
        TeamService.getCustomEusa(data.id)
          .then(eusa => {
            this.setState({
              formData: data,
              originalData: data,
              originalCustomEusa: eusa.content ? eusa : null,
              customEusa: eusa.content ? eusa : null,
              originalHasCustomEusa: eusa.content ? true : false,
              hasCustomEusa: eusa.content ? true : false,
            });
          })
          .catch(() => {
            this.setState({ data: false });
            log.error("Reading team custom EUSA failed");
            this.props.snackbar.enqueueSnackbar(
              `Reading team custom EUSA failed!`,
              {
                variant: "error",
                persist: true,
                action: onCloseAction(this.props.snackbar.closeSnackbar),
              },
            );
          });
      })
      .catch(() => {
        this.setState({ data: false });
        log.error("Reading team data failed");
        this.props.snackbar.enqueueSnackbar(`Reading team data failed!`, {
          variant: "error",
          persist: true,
          action: onCloseAction(this.props.snackbar.closeSnackbar),
        });
      });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ formErrorMessage: null });
    this.props.teamService
      .editTeam(this.state.formData)
      .then(() => {
        if (
          this.state.originalCustomEusa !== this.state.customEusa &&
          this.state.customEusa &&
          this.state.hasCustomEusa
        ) {
          TeamService.uploadEusa(this.state.formData.id, this.state.customEusa)
            .then(() => {
              this.setState({
                formData: this.state.formData,
                originalData: this.state.formData,
                originalCustomEusa: this.state.customEusa,
                customEusa: this.state.customEusa,
                originalHasCustomEusa: true,
                hasCustomEusa: true,
                fileLimitExceeded: false,
              });
            })
            .catch(err => {
              this.props.snackbar.enqueueSnackbar(
                `Failed to upload the custom EUSA file!`,
                {
                  variant: "error",
                  persist: true,
                  action: onCloseAction(this.props.snackbar.closeSnackbar),
                },
              );
              log.error("Failed to upload the custom EUSA file.", err);
            });
        }
        if (
          this.state.originalCustomEusa === this.state.customEusa &&
          this.state.originalCustomEusa &&
          !this.state.hasCustomEusa
        ) {
          this.setState({
            confirmEusaEdit: true,
          });
        }
        if (
          this.state.originalCustomEusa !== this.state.customEusa &&
          !this.state.hasCustomEusa
        ) {
          this.setState({
            confirmEusaEdit: true,
          });
        }
        if (
          this.state.originalCustomEusa === null &&
          this.state.customEusa === null &&
          this.state.hasCustomEusa
        ) {
          this.setState({
            alertEusa: true,
          });
        }
        if (
          this.state.originalCustomEusa !== null &&
          this.state.customEusa === null &&
          this.state.hasCustomEusa
        ) {
          this.setState({
            alertEusa: true,
          });
        } else {
          this.setState({
            formData: this.state.formData,
            originalData: this.state.formData,
            fileLimitExceeded: false,
          });
        }
        this.props.snackbar.enqueueSnackbar(`Team saved successfully!`, {
          variant: "success",
          action: onCloseAction(this.props.snackbar.closeSnackbar),
        });
      })
      .catch(err => {
        let newFormErrors = {};
        let errorMessage = "";
        const fieldErrors = err.getFieldErrors();
        if (fieldErrors.length > 0) {
          err.getFieldErrors().forEach(e => {
            newFormErrors[e.field] = e.message;
          });
        } else if (typeof err.message === "string") {
          this.props.snackbar.enqueueSnackbar(
            `Failed to save changes of the team.`,
            {
              variant: "error",
              persist: true,
              action: onCloseAction(this.props.snackbar.closeSnackbar),
            },
          );
        }
        this.setState({
          formErrors: newFormErrors,
          formErrorMessage: errorMessage,
        });
        log.error("Failed to save changes of the application.", err);
      })
      .finally(() => {
        this.setState({
          savingTeam: false,
        });
      });
  };

  handleCancelButton = () => {
    this.props.router.navigate("/teams");
  };
}

EditTeamView.propTypes = {
  match: PropTypes.object,
  router: PropTypes.object,
  teamMembers: PropTypes.array,
  location: PropTypes.object,
  teamService: PropTypes.shape({
    getTeam: PropTypes.func,
    editTeam: PropTypes.func,
    isEditTeamLoading: PropTypes.bool,
  }),
  snackbar: PropTypes.shape({
    enqueueSnackbar: PropTypes.func,
    closeSnackbar: PropTypes.func,
  }),
};

const withTeamService = WrappedComponent => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTeamService = props => {
    const { teamId } = useParams();
    const getTeamQueryResult = useGetTeam(Number(teamId));
    const mutateEditTeam = useEditTeam();

    const teamService = {
      getTeam: async () =>
        adaptUseQueryResponseForContainerWithLoader(
          await getTeamQueryResult.refetch(),
        ),
      editTeam: mutateEditTeam.mutateAsync,
      isEditTeamLoading: mutateEditTeam.isPending,
    };

    return <WrappedComponent {...props} teamService={teamService} />;
  };

  ComponentWithTeamService.displayName = `WithTeamService(${displayName})`;

  return ComponentWithTeamService;
};

const enhance = flow(
  withSnackbar,
  withTeamService,
  withTeamMembers,
  withRouter,
);

export default enhance(EditTeamView);
