import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Api from "src/ui/services/Api";
import type { QueryKeysDefinition } from "src/types/queryKeys";
import { queryKeys as groupQueryKeys } from "src/ui/services/GroupService";
import type {
  AddTeamMemberRequest,
  TeamMember,
} from "src/ui/models/TeamMember";
import { queryKeys as teamQueryKeys } from "src/ui/services/TeamService";
import { queryKeys as userInfoQueryKeys } from "src/ui/services/UserInfoService";

export const SERVICE_URL = "/internal/users";

class TeamMemberService {
  /**
   * Get list of registered team members.
   */
  static getTeamMembers(): Promise<TeamMember[]> {
    return Api.get(SERVICE_URL);
  }

  static addTeamMembers(
    userNameList: string[],
    teamId: number,
    groups: string[],
  ): Promise<number[]> {
    const input = {
      users:
        userNameList && userNameList.map
          ? userNameList.map(userName => ({
              userName: userName,
            }))
          : [],
      teamId: teamId,
      groups: groups,
    } satisfies AddTeamMemberRequest;
    return Api.post(`${SERVICE_URL}/bulk`, input);
  }

  static removeTeamMember(teamMemberId: number): Promise<void> {
    return Api.delete(`${SERVICE_URL}/${teamMemberId}`);
  }

  static acceptEusa(teamMemberId: number): Promise<void> {
    return Api.post(`${SERVICE_URL}/${teamMemberId}/acceptEUSA`);
  }
}

const QUERY_KEYS_NAMESPACE = "teamMembers";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const useGetTeamMembers = () =>
  useQuery({
    queryKey: queryKeys.list(),
    queryFn: () => TeamMemberService.getTeamMembers(),
  });

export const useAddTeamMembers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userNameList,
      teamId,
      groups,
    }: {
      userNameList: string[];
      teamId: number;
      groups: string[];
    }) => TeamMemberService.addTeamMembers(userNameList, teamId, groups),
    onSuccess: () => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: queryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.details() }),
      ]);
    },
  });
};

export const useRemoveTeamMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamMemberId: number) =>
      TeamMemberService.removeTeamMember(teamMemberId),
    onSuccess: () => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: queryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.details() }),
      ]);
    },
  });
};

export const useAcceptEusa = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamMemberId: number) =>
      TeamMemberService.acceptEusa(teamMemberId),
    onSuccess: () => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: queryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: userInfoQueryKeys.me() }),
      ]);
    },
  });
};

export default TeamMemberService;
