import { useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { Button } from "@chemaxon/design-system";
import Tooltip from "@mui/material/Tooltip";
import log from "loglevel";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";

import PortalStyle from "src/ui/services/styles";
import {
  useGetApplicationsWithHealthCheck,
  useRemoveApplication,
} from "src/ui/services/ApplicationService";
import ConfirmDialog from "src/ui/components/confirmation/ConfirmDialog";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";
import SynergyMUIDataTableSearchBox from "src/ui/components/mui-datatables-search-box/SynergyMUIDataTableSearchBox";
import { Application, Status } from "src/ui/models/Application";
import { AppRoutePath } from "src/ui/utils/routes";
import { useGetTeams } from "src/ui/services/TeamService";

import "src/ui/components/applications/ApplicationList.scss";
import * as React from "react";
import { useSnackbar } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

interface Props {
  filters?: {
    teamId?: number;
  };
}

type DataRow =
  | [number, string, string | undefined, Status, Application]
  | [number, string, string | undefined, string, Status, Application];

const ApplicationList = (props: Props) => {
  const { filters = {} } = props;
  const [appToRemove, setAppToRemove] = useState<null | Application>(null);
  const navigate = useNavigate();
  const getTeamsQueryResult = useGetTeams();
  const getApplicationsWithHealthCheckQueryResult =
    useGetApplicationsWithHealthCheck();
  const mutateRemoveApplication = useRemoveApplication();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const teams = getTeamsQueryResult.data;
  const applications = getApplicationsWithHealthCheckQueryResult.data;

  const getMuiTableOptions = (): MUIDataTableOptions => {
    return {
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <SynergyMUIDataTableSearchBox
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      },
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      onRowClick: editApplication,
      elevation: 0,
      filter: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "No registered applications.",
        },
      },
      sortOrder: {
        name: "Name",
        direction: "asc",
      },
    } satisfies MUIDataTableOptions;
  };

  const hideConfirmDialog = () => {
    setAppToRemove(null);
  };

  const removeApplication = () => {
    if (appToRemove) {
      mutateRemoveApplication
        .mutateAsync(appToRemove.id)
        .then(() => {
          hideConfirmDialog();
          enqueueSnackbar(`${appToRemove.displayName} removed successfully.`, {
            variant: "success",
            action: onCloseAction(closeSnackbar),
          });
        })
        .catch(() => {
          log.error("Unable to remove application");
          hideConfirmDialog();
          enqueueSnackbar(
            `Unable to remove application: ${appToRemove.displayName}.`,
            {
              variant: "error",
              persist: true,
              action: onCloseAction(closeSnackbar),
            },
          );
        });
    }
  };

  const getTableColumns = (): MUIDataTableColumnDef[] => {
    const columns: MUIDataTableColumnDef[] = [];

    columns.push({
      name: "",
      options: { display: false, filter: false, searchable: false },
    });

    columns.push({
      name: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderText,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
      },
    });

    columns.push({
      name: "Address",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderText,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
      },
    });

    if (filters.teamId === undefined) {
      columns.push({
        name: "Team",
        options: {
          filter: true,
          sort: true,
          customBodyRender: renderText,
          setCellHeaderProps: () => {
            return {
              style: {
                width: 320,
              },
            };
          },
        },
      });
    }

    columns.push({
      name: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderStatusCell,
      },
    });

    columns.push({
      name: "",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: renderRemoveButton,
      },
    });

    return columns;
  };

  const renderRemoveButton = (application: Application) => {
    return (
      <Button
        colorVariant="text"
        size="small"
        onClick={e => {
          setAppToRemove(application);
          e.stopPropagation();
        }}
      >
        Remove
      </Button>
    );
  };

  const getTableData = (applications: Application[]) => {
    const rows: DataRow[] = [];

    applications.forEach(application => {
      if (filters.teamId === undefined || filters.teamId === application.team) {
        const teamName =
          filters.teamId === undefined
            ? ([getTeamName(application.team)] as const)
            : ([] as const);

        const cells = [
          application.id,
          application.displayName,
          application.address,
          ...teamName,
          application.status,
          application,
        ] satisfies DataRow;

        rows.push(cells);
      }
    });

    return rows;
  };

  const getTeamName = (teamId: number) => {
    const team = teams ? teams.find(team => team.id === teamId) : undefined;
    return team ? team.teamName : "";
  };

  const renderText = (name?: string) => {
    if (name) {
      if (name.length > 40) {
        const truncName = name.substring(0, 39) + "...";
        return (
          <Tooltip title={name}>
            <span>{truncName}</span>
          </Tooltip>
        );
      }
      return <span>{name}</span>;
    } else {
      return <span style={{ fontStyle: "italic" }}>not defined</span>;
    }
  };

  const renderStatusCell = (status: Status) => {
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <div
          data-class="status-indicator"
          style={getStatusCellStyle(status)}
        ></div>
        {status.replace("_", " ")}
      </div>
    );
  };

  const getStatusCellStyle = (status: Status) => {
    let bgcolor;
    switch (status) {
      case Status.Up:
        bgcolor = PortalStyle.colors.success;
        break;
      case Status.ShuttingDown:
      case Status.Unknown:
        bgcolor = PortalStyle.colors.attention;
        break;
      case Status.Error:
        bgcolor = PortalStyle.colors.danger;
        break;
      case Status.Down:
      default:
        bgcolor = PortalStyle.colors.gray02;
        break;
    }

    return {
      display: "inline-block",
      margin: "0 5px 0px 0px",
      width: "4px",
      height: "12px",
      backgroundColor: bgcolor,
      borderRadius: "5px",
      verticalAlign: "text-top",
    };
  };

  const editApplication = (row: string[]) => {
    navigate(
      generatePath(AppRoutePath.ApplicationEdit, { appId: String(row[0]) }),
    );
  };

  return (
    <ContainerWithLoaderAndError
      data={applications}
      content={applications => {
        const tableColumns = getTableColumns();
        const tableData = getTableData(applications);
        return (
          <div className={"applicationList"}>
            <MUIDataTable
              title="Application list"
              columns={tableColumns}
              data={tableData}
              options={getMuiTableOptions()}
            />
            <ConfirmDialog
              isShown={appToRemove !== null}
              title={`Are you sure, you want to remove ${
                appToRemove ? appToRemove.displayName : null
              }?`}
              submit={removeApplication}
              cancel={hideConfirmDialog}
              destructive={true}
              loading={mutateRemoveApplication.isPending}
            />
          </div>
        );
      }}
    />
  );
};

export default ApplicationList;
