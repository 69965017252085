import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Api from "src/ui/services/Api";
import { QueryKeysDefinition } from "src/types/queryKeys";
import {
  ResourceAddRequest,
  ResourcesGetResponse,
  ResourcesInfoGetResponse,
  ResourceUpdateRequest,
} from "src/ui/models/Resource";

export const SERVICE_URL = "/internal/resources";

class ResourceService {
  static getResources(): Promise<ResourcesGetResponse> {
    return Api.get(SERVICE_URL);
  }

  static getResourcesInfo(): Promise<ResourcesInfoGetResponse> {
    return Api.get(`${SERVICE_URL}/info`);
  }

  static addResource(data: ResourceAddRequest): Promise<number> {
    return Api.post(SERVICE_URL, data);
  }

  static updateResource(data: ResourceUpdateRequest): Promise<void> {
    return Api.put(SERVICE_URL, data);
  }

  static deleteResource(resourceId: string) {
    return Api.delete(`${SERVICE_URL}/${resourceId}`);
  }
}

const QUERY_KEYS_NAMESPACE = "resources";

const baseQueryKeys = {
  all: () => [QUERY_KEYS_NAMESPACE] as const,
  list: () => [QUERY_KEYS_NAMESPACE, "list"] as const,
  infos: () => [QUERY_KEYS_NAMESPACE, "info"] as const,
} satisfies QueryKeysDefinition;

export const queryKeys = {
  ...baseQueryKeys,
} satisfies QueryKeysDefinition;

export const useGetResources = () =>
  useQuery({
    queryKey: queryKeys.list(),
    queryFn: () => ResourceService.getResources(),
  });

export const useGetResourcesInfo = () =>
  useQuery({
    queryKey: queryKeys.infos(),
    queryFn: () => ResourceService.getResourcesInfo(),
  });

export const useAddResource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResourceAddRequest) => ResourceService.addResource(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.infos() });
    },
  });
};

export const useUpdateResource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResourceUpdateRequest) =>
      ResourceService.updateResource(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.infos() });
    },
  });
};

export const useDeleteResource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (resourceId: string) =>
      ResourceService.deleteResource(resourceId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
      queryClient.invalidateQueries({ queryKey: queryKeys.infos() });
    },
  });
};

export default ResourceService;
